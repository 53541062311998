@import url(template.scss);
/* Basic styling */ 
* { box-sizing: border-box; padding: 0; margin: 0; } 
.embedScrollVideoContainer{
  width:100%;
  height: 100vh
} 

.embedScrollVideo {
  width: 100vw;
  height: 100vh;
  
}
.overflow_hidden { overflow: hidden!important;}
.logo {
  max-height: 50px;

}
.footer__logo img {
  width: 100px;
  height:auto;
}
.navClassic-list li a{text-transform: uppercase;}
h1.sectionHeading__title, h2.sectionHeading__title {

  border-left: 2px solid;
  padding-left: 15px;

}
.hover_color.active{color: var(--accent-color)}
.hover_color:hover{color: var(--accent-color);transition: all 0.2s ease-in-out}

#portfolio_list, #portfolio_grid {width: 0;height: 0;visibility: hidden;}
#portfolio_list.active, #portfolio_grid.active{width: 100%;height: auto; visibility: visible;}

.clientsItem__img img {max-width: 100%;}

.fancy-grid__item.is-deactivated {width: 0; height: 0; display: none;}

// .embedScrollVideoContainer iframe:after{content: 'play'; width: 100%; height: 100; position: absolute; top:0; left:0}
/* ---- 12.3 Clients List ---- */





 .t-clients li a img {
  margin: auto;
}

.t-clients li {display:inline-block;   padding-left: 35px;
  padding-right: 35px; }

.t-clients img {
    opacity: 0.7;
    max-width: 200px;
    }
    .t-clients-container {overflow:hidden}

    section.bg-dark-1 h2, section.bg-dark-1 .sectionHeading__subtitle, section.bg-dark-1  a{color:#FFF}

    .navbar-brand img {max-width:100px;}

    @media (max-width: 767px) {
.layoutBar {text-align: center;}
    }

    .navClassic-wrap.darkbg li a {color:#FFF}
    header.is-pinned .navClassic-wrap.darkbg li a{color:#000}
    header.is-pinned.is-top .navClassic-wrap.darkbg li a{color:#FFF}

    .shopify-buy__product__price {text-align: right;}