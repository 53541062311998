/* ------------------------------------------------------------------

  01. Variables
  02. Reveal animations
  03. Base
  04. Buttons
  05. Forms
  06. Typography
  07. 404
  08. Footers
  09. Blog masthead
  10. Blog content
  11. Blog pagination
  12. Blog comments
  13. Blog respond form
  14. Blog sidebar
  15. Blog sidebar widgets
  16. Header, menus
  17. Nav button
  18. Testimonials
  19. Video
  20. Back button
  21. Breadcrumbs
  22. Social
  23. Contact
  24. Section heading
  25. Parallax
  26. Preloader
  27. UI elements
  28. Pagination
  29. Cursor
  30. Main slider type-1
  31. Main slider type-2
  32. Main slider type-3
  33. Masthead base
  34. Masthead single portfolio project
  35. Masthead type-1
  36. Masthead type-2
  37. Masthead type-3
  38. Masthead type-4
  39. Card
  40. Price card
  41. Award
  42. Charts
  43. Clients
  44. Blog card
  45. Team
  46. Counter
  47. Portfolio
  48. Masonry
  49. Portfolio projects navigation
  50. Portfolio single project
  51. Utilities

-------------------------------------------------------------------- */
.ratio {
    position: relative;
    display: block;
    overflow: hidden;
  }
  
  .ratio::before {
    display: block;
    width: 100%;
    content: "";
  }
  
  .ratio-1\:1::before {
    padding-bottom: 100%;
  }
  
  .ratio-2\:1::before {
    padding-bottom: 50%;
  }
  
  .ratio-2\:3::before {
    padding-bottom: 150%;
  }
  
  .ratio-3\:2::before {
    padding-bottom: 66.66667%;
  }
  
  .ratio-3\:4::before {
    padding-bottom: 133.33333%;
  }
  
  .ratio-4\:3::before {
    padding-bottom: 75%;
  }
  
  .ratio-16\:9::before {
    padding-bottom: 56.25%;
  }
  
  /*--------------------------------------------------
      01. Variables
  ---------------------------------------------------*/
  :root {
    --text-xs:   0.75rem;
    --text-sm:   0.875rem;
    --text-base: 1rem;
    --text-lg:   1.125rem;
    --text-xl:   1.25rem;
    --text-2xl:  1.5rem;
    --text-3xl:  2rem;
    --text-4xl:  2.5rem;
    --text-5xl:  3rem;
    --text-6xl:  3.75rem;
  }
  
  :root {
    --preloader-image: url('/assets/images/general/loader.svg');
  }
  
  :root {
    //--accent-color: #FF002E;
    --accent-color: #F64C72;
    --bg-light-1: #f5f6f8;
    --bg-light-2: #F1F6F9;
    --bg-dark-1: #131419;
    --bg-dark-2: #1E2027;
    --bg-dark-3: #0D0D12;
    --bg-dark-4: #0A0A0F;
    --font-light: rgb(197, 197, 197);
    --font-dark: #454545;
  }
  
  :root {
    --font-primary: "Lato", sans-serif;
    --font-secondary: "Inter", sans-serif;
  }
  
  /*--------------------------------------------------
      02. Reveal animations
  ---------------------------------------------------*/
  [data-anim-wrap] {
    pointer-events: none;
  }
  
  [data-anim-wrap].animated {
    pointer-events: auto;
  }
  
  [data-anim*='cover-white']::after,
  [data-anim-child*='cover-white']::after {
    background-color: white;
  }
  
  [data-anim*='cover-black']::after,
  [data-anim-child*='cover-black']::after {
    background-color: black;
  }
  
  [data-anim*='cover-light-1']::after,
  [data-anim-child*='cover-light-1']::after {
    background-color: var(--bg-light-1);
  }
  
  [data-anim*='cover-light-2']::after,
  [data-anim-child*='cover-light-2']::after {
    background-color: var(--bg-light-2);
  }
  
  [data-anim*='cover-dark-1']::after,
  [data-anim-child*='cover-dark-1']::after {
    background-color: var(--bg-dark-1);
  }
  
  [data-anim*='cover-dark-2']::after,
  [data-anim-child*='cover-dark-2']::after {
    background-color: var(--bg-dark-2);
  }
  
  [data-anim*='cover-accent']::after,
  [data-anim-child*='cover-accent']::after {
    background-color: var(--accent-color);
  }
  
  @-webkit-keyframes reveal {
    100% {
      opacity: 1;
    }
  }
  
  @keyframes reveal {
    100% {
      opacity: 1;
    }
  }
  
  [data-anim^='img-fade'],
  [data-anim-child^='img-fade'] {
    overflow: hidden;
    display: block;
    position: relative;
  }
  
  [data-anim^='img-fade'] > *,
  [data-anim-child^='img-fade'] > * {
    pointer-events: none;
    opacity: 0;
    transform: scale(1.5);
    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  }
  
  [data-anim^='img-fade'].is-in-view > *,
  [data-anim-child^='img-fade'].is-in-view > * {
    pointer-events: auto;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  
  [data-anim^='img-right'],
  [data-anim-child^='img-right'] {
    overflow: hidden;
    display: block;
    position: relative;
  }
  
  [data-anim^='img-right']::after,
  [data-anim-child^='img-right']::after {
    content: "";
    position: absolute;
    top: -0.0625rem;
    left: -0.0625rem;
    right: 0;
    z-index: 20;
    height: calc(100% + 0.125rem);
    width: calc(100% + 0.125rem);
    transform-origin: right;
    -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
    -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
            animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  
  [data-anim^='img-right'] > *,
  [data-anim-child^='img-right'] > * {
    transform-origin: center;
    -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
    -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
            animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  
  [data-anim^='img-right'].is-in-view::after,
  [data-anim-child^='img-right'].is-in-view::after {
    -webkit-animation-name: imgRight;
            animation-name: imgRight;
  }
  
  [data-anim^='img-right'].is-in-view > *,
  [data-anim-child^='img-right'].is-in-view > * {
    -webkit-animation-name: imgReveal;
            animation-name: imgReveal;
  }
  
  @-webkit-keyframes imgRight {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }
  
  @keyframes imgRight {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }
  
  @-webkit-keyframes imgReveal {
    0% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1.01);
    }
  }
  
  @keyframes imgReveal {
    0% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1.01);
    }
  }
  
  [data-anim^='slide-'],
  [data-anim-child^='slide-'] {
    opacity: 0;
    transition-property: opacity, transform;
    pointer-events: none;
    transition-duration: 1.2s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  [data-anim^='slide-'].is-in-view,
  [data-anim-child^='slide-'].is-in-view {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    pointer-events: auto;
  }
  
  [data-anim^='slide-up'],
  [data-anim-child^='slide-up'] {
    transform: translate3d(0, 2.125rem, 0);
  }
  
  [data-anim^='slide-down'],
  [data-anim-child^='slide-down'] {
    transform: translate3d(0, -2.125rem, 0);
  }
  
  [data-anim^='slide-right'],
  [data-anim-child^='slide-right'] {
    transform: translate3d(-2.125rem, 0, 0);
  }
  
  [data-anim^='slide-left'],
  [data-anim-child^='slide-left'] {
    transform: translate3d(2.125rem, 0, 0);
  }
  
  [data-anim^='fade'],
  [data-anim-child^='fade'] {
    opacity: 0;
    transition-property: opacity;
    pointer-events: none;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  [data-anim^='fade'].is-in-view,
  [data-anim-child^='fade'].is-in-view {
    opacity: 1;
    pointer-events: auto;
  }
  
  [data-anim*="delay-1"],
  [data-anim-child*="delay-1"] {
    transition-delay: 0.1s;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }
  
  [data-anim*="delay-1"]::after,
  [data-anim-child*="delay-1"]::after {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }
  
  [data-anim*="delay-1"] > *,
  [data-anim-child*="delay-1"] > * {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }
  
  [data-anim*="delay-2"],
  [data-anim-child*="delay-2"] {
    transition-delay: 0.2s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  
  [data-anim*="delay-2"]::after,
  [data-anim-child*="delay-2"]::after {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  
  [data-anim*="delay-2"] > *,
  [data-anim-child*="delay-2"] > * {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  
  [data-anim*="delay-3"],
  [data-anim-child*="delay-3"] {
    transition-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  
  [data-anim*="delay-3"]::after,
  [data-anim-child*="delay-3"]::after {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  
  [data-anim*="delay-3"] > *,
  [data-anim-child*="delay-3"] > * {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  
  [data-anim*="delay-4"],
  [data-anim-child*="delay-4"] {
    transition-delay: 0.4s;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  
  [data-anim*="delay-4"]::after,
  [data-anim-child*="delay-4"]::after {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  
  [data-anim*="delay-4"] > *,
  [data-anim-child*="delay-4"] > * {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  
  [data-anim*="delay-5"],
  [data-anim-child*="delay-5"] {
    transition-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  
  [data-anim*="delay-5"]::after,
  [data-anim-child*="delay-5"]::after {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  
  [data-anim*="delay-5"] > *,
  [data-anim-child*="delay-5"] > * {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  
  [data-anim*="delay-6"],
  [data-anim-child*="delay-6"] {
    transition-delay: 0.6s;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }
  
  [data-anim*="delay-6"]::after,
  [data-anim-child*="delay-6"]::after {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }
  
  [data-anim*="delay-6"] > *,
  [data-anim-child*="delay-6"] > * {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }
  
  [data-anim*="delay-7"],
  [data-anim-child*="delay-7"] {
    transition-delay: 0.7s;
    -webkit-animation-delay: 0.7s;
            animation-delay: 0.7s;
  }
  
  [data-anim*="delay-7"]::after,
  [data-anim-child*="delay-7"]::after {
    -webkit-animation-delay: 0.7s;
            animation-delay: 0.7s;
  }
  
  [data-anim*="delay-7"] > *,
  [data-anim-child*="delay-7"] > * {
    -webkit-animation-delay: 0.7s;
            animation-delay: 0.7s;
  }
  
  [data-anim*="delay-8"],
  [data-anim-child*="delay-8"] {
    transition-delay: 0.8s;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }
  
  [data-anim*="delay-8"]::after,
  [data-anim-child*="delay-8"]::after {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }
  
  [data-anim*="delay-8"] > *,
  [data-anim-child*="delay-8"] > * {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }
  
  [data-anim*="delay-9"],
  [data-anim-child*="delay-9"] {
    transition-delay: 0.9s;
    -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
  }
  
  [data-anim*="delay-9"]::after,
  [data-anim-child*="delay-9"]::after {
    -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
  }
  
  [data-anim*="delay-9"] > *,
  [data-anim-child*="delay-9"] > * {
    -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
  }
  
  [data-anim*="delay-10"],
  [data-anim-child*="delay-10"] {
    transition-delay: 1s;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  [data-anim*="delay-10"]::after,
  [data-anim-child*="delay-10"]::after {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  [data-anim*="delay-10"] > *,
  [data-anim-child*="delay-10"] > * {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  [data-anim*="delay-11"],
  [data-anim-child*="delay-11"] {
    transition-delay: 1.1s;
    -webkit-animation-delay: 1.1s;
            animation-delay: 1.1s;
  }
  
  [data-anim*="delay-11"]::after,
  [data-anim-child*="delay-11"]::after {
    -webkit-animation-delay: 1.1s;
            animation-delay: 1.1s;
  }
  
  [data-anim*="delay-11"] > *,
  [data-anim-child*="delay-11"] > * {
    -webkit-animation-delay: 1.1s;
            animation-delay: 1.1s;
  }
  
  [data-anim*="delay-12"],
  [data-anim-child*="delay-12"] {
    transition-delay: 1.2s;
    -webkit-animation-delay: 1.2s;
            animation-delay: 1.2s;
  }
  
  [data-anim*="delay-12"]::after,
  [data-anim-child*="delay-12"]::after {
    -webkit-animation-delay: 1.2s;
            animation-delay: 1.2s;
  }
  
  [data-anim*="delay-12"] > *,
  [data-anim-child*="delay-12"] > * {
    -webkit-animation-delay: 1.2s;
            animation-delay: 1.2s;
  }
  
  [data-anim*="delay-13"],
  [data-anim-child*="delay-13"] {
    transition-delay: 1.3s;
    -webkit-animation-delay: 1.3s;
            animation-delay: 1.3s;
  }
  
  [data-anim*="delay-13"]::after,
  [data-anim-child*="delay-13"]::after {
    -webkit-animation-delay: 1.3s;
            animation-delay: 1.3s;
  }
  
  [data-anim*="delay-13"] > *,
  [data-anim-child*="delay-13"] > * {
    -webkit-animation-delay: 1.3s;
            animation-delay: 1.3s;
  }
  
  [data-anim*="delay-14"],
  [data-anim-child*="delay-14"] {
    transition-delay: 1.4s;
    -webkit-animation-delay: 1.4s;
            animation-delay: 1.4s;
  }
  
  [data-anim*="delay-14"]::after,
  [data-anim-child*="delay-14"]::after {
    -webkit-animation-delay: 1.4s;
            animation-delay: 1.4s;
  }
  
  [data-anim*="delay-14"] > *,
  [data-anim-child*="delay-14"] > * {
    -webkit-animation-delay: 1.4s;
            animation-delay: 1.4s;
  }
  
  [data-anim*="delay-15"],
  [data-anim-child*="delay-15"] {
    transition-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
  }
  
  [data-anim*="delay-15"]::after,
  [data-anim-child*="delay-15"]::after {
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
  }
  
  [data-anim*="delay-15"] > *,
  [data-anim-child*="delay-15"] > * {
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
  }
  
  [data-anim*="delay-16"],
  [data-anim-child*="delay-16"] {
    transition-delay: 1.6s;
    -webkit-animation-delay: 1.6s;
            animation-delay: 1.6s;
  }
  
  [data-anim*="delay-16"]::after,
  [data-anim-child*="delay-16"]::after {
    -webkit-animation-delay: 1.6s;
            animation-delay: 1.6s;
  }
  
  [data-anim*="delay-16"] > *,
  [data-anim-child*="delay-16"] > * {
    -webkit-animation-delay: 1.6s;
            animation-delay: 1.6s;
  }
  
  [data-anim*="delay-17"],
  [data-anim-child*="delay-17"] {
    transition-delay: 1.7s;
    -webkit-animation-delay: 1.7s;
            animation-delay: 1.7s;
  }
  
  [data-anim*="delay-17"]::after,
  [data-anim-child*="delay-17"]::after {
    -webkit-animation-delay: 1.7s;
            animation-delay: 1.7s;
  }
  
  [data-anim*="delay-17"] > *,
  [data-anim-child*="delay-17"] > * {
    -webkit-animation-delay: 1.7s;
            animation-delay: 1.7s;
  }
  
  [data-anim*="delay-18"],
  [data-anim-child*="delay-18"] {
    transition-delay: 1.8s;
    -webkit-animation-delay: 1.8s;
            animation-delay: 1.8s;
  }
  
  [data-anim*="delay-18"]::after,
  [data-anim-child*="delay-18"]::after {
    -webkit-animation-delay: 1.8s;
            animation-delay: 1.8s;
  }
  
  [data-anim*="delay-18"] > *,
  [data-anim-child*="delay-18"] > * {
    -webkit-animation-delay: 1.8s;
            animation-delay: 1.8s;
  }
  
  [data-anim*="delay-19"],
  [data-anim-child*="delay-19"] {
    transition-delay: 1.9s;
    -webkit-animation-delay: 1.9s;
            animation-delay: 1.9s;
  }
  
  [data-anim*="delay-19"]::after,
  [data-anim-child*="delay-19"]::after {
    -webkit-animation-delay: 1.9s;
            animation-delay: 1.9s;
  }
  
  [data-anim*="delay-19"] > *,
  [data-anim-child*="delay-19"] > * {
    -webkit-animation-delay: 1.9s;
            animation-delay: 1.9s;
  }
  
  [data-anim*="delay-20"],
  [data-anim-child*="delay-20"] {
    transition-delay: 2s;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  
  [data-anim*="delay-20"]::after,
  [data-anim-child*="delay-20"]::after {
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  
  [data-anim*="delay-20"] > *,
  [data-anim-child*="delay-20"] > * {
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  
  /*--------------------------------------------------
      03. Base
  ---------------------------------------------------*/
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
    font-family: var(--font-primary);
    color: var(--font-dark);
    line-height: 1;
    font-size: 16px;
    max-width: 100%;
    overflow-x: hidden;
  }
  
  @media (min-width: 1600px) {
    html {
      font-size: 1vw;
    }
  }
  
  @media (min-width: 1800px) {
    html {
      font-size: 0.92vw;
    }
  }
  
  body {
    font-family: var(--font-primary);
    overflow-x: hidden;
    background-color: white;
    color: var(--font-dark);
    line-height: 1.7;
    width: 100%;
    max-width: 100%;
    padding: 0;
    font-size: var(--text-base);
  }
  
  @media (min-width: 1260px) {
    .container {
      max-width: 77rem;
    }
  }
  
  @media (max-width: 575px) {
    .container {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }
  
  ::-moz-selection {
    color: white;
    background-color: var(--accent-color);
    text-shadow: none;
  }
  
  ::selection {
    color: white;
    background-color: var(--accent-color);
    text-shadow: none;
  }
  
  main {
    overflow-x: hidden;
  }
  
  section {
    overflow: hidden;
    position: relative;
  }
  
  a {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    transition: color 200ms ease-in-out;
  }
  
  a:hover {
    text-decoration: none;
    color: var(--accent-color);
  }
  
  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: var(--font-secondary);
  }
  
  p {
    margin: 0;
  }
  
  button:focus {
    outline: 0;
  }
  
  /*--------------------------------------------------
      04. Buttons
  ---------------------------------------------------*/
  button {
    padding: unset;
    margin: unset;
    border: unset;
    border-radius: unset;
    background-color: unset;
  }
  
  .button {
    display: inline-block;
    border: 0;
    transition: background-color 300ms cubic-bezier(0.165, 0.84, 0.44, 1), color 300ms cubic-bezier(0.165, 0.84, 0.44, 1), border 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .button.-sm {
    min-width: 7.5rem;
    padding: 0.9375rem 1.6875rem;
    font-size: 0.875rem;
    line-height: 1;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 0.03em;
  }
  
  .button.-md {
    min-width: 8.25rem;
    padding: 1.125rem 1.875rem;
    font-size: 0.9375rem;
    line-height: 1;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 0.03em;
  }
  
  @media (max-width: 767px) {
    .button.-md {
      padding: 0.9375rem 1.5rem;
      font-size: 0.875rem;
    }
  }
  
  .button.-lg {
    min-width: 8.75rem;
    padding: 1.125rem 2rem;
    font-size: 1rem;
    line-height: 1;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 0.03em;
  }
  
  @media (max-width: 767px) {
    .button.-lg {
      padding: 1.125rem 2rem;
      font-size: 0.875rem;
    }
  }
  
  .button.-outline-white,
  .button.-outline-black,
  .button.-white,
  .button.-black,
  .button.-accent {
    text-align: center;
    border-radius: 3.75rem;
  }
  
  @media (max-width: 767px) {
    .button.-outline-white,
    .button.-outline-black,
    .button.-white,
    .button.-black,
    .button.-accent {
      min-width: 7.75rem;
    }
  }
  
  .button.-white {
    background-color: white;
    border: 0.0625rem solid white;
  }
  
  .button.-white:hover {
    background-color: transparent;
    color: white;
  }
  
  .button.-black {
    background-color: #121212;
    border: 0.0625rem solid #121212;
  }
  .button.-accent {
    background-color: var(--accent-color);
    border: 0.0625rem solid var(--accent-color);
  }
  
  .button.-black:hover {
    background-color: transparent;
    color: #121212;
  }
  .button.-accent:hover {
    background-color: transparent;
    color: var(--accent-color);
  }
  
  .button.-outline-black {
    background-color: transparent;
    border: 0.0625rem solid #121212;
  }
  
  .button.-outline-black:hover {
    background-color: #121212;
    color: white;
  }
  
  .button.-outline-white {
    background-color: transparent;
    border: 0.0625rem solid #ffffff;
  }
  
  .button.-outline-white:hover {
    background-color: white;
    color: black;
  }
  
  .button.-underline {
    position: relative;
    padding-bottom: 0.125rem;
    transition: color 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .button.-underline::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.125rem;
    background-color: currentColor;
    transition: transform 300ms cubic-bezier(0.77, 0, 0.175, 1);
    transform-origin: left;
    transform: scaleX(0);
  }
  
  .button.-underline:hover::before {
    transform: scaleX(1);
  }
  
  .button.-icon .icon {
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .button.-icon:hover .icon {
    transform: translateX(0.375rem);
  }
  
  /*--------------------------------------------------
      05. Forms
  ---------------------------------------------------*/
  form label,
  form p {
    font-size: 0.9375rem;
    line-height: 1;
    color: black;
  }
  
  form input[type="text"],
  form input[type="search"],
  form textarea,
  form input[type="email"] {
    border: 0;
    font-size: 1.1875rem;
    line-height: 1.5;
    font-weight: 500;
    width: 100%;
    padding: 14px 0;
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    transition: border 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  }
  
  @media (max-width: 767px) {
    form input[type="text"],
    form input[type="search"],
    form textarea,
    form input[type="email"] {
      font-size: 1.0625rem;
    }
  }
  
  form input[type="text"]:focus,
  form input[type="search"]:focus,
  form textarea:focus,
  form input[type="email"]:focus {
    outline: none;
    border-bottom: 1px solid black;
  }
  
  form ::-webkit-input-placeholder {
    font-weight: 400;
  }
  
  form ::-moz-placeholder {
    font-weight: 400;
  }
  
  form :-ms-input-placeholder {
    font-weight: 400;
  }
  
  form ::-ms-input-placeholder {
    font-weight: 400;
  }
  
  form ::placeholder {
    font-weight: 400;
  }
  
  form.-light label,
  form.-light p {
    color: white;
  }
  
  form.-light input[type="text"],
  form.-light input[type="search"],
  form.-light textarea,
  form.-light input[type="email"] {
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  }
  
  form.-light ::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  form.-light ::-moz-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  form.-light :-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  form.-light ::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  form.-light ::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  /*--------------------------------------------------
      06. Typography
  ---------------------------------------------------*/
  h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
    letter-spacing: -0.02em;
    font-weight: 600;
    color: black;
  }
  
  h1 {
    font-size: var(--text-6xl);
  }
  
  h2 {
    font-size: var(--text-5xl);
  }
  
  h3 {
    font-size: var(--text-4xl);
  }
  
  h4 {
    font-size: var(--text-3xl);
  }
  
  h5 {
    font-size: var(--text-2xl);
  }
  
  h6 {
    font-size: var(--text-xl);
  }
  
  .text-xs {
    font-size: var(--text-xs);
  }
  
  .text-sm {
    font-size: var(--text-sm);
  }
  
  .text-base {
    font-size: var(--text-base);
  }
  
  .text-lg {
    font-size: var(--text-lg);
  }
  
  .text-xl {
    font-size: var(--text-xl);
  }
  
  .text-2xl {
    font-size: var(--text-2xl);
  }
  
  .text-3xl {
    font-size: var(--text-3xl);
  }
  
  .text-4xl {
    font-size: var(--text-4xl);
  }
  
  .text-5xl {
    font-size: var(--text-5xl);
  }
  
  .text-6xl {
    font-size: var(--text-6xl);
  }
  
  @media (max-width: 1229px) {
    .lg\:text-xs {
      font-size: var(--text-xs);
    }
    .lg\:text-sm {
      font-size: var(--text-sm);
    }
    .lg\:text-base {
      font-size: var(--text-base);
    }
    .lg\:text-lg {
      font-size: var(--text-lg);
    }
    .lg\:text-xl {
      font-size: var(--text-xl);
    }
    .lg\:text-2xl {
      font-size: var(--text-2xl);
    }
    .lg\:text-3xl {
      font-size: var(--text-3xl);
    }
    .lg\:text-4xl {
      font-size: var(--text-4xl);
    }
    .lg\:text-5xl {
      font-size: var(--text-5xl);
    }
    .lg\:text-6xl {
      font-size: var(--text-6xl);
    }
  }
  
  @media (max-width: 991px) {
    .md\:text-xs {
      font-size: var(--text-xs);
    }
    .md\:text-sm {
      font-size: var(--text-sm);
    }
    .md\:text-base {
      font-size: var(--text-base);
    }
    .md\:text-lg {
      font-size: var(--text-lg);
    }
    .md\:text-xl {
      font-size: var(--text-xl);
    }
    .md\:text-2xl {
      font-size: var(--text-2xl);
    }
    .md\:text-3xl {
      font-size: var(--text-3xl);
    }
    .md\:text-4xl {
      font-size: var(--text-4xl);
    }
    .md\:text-5xl {
      font-size: var(--text-5xl);
    }
    .md\:text-6xl {
      font-size: var(--text-6xl);
    }
  }
  
  @media (max-width: 767px) {
    .sm\:text-xs {
      font-size: var(--text-xs);
    }
    .sm\:text-sm {
      font-size: var(--text-sm);
    }
    .sm\:text-base {
      font-size: var(--text-base);
    }
    .sm\:text-lg {
      font-size: var(--text-lg);
    }
    .sm\:text-xl {
      font-size: var(--text-xl);
    }
    .sm\:text-2xl {
      font-size: var(--text-2xl);
    }
    .sm\:text-3xl {
      font-size: var(--text-3xl);
    }
    .sm\:text-4xl {
      font-size: var(--text-4xl);
    }
    .sm\:text-5xl {
      font-size: var(--text-5xl);
    }
    .sm\:text-6xl {
      font-size: var(--text-6xl);
    }
  }
  
  @media (max-width: 575px) {
    .xs\:text-xs {
      font-size: var(--text-xs);
    }
    .xs\:text-sm {
      font-size: var(--text-sm);
    }
    .xs\:text-base {
      font-size: var(--text-base);
    }
    .xs\:text-lg {
      font-size: var(--text-lg);
    }
    .xs\:text-xl {
      font-size: var(--text-xl);
    }
    .xs\:text-2xl {
      font-size: var(--text-2xl);
    }
    .xs\:text-3xl {
      font-size: var(--text-3xl);
    }
    .xs\:text-4xl {
      font-size: var(--text-4xl);
    }
    .xs\:text-5xl {
      font-size: var(--text-5xl);
    }
    .xs\:text-6xl {
      font-size: var(--text-6xl);
    }
  }
  
  .leading-xs {
    line-height: 1.1 !important;
  }
  
  .leading-sm {
    line-height: 1.2 !important;
  }
  
  .leading-md {
    line-height: 1.3 !important;
  }
  
  .leading-lg {
    line-height: 1.4 !important;
  }
  
  .leading-xl {
    line-height: 1.5 !important;
  }
  
  .leading-2xl {
    line-height: 1.6 !important;
  }
  
  .leading-3xl {
    line-height: 1.7 !important;
  }
  
  .leading-4xl {
    line-height: 1.8 !important;
  }
  
  .leading-5xl {
    line-height: 1.9 !important;
  }
  
  .leading-6xl {
    line-height: 2 !important;
  }
  
  .tracking-none {
    letter-spacing: 0 !important;
  }
  
  .tracking-sm {
    letter-spacing: 0.08em !important;
  }
  
  .tracking-md {
    letter-spacing: 0.12em !important;
  }
  
  .tracking-lg {
    letter-spacing: 0.16em !important;
  }
  
  .number {
    font-family: var(--font-secondary);
    line-height: 1;
    letter-spacing: -0.02em;
  }
  
  .number.-left {
    margin-left: -0.25rem;
  }
  
  .number.-sm {
    font-size: 3.75rem;
  }
  
  .number.-md {
    font-size: 4.6875rem;
  }
  
  .number.-lg {
    font-size: 5.625rem;
  }
  
  @media (max-width: 991px) {
    .number.-sm {
      font-size: 3.75rem;
    }
    .number.-md {
      font-size: 4.375rem;
    }
    .number.-lg {
      font-size: 5.25rem;
    }
  }
  
  @media (max-width: 767px) {
    .number.-sm {
      font-size: 3.5rem;
    }
    .number.-md {
      font-size: 4rem;
    }
    .number.-lg {
      font-size: 4.875rem;
    }
  }
  
  /*--------------------------------------------------
      07. 404
  ---------------------------------------------------*/
  /*--------------------------------------------------
      36. 404 page
  ---------------------------------------------------*/
  .page-404 {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .page-404-content {
    padding-top: 2.5rem;
  }
  
  .page-404-bg {
    position: absolute;
    left: -0.25rem;
    left: -0.4vw;
    z-index: 0;
    color: rgba(0, 0, 0, 0.02);
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.03em;
    font-size: 50vw;
  }
  
  @media (max-width: 767px) {
    .page-404-bg {
      font-size: 56vw;
      color: rgba(0, 0, 0, 0.03);
      -webkit-writing-mode: vertical-rl;
          -ms-writing-mode: tb-rl;
              writing-mode: vertical-rl;
    }
  }
  
  .page-404-bg.-light {
    color: rgba(255, 255, 255, 0.02);
  }
  
  .page-404-title {
    font-weight: 800;
    font-size: 3rem;
    line-height: 1;
  }
  
  @media (max-width: 991px) {
    .page-404-title {
      font-size: 2.375rem;
    }
  }
  
  @media (max-width: 767px) {
    .page-404-title {
      font-size: 2rem;
    }
  }
  
  .page-404-text {
    font-size: 1.25rem;
    line-height: 1.6;
  }
  
  @media (max-width: 991px) {
    .page-404-text {
      font-size: 1.0625rem;
    }
  }
  
  @media (max-width: 767px) {
    .page-404-text {
      font-size: 1rem;
    }
  }
  
  /*--------------------------------------------------
      08. Footers
  ---------------------------------------------------*/
  /*--------------------------------------------------
      12. Footer
  ---------------------------------------------------*/
  .footer.-type-1 .footer__top {
    padding-top: 8.125rem;
    padding-bottom: 6.875rem;
  }
  
  @media (max-width: 991px) {
    .footer.-type-1 .footer__top {
      padding: 5rem 0;
    }
  }
  
  .footer.-type-1 .footer__title h2 {
    font-size: 2.875rem;
    font-weight: 600;
    line-height: 1.3;
  }
  
  @media (max-width: 991px) {
    .footer.-type-1 .footer__title h2 {
      font-size: 2.5rem;
    }
  }
  
  @media (max-width: 767px) {
    .footer.-type-1 .footer__title h2 {
      font-size: 2.25rem;
    }
  }
  
  @media (max-width: 575px) {
    .footer.-type-1 .footer__title h2 {
      font-size: 1.75rem;
    }
  }
  
  .footer.-type-1 .footer__content a, .footer.-type-1 .footer__content p {
    font-size: inherit;
    line-height: inherit;
  }
  
  .footer.-type-1 .footer__bottom {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2.5rem 0;
  }
  
  @media (max-width: 991px) {
    .footer.-type-1 .footer__bottom {
      padding: 2.5rem 0;
    }
  }
  
  .footer.-type-1 .footer__bottom.-light {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .footer.-type-1 .footer__logo {
    display: flex;
    align-items: center;
    z-index: 10;
    font-size: 1.875rem;
    line-height: 1;
    font-weight: 700;
    letter-spacing: -0.04em;
  }
  
  .footer.-type-1 .footer__logo:hover {
    color: initial;
  }
  
  .footer.-type-1 .footer__logo img {
    height: 44%;
  }
  
  .footer.-type-1 .footer__copyright p {
    font-size: 0.875rem;
  }
  
  /*--------------------------------------------------
      12. Footer
  ---------------------------------------------------*/
  .footer.-type-2 {
    overflow: hidden;
    position: relative;
    z-index: 1;
    padding-top: 7.5rem;
    padding-bottom: 6.25rem;
  }
  
  @media (max-width: 991px) {
    .footer.-type-2 {
      padding-top: 6.25rem;
      padding-bottom: 5rem;
    }
  }
  
  @media (max-width: 767px) {
    .footer.-type-2 {
      padding-top: 5rem;
      padding-bottom: 3.75rem;
    }
  }
  
  .footer.-type-2 .footer__social {
    display: flex;
    flex-wrap: wrap;
  }
  
  .footer.-type-2 .footer__social a {
    line-height: 1;
    font-size: 1.375rem;
    margin-right: 2.5rem;
    margin-top: 0.75rem;
  }
  
  @media (max-width: 767px) {
    .footer.-type-2 .footer__social a {
      font-size: 1.125rem;
      margin-right: 1.25rem;
      width: 100%;
    }
  }
  
  .footer.-type-2 .footer__social a:last-child {
    margin-right: 0;
  }
  
  .footer.-type-2 .footer__text {
    margin-top: 5rem;
  }
  
  @media (max-width: 991px) {
    .footer.-type-2 .footer__text {
      margin-top: 3.75rem;
    }
  }
  
  @media (max-width: 767px) {
    .footer.-type-2 .footer__text {
      margin-top: 3rem;
    }
  }
  
  .footer.-type-2 .footer__link {
    word-wrap: break-word;
  }
  
  @media (max-width: 575px) {
    .footer.-type-2 .footer__link {
      font-size: 7vw;
    }
  }
  
  .footer.-type-2 .footer__copyright {
    color: rgba(0, 0, 0, 0.6);
    margin-top: 6.25rem;
  }
  
  @media (max-width: 991px) {
    .footer.-type-2 .footer__copyright {
      margin-top: 5rem;
    }
  }
  
  @media (max-width: 767px) {
    .footer.-type-2 .footer__copyright {
      margin-top: 3rem;
    }
  }
  
  .footer.-type-2 .footer__shapes {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  
  .footer.-type-2 .footer__shapes *:first-child {
    position: absolute;
    top: 8.0625rem;
    right: -21.625rem;
    width: 46.875rem;
    height: 46.875rem;
    border-radius: 100%;
    background-color: #141318;
    background-color: #FFF;
    box-shadow: 0px 4px 60px rgba(13, 22, 52, 0.08);
  }
  
  .footer.-type-2 .footer__shapes *:last-child {
    position: absolute;
    top: 24.875rem;
    right: -8.5rem;
    width: 50.625rem;
    height: 50.625rem;
    border-radius: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 60px rgba(13, 22, 52, 0.08);
  }
  
  /*--------------------------------------------------
      09. Blog masthead
  ---------------------------------------------------*/
  /*--------------------------------------------------
      Blog header
  ---------------------------------------------------*/
  .masthead.-blog a {
    font-weight: inherit;
    word-wrap: break-word;
    line-height: inherit;
    font-size: inherit;
    color: inherit;
    transition: color 0.2s ease;
  }
  
  .masthead.-blog a:hover {
    color: var(--accent-color);
  }
  
  .masthead.-blog .masthead__img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
  
  .masthead.-blog .masthead__content {
    position: relative;
    z-index: 1;
    padding-top: 17.5rem;
    padding-bottom: 12.5rem;
  }
  
  @media (max-width: 991px) {
    .masthead.-blog .masthead__content {
      padding-top: 15rem;
      padding-bottom: 7.5rem;
    }
  }
  
  @media (max-width: 767px) {
    .masthead.-blog .masthead__content {
      padding-top: 12.5rem;
      padding-bottom: 6.25rem;
    }
  }
  
  @media (max-width: 575px) {
    .masthead.-blog .masthead__content {
      padding-top: 11.25rem;
      padding-bottom: 5rem;
    }
  }
  
  .masthead.-blog .masthead__title {
    font-size: 4.25rem;
  }
  
  @media (max-width: 1229px) {
    .masthead.-blog .masthead__title {
      font-size: 3.875rem;
    }
  }
  
  @media (max-width: 991px) {
    .masthead.-blog .masthead__title {
      font-size: 3.5rem;
    }
  }
  
  @media (max-width: 767px) {
    .masthead.-blog .masthead__title {
      font-size: 3rem;
    }
  }
  
  @media (max-width: 575px) {
    .masthead.-blog .masthead__title {
      font-size: 2.25rem;
    }
  }
  
  .masthead.-blog .masthead__line {
    width: 2.125rem;
    height: 0.0625rem;
  }
  
  /*--------------------------------------------------
      10. Blog content
  ---------------------------------------------------*/
  /*--------------------------------------------------
      Blog content
  ---------------------------------------------------*/
  .blogPost__img {
    width: 100%;
  }
  
  .blogPost__img img {
    -o-object-fit: cover;
       object-fit: cover;
  }
  
  .blogPost__img.-hover {
    overflow: hidden !important;
  }
  
  .blogPost__img.-hover > * {
    transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  }
  
  .blogPost__img.-hover:hover > * {
    transform: scale(1.1);
  }
  
  .blogPost {
    margin-bottom: 4.375rem;
  }
  
  .blogPost:last-child {
    margin-bottom: 0rem;
  }
  
  .blogPost__title {
    word-wrap: break-word;
  }
  
  .blogPost a {
    font-weight: inherit;
    word-wrap: break-word;
    line-height: inherit;
    font-size: inherit;
    color: inherit;
    transition: color 0.2s ease;
  }
  
  .blogPost a:hover {
    color: var(--accent-color);
  }
  
  .blogPost.-single .blogPost__content h1, .blogPost.-single .blogPost__content h2, .blogPost.-single .blogPost__content h3, .blogPost.-single .blogPost__content h4, .blogPost.-single .blogPost__content h5, .blogPost.-single .blogPost__content h6 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  .blogPost.-single .blogPost__content h1 {
    font-size: 2.375rem;
    font-weight: 600;
  }
  
  .blogPost.-single .blogPost__content h2 {
    font-size: 2.125rem;
    font-weight: 600;
  }
  
  .blogPost.-single .blogPost__content h3 {
    font-size: 1.875rem;
    font-weight: 600;
  }
  
  .blogPost.-single .blogPost__content h4 {
    font-size: 1.625rem;
    font-weight: 700;
  }
  
  .blogPost.-single .blogPost__content h5 {
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  .blogPost.-single .blogPost__content h6 {
    font-size: 1.375rem;
    font-weight: 700;
  }
  
  .blogPost.-single .blogPost__content img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  
  .blogPost.-single .blogPost__content > p img,
  .blogPost.-single .blogPost__content > img {
    margin: 3.125rem 0;
  }
  
  .blogPost.-single .blogPost__content > figure {
    max-width: 100%;
    margin: 2.5rem 0;
  }
  
  .blogPost.-single .blogPost__content > figure figcaption {
    margin-top: 0.375rem;
  }
  
  .blogPost.-single .blogPost__content p, .blogPost.-single .blogPost__content li, .blogPost.-single .blogPost__content figcaption, .blogPost.-single .blogPost__content cite {
    font-size: 1.125rem;
    line-height: 1.7;
    letter-spacing: 0.01em;
    color: #313131;
  }
  
  .blogPost.-single .blogPost__content p {
    margin-bottom: 1rem;
  }
  
  .blogPost.-single .blogPost__content a {
    text-decoration: underline;
  }
  
  .blogPost.-single .blogPost__content .has-small-font-size {
    font-size: 0.8125rem;
  }
  
  .blogPost.-single .blogPost__content .has-large-font-size {
    font-size: 2.25rem;
  }
  
  .blogPost.-single .blogPost__content ul, .blogPost.-single .blogPost__content ol {
    padding: 0;
    margin: 0.75rem 0;
  }
  
  .blogPost.-single .blogPost__content > ul, .blogPost.-single .blogPost__content > ol {
    margin: 1.25rem 0;
  }
  
  .blogPost.-single .blogPost__content ol {
    padding-left: 1.25rem;
  }
  
  .blogPost.-single .blogPost__content ol > li {
    list-style: decimal;
  }
  
  .blogPost.-single .blogPost__content li {
    padding: 0.375rem 0;
  }
  
  .blogPost.-single .blogPost__content li ul, .blogPost.-single .blogPost__content li ol {
    margin: 0;
    margin-left: 1.25rem;
  }
  
  .blogPost.-single .blogPost__content .wp-block-latest-comments {
    padding-left: 0;
    list-style: none;
  }
  
  .blogPost.-single .blogPost__content .wp-block-latest-comments > li {
    list-style: none;
  }
  
  .blogPost.-single .blogPost__content pre, .blogPost.-single .blogPost__content code {
    margin: 2.5rem 0;
  }
  
  .blogPost.-single .blogPost__content table {
    width: 100%;
    margin: 2.5rem 0;
  }
  
  .blogPost.-single {
    margin-bottom: 0;
  }
  
  .blogPost.tags {
    margin-bottom: 0;
    margin-top: 3.75rem;
    font-size: 1rem;
  }
  
  .blogPost.tags .tags__item {
    display: inline-block;
    font-size: inherit;
    letter-spacing: -0.01em;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 0.25rem;
    padding: 0.25rem 0.625rem;
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .blogPost.tags .tags__item:hover {
    color: white;
    background-color: var(--accent-color);
  }
  
  /*--------------------------------------------------
      11. Blog pagination
  ---------------------------------------------------*/
  /*--------------------------------------------------
      Blog pagination
  ---------------------------------------------------*/
  .blogPost-pagination {
    display: flex;
    justify-content: center;
    padding-top: 1.25rem;
  }
  
  .blogPost-pagination__wrap > * {
    font-weight: 500;
    font-size: 1.5rem;
    padding: 0.625rem;
    line-height: 1;
    color: var(--accent-color);
    margin-right: 1.25rem;
  }
  
  .blogPost-pagination__wrap > *:last-child {
    margin-right: 0;
  }
  
  .blogPost-pagination__wrap a:hover {
    color: black;
  }
  
  .blogPost-pagination__wrap a.is-active {
    color: black;
  }
  
  /*--------------------------------------------------
      12. Blog comments
  ---------------------------------------------------*/
  /*--------------------------------------------------
      Blog comments
  ---------------------------------------------------*/
  .blogPost.comments {
    margin-bottom: 0;
    margin-top: 3.75rem;
  }
  
  .blogPost.comments .blogPost__content .comments__list li {
    list-style: none;
  }
  
  .blogPost.comments .blogPost__content .comments__list > li:first-child > .comments-item-inner {
    border-top: none;
  }
  
  .blogPost.comments .blogPost__content .comments__list > li:first-child {
    border-top: none;
  }
  
  .blogPost.comments .blogPost__content .comments__list .trackback a,
  .blogPost.comments .blogPost__content .comments__list .pingback a {
    line-height: 1.5rem;
    text-decoration: none !important;
  }
  
  .blogPost.comments .blogPost__content .comments__list {
    margin: 0;
    margin-top: 3rem;
  }
  
  .blogPost.comments .blogPost__content .comments__list > li {
    padding-bottom: 3rem;
    margin-bottom: 3rem;
  }
  
  .blogPost.comments .blogPost__content .comments__list > li:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .blogPost.comments .blogPost__content .comments__item-inner {
    display: flex;
  }
  
  .blogPost.comments .blogPost__content .comments__item .children {
    padding: 0;
    margin: 0;
    padding-left: 5%;
    padding-top: 3rem;
  }
  
  .blogPost.comments .blogPost__content .comments__body {
    width: 100%;
  }
  
  .blogPost.comments .blogPost__content .comments__img {
    flex: 0 0 auto;
    width: 80px;
    height: 80px;
    max-width: 5rem;
    margin-right: 1.375rem;
  }
  
  @media (max-width: 767px) {
    .blogPost.comments .blogPost__content .comments__img {
      width: 48px;
      height: 48px;
      max-width: 3rem;
      margin-right: 1rem;
    }
  }
  
  .blogPost.comments .blogPost__content .comments__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  
  @media (max-width: 767px) {
    .blogPost.comments .blogPost__content .comments__header {
      flex-flow: column;
    }
  }
  
  .blogPost.comments .blogPost__content .comments__header h4 {
    font-size: 1.25rem;
    line-height: 1.3;
    font-weight: 600;
  }
  
  .blogPost.comments .blogPost__content .comments__header p {
    display: block;
    font-size: 0.875rem;
    line-height: 1.3;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
  }
  
  .blogPost.comments .blogPost__content .comments__text p {
    margin: 0;
    margin-top: 0.625rem;
    font-size: 1rem;
    letter-spacing: 0.01em;
    color: #313131;
  }
  
  .blogPost.comments .blogPost__content .comments__text a {
    text-decoration: underline;
  }
  
  .blogPost.comments .blogPost__content .comments__text ul, .blogPost.comments .blogPost__content .comments__text ul li {
    list-style: disc inside !important;
  }
  
  .blogPost.comments .blogPost__content .comments__text ol, .blogPost.comments .blogPost__content .comments__text ol li {
    list-style: decimal inside !important;
  }
  
  .blogPost.comments .blogPost__content .comments__reply a {
    margin-top: 0.75rem;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.04em;
    line-height: 1.3;
    color: black;
  }
  
  /*--------------------------------------------------
      13. Blog respond form
  ---------------------------------------------------*/
  /*--------------------------------------------------
      Respond form
  ---------------------------------------------------*/
  .respondForm {
    margin-top: 1.875rem;
  }
  
  .respondForm h3 {
    color: black;
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 600;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  
  .respondForm h3 small a {
    text-transform: uppercase;
    letter-spacing: 0.06em;
    font-size: 0.9375rem;
    color: var(--accent-color);
    margin-left: 0.5rem;
  }
  
  .respondForm__notes {
    color: var(--font-dark);
    font-size: 1rem;
  }
  
  .respondForm .logged-in-as {
    display: flex;
    margin-bottom: 0.75rem;
  }
  
  .respondForm .logged-in-as a {
    padding-right: 1rem;
  }
  
  .respondForm form p {
    font-size: 0.9375rem;
    line-height: 1;
  }
  
  /*--------------------------------------------------
      14. Blog sidebar
  ---------------------------------------------------*/
  /*--------------------------------------------------
      blogSidebar
  ---------------------------------------------------*/
  .blogSidebar {
    padding-left: 6.875rem;
  }
  
  @media (max-width: 991px) {
    .blogSidebar {
      padding-left: 0;
      margin-top: 5rem;
    }
  }
  
  .blogSidebar .widget {
    margin-bottom: 2.5rem;
  }
  
  .blogSidebar .widget:last-child {
    margin-bottom: 0;
  }
  
  .blogSidebar .widget-title {
    font-size: 1.375rem;
    font-weight: 600;
    margin-bottom: 1.25rem;
  }
  
  .blogSidebar p, .blogSidebar li, .blogSidebar span {
    font-size: 1rem;
    line-height: 1.6;
    color: black;
  }
  
  .blogSidebar ul ul, .blogSidebar ul ol, .blogSidebar ol ul, .blogSidebar ol ol {
    padding-left: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .blogSidebar li {
    margin-bottom: 0.5rem;
  }
  
  .blogSidebar li:last-child {
    margin-bottom: 0;
  }
  
  .blogSidebar a {
    font-weight: inherit;
    word-wrap: break-word;
    line-height: inherit;
    font-size: inherit;
    color: inherit;
    transition: color 0.2s ease;
  }
  
  .blogSidebar a:hover {
    color: var(--accent-color);
  }
  
  .blogSidebar p {
    margin: 0.75rem 0;
  }
  
  .blogSidebar img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  
  .blogSidebar select {
    width: 100%;
  }
  
  /*--------------------------------------------------
      15. Blog sidebar widgets
  ---------------------------------------------------*/
  /*--------------------------------------------------
      Widgets
  ---------------------------------------------------*/
  .blogSidebar .widget_search .search__wrap {
    position: relative;
    width: 100%;
  }
  
  .blogSidebar .widget_search input[type="search"] {
    background-color: #F5F5F5;
    padding: 1rem;
    padding-right: 3.5rem;
    font-size: 1.0625rem;
  }
  
  .blogSidebar .widget_search .search__button {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 100%;
    transition: color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .blogSidebar .widget_search .search__button:hover {
    color: var(--accent-color);
    background-color: rgba(0, 0, 0, 0.08);
  }
  
  .blogSidebar .widget_search .search__button .icon {
    width: 1.5rem;
    height: 1.5rem;
    stroke-width: 1.75;
  }
  
  .blogSidebar .widget_recent_comments li {
    margin-bottom: 1.25rem;
  }
  
  .blogSidebar .widget_recent_comments li:last-child {
    padding-bottom: 0;
  }
  
  .blogSidebar .widget_recent_comments .comment-author-link {
    display: inline-block;
    margin-bottom: 0.125rem;
  }
  
  .blogSidebar .widget_recent_entries li {
    margin-bottom: 1.25rem;
  }
  
  .blogSidebar .widget_recent_entries li:last-child {
    padding-bottom: 0;
  }
  
  .blogSidebar .widget_recent_entries span {
    display: block;
    margin-bottom: 0.125rem;
  }
  
  .blogSidebar .widget_tag_cloud .tagcloud {
    display: flex;
    flex-wrap: wrap;
  }
  
  .blogSidebar .widget_tag_cloud .tagcloud a {
    font-size: 0.9375rem;
    font-weight: 500;
    letter-spacing: -0.01em;
    padding: 0.375rem 0.625rem;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 0.25rem;
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .blogSidebar .widget_tag_cloud .tagcloud a:hover {
    color: white;
    background-color: var(--accent-color);
  }
  
  .blogSidebar .widget_tag_cloud .tagcloud a {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  /*--------------------------------------------------
      16. Header, menus
  ---------------------------------------------------*/
  /*--------------------------------------------------
      06. Header
  ---------------------------------------------------*/
  .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    height: 6rem;
  }
  
  .header__bar {
    position: absolute;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 3rem;
  }
  
  @media (max-width: 767px) {
    .header {
      height: 3.75rem;
    }
    .header__bar {
      padding: 0 1.5rem;
    }
  }
  
  .header__logo {
    position: relative;
    z-index: 1;
    font-weight: 600;
    font-size: 1.625rem;
    line-height: 1;
    letter-spacing: -0.02em;
  }
  
  .header__logo a {
    height: 100%;
  }
  
  .header__logo div,
  .header__logo img {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-fit: cover;
       object-fit: cover;
    transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.05s;
    opacity: 0;
  }
  
  @media (min-width: 992px) {
    .header.-classic .header__menu__wrap {
      display: none !important;
    }
  }
  
  @media (max-width: 991px) {
    .header.-classic .navClassic-wrap {
      display: none;
    }
  }
  
  .header .header__logo__dark {
    position: relative;
    opacity: 1;
  }
  
  .header .header__logo a {
    color: black;
  }
  
  .header .navClassic-list > li {
    color: black;
  }
  
  .header .menu-header-list li a {
    color: black;
  }
  
  .header .header__menu .icon {
    color: black;
  }
  
  .header.-dark .header__logo__light {
    position: relative;
    opacity: 1;
  }
  
  .header.-dark .header__logo__dark {
    position: absolute;
    opacity: 0;
  }
  
  .header.-dark .header__logo a {
    color: white;
  }
  
  .header.-dark .navClassic-list > li {
    color: white;
  }
  
  .header.-dark .menu-header-list li a {
    color: white;
  }
  
  .header.-dark .header__menu .icon {
    color: white;
  }
  
  .header.-sticky-initial.-sticky-light .header__bar,
  .header.is-sticky.-sticky-light .header__bar {
    background-color: #fff;
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.08);
    opacity: 0.98;
  }
  
  .header.-sticky-initial.-sticky-light .header__logo__light,
  .header.is-sticky.-sticky-light .header__logo__light {
    position: absolute;
    opacity: 0;
  }
  
  .header.-sticky-initial.-sticky-light .header__logo__dark,
  .header.is-sticky.-sticky-light .header__logo__dark {
    position: relative;
    opacity: 1;
  }
  
  .header.-sticky-initial.-sticky-light .header__logo a,
  .header.is-sticky.-sticky-light .header__logo a {
    color: black;
  }
  
  .header.-sticky-initial.-sticky-light .navClassic-list > li,
  .header.is-sticky.-sticky-light .navClassic-list > li {
    color: black;
  }
  
  .header.-sticky-initial.-sticky-light .menu-header-list li a,
  .header.is-sticky.-sticky-light .menu-header-list li a {
    color: black;
  }
  
  .header.-sticky-initial.-sticky-light .header__menu .icon,
  .header.is-sticky.-sticky-light .header__menu .icon {
    color: black;
  }
  
  .header.-sticky-initial.-sticky-light .header__bar {
    box-shadow: none;
  }
  
  .header.-sticky-initial.-sticky-light.is-sticky .header__bar {
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.08);
  }
  
  .header.-sticky-initial.-sticky-dark .header__bar,
  .header.is-sticky.-sticky-dark .header__bar {
    background-color: var(--bg-dark-4);
    opacity: 0.99;
  }
  
  .header.-sticky-initial.-sticky-dark .header__logo__light,
  .header.is-sticky.-sticky-dark .header__logo__light {
    position: relative;
    opacity: 1;
  }
  
  .header.-sticky-initial.-sticky-dark .header__logo__dark,
  .header.is-sticky.-sticky-dark .header__logo__dark {
    position: absolute;
    opacity: 0;
  }
  
  .header.-sticky-initial.-sticky-dark .header__logo a,
  .header.is-sticky.-sticky-dark .header__logo a {
    color: white;
  }
  
  .header.-sticky-initial.-sticky-dark .navClassic-list > li,
  .header.is-sticky.-sticky-dark .navClassic-list > li {
    color: white;
  }
  
  .header.-sticky-initial.-sticky-dark .menu-header-list li a,
  .header.is-sticky.-sticky-dark .menu-header-list li a {
    color: white;
  }
  
  .header.-sticky-initial.-sticky-dark .header__menu .icon,
  .header.is-sticky.-sticky-dark .header__menu .icon {
    color: white;
  }
  
  .header__menu .nav-button-open {
    cursor: pointer;
  }
  
  .header__menu .nav-button-open .icon {
    width: 2.25rem;
    height: 2.25rem;
    stroke-width: 1.25;
  }
  
  .header__menu .nav-button-open:hover .icon {
    color: var(--accent-color) !important;
  }
  
  .header {
    will-change: height, transform, background-color;
    transition: height 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0.05s, transform 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  
  .header .header__bar {
    transition: box-shadow 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0.05s, background-color 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0.05s;
  }
  
  .header .header__logo a {
    transition: color 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0.05s;
  }
  
  .header .header__menu .icon {
    transition: color 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0.05s;
  }
  
  .header.is-pinned {
    transform: translateY(0);
  }
  
  .header.is-unpinned {
    transform: translateY(-100%);
  }
  
  .header.is-sticky {
    position: fixed;
    height: 4.25rem;
  }
  
  /*--------------------------------------------------
      15.2. Navigation background
  ---------------------------------------------------*/
  .nav__inner {
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
  }
  
  .nav__inner.is-active {
    opacity: 1;
    pointer-events: auto;
  }
  
  .nav__bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--bg-dark-3);
    box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.12);
    transform-origin: bottom;
  }
  
  .nav__container {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding-top: 5rem;
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  
  @media (max-width: 1200px) {
    .nav__container {
      padding-left: 6.25rem;
      padding-right: 6.25rem;
    }
  }
  
  @media (max-width: 991px) {
    .nav__container {
      padding-top: 2.5rem;
      padding-left: 3.75rem;
      padding-right: 3.75rem;
    }
  }
  
  @media (max-width: 767px) {
    .nav__container {
      padding-top: 1.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  
  .nav__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .nav .nav-button-back .icon,
  .nav .nav-btn-close .icon {
    color: white;
    width: 4rem;
    height: 4rem;
    stroke-width: 0.5;
  }
  
  @media (max-width: 991px) {
    .nav .nav-button-back .icon,
    .nav .nav-btn-close .icon {
      width: 3.5rem;
      height: 3.5rem;
    }
  }
  
  @media (max-width: 767px) {
    .nav .nav-button-back .icon,
    .nav .nav-btn-close .icon {
      width: 3rem;
      height: 3rem;
    }
  }
  
  .nav__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding-top: 6.25rem;
    padding-right: 12vw;
  }
  
  .nav__content__left {
    position: relative;
    flex: 0 0 70%;
    max-width: 70%;
  }
  
  .nav__content__right {
    position: relative;
    flex: 0 0 21%;
    max-width: 21%;
  }
  
  @media (max-width: 1280px) {
    .nav__content {
      padding-right: 0;
    }
    .nav__content__left {
      flex: 0 0 70%;
      max-width: 70%;
    }
    .nav__content__right {
      flex: 0 0 30%;
      max-width: 30%;
    }
  }
  
  @media (max-width: 767px) {
    .nav__content {
      padding-top: 3.75rem;
    }
    .nav__content__left {
      flex: 0 0 60%;
      max-width: 60%;
    }
    .nav__content__right {
      flex: 0 0 32%;
      max-width: 32%;
    }
  }
  
  @media (max-width: 575px) {
    .nav__content__left {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  .nav__info__item {
    overflow: hidden;
    margin-top: 3rem;
  }
  
  .nav__info__item:first-child {
    margin-top: 0;
  }
  
  .nav__info__item h5 {
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.6);
  }
  
  .nav__info__item a, .nav__info__item p {
    display: block;
    font-size: 1.125rem;
  }
  
  @media (max-width: 991px) {
    .nav__info__item a, .nav__info__item p {
      font-size: 1rem;
    }
  }
  
  .nav__info__content > * {
    margin-top: 0.25rem;
  }
  
  .nav__info__content > *:last-child {
    margin-top: 0;
  }
  
  @media (max-width: 991px) {
    .nav__info__item {
      margin-top: 2rem;
    }
  }
  
  @media (max-width: 575px) {
    .nav__info {
      display: none;
    }
  }
  
  /*--------------------------------------------------
      15.3. Navigation list
  ---------------------------------------------------*/
  .navList__wrap ul {
    pointer-events: none;
  }
  
  .navList__wrap ul.is-visible {
    pointer-events: auto !important;
  }
  
  .navList__wrap li {
    overflow: hidden;
  }
  
  .navList__wrap a {
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .navList__wrap a:hover {
    color: var(--accent-color);
  }
  
  .navList__wrap a:not([href]):not([tabindex]):hover {
    color: var(--accent-color);
  }
  
  .navList {
    margin-top: -0.5rem;
    pointer-events: none;
  }
  
  .navList li a {
    cursor: pointer;
    display: inline-block;
    color: white;
    padding: 8px 0;
    font-size: 3.25rem;
    line-height: 1.2;
    font-weight: 500;
  }
  
  @media (max-width: 991px) {
    .navList li a {
      font-size: 2.75rem;
    }
  }
  
  @media (max-width: 575px) {
    .navList li a {
      font-size: 2.5rem;
    }
  }
  
  .subnav-list {
    position: absolute;
    top: -0.5rem;
  }
  
  .subnav-list li a {
    transform: translateY(100%);
    font-size: 2.75rem;
  }
  
  @media (max-width: 991px) {
    .subnav-list li a {
      font-size: 2.25rem;
    }
  }
  
  @media (max-width: 575px) {
    .subnav-list li a {
      font-size: 2rem;
    }
  }
  
  .navList .menu-item-has-children > a::after {
    display: inline-block;
    vertical-align: middle;
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.4);
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .navList .menu-item-has-children > a::after {
    content: "\e900";
    margin-left: 1.25rem;
  }
  
  .navList .menu-item-has-children > a:hover::after {
    color: var(--accent-color);
    transform: translateX(10px);
  }
  
  @media (min-width: 1600px) {
    .nav__container {
      padding-top: 5rem;
      padding-left: 7.5rem;
      padding-right: 7.5rem;
    }
    .nav-button-back .icon,
    .nav-btn-close .icon {
      width: 4.05vw !important;
      height: 4.05vw !important;
    }
    .nav__content {
      padding-top: 5rem;
    }
    .navList li a {
      padding: 0.5rem 0;
    }
    .navList li a {
      font-size: 3.4vw;
    }
    .subnav-list li a {
      font-size: 2.84vw;
    }
    .navList .menu-item-has-children > a::after {
      font-size: 0.9vw;
    }
    .navList .menu-item-has-children > a::after {
      margin-left: 1.3vw;
    }
    .navList .menu-item-has-children > a:hover::after {
      transform: translateX(0.6vw);
    }
    .nav__info__item {
      margin-top: 3.05vw;
    }
    .nav__info__item h5 {
      font-size: 0.9vw;
    }
    .nav__info__content a, .nav__info__content p {
      font-size: 1.125rem;
      font-size: 1.12vw;
    }
  }
  
  /*--------------------------------------------------
      40. Navigation base
  ---------------------------------------------------*/
  .navClassic-wrap {
    height: 100%;
  }
  
  .navClassic-wrap a {
    cursor: pointer;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
  
  .navClassic-list {
    display: flex;
    height: 100%;
  }
  
  .navClassic-list > li {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1.25rem;
  }
  
  .navClassic-list > li:hover {
    color: var(--accent-color);
  }
  
  .navClassic-list .tippy-box {
    border-radius: 0.25rem;
    min-width: 11.25rem;
    padding: 0.625rem 0;
  }
  
  .navClassic-list .tippy-box[data-placement^="right"] > .tippy-arrow, .navClassic-list .tippy-box[data-placement^="left"] > .tippy-arrow {
    color: transparent;
  }
  
  .navClassic-list .tippy-box[data-placement^="right"] > .tippy-arrow::before, .navClassic-list .tippy-box[data-placement^="left"] > .tippy-arrow::before {
    border-bottom-color: transparent;
  }
  
  .navClassic-list .tippy-box .tippy-content {
    display: block;
    position: relative;
    white-space: nowrap;
    padding: 0;
  }
  
  .navClassic-list .tippy-box .tippy-content a {
    display: block;
    padding: 0.625rem 2rem;
    width: 100%;
    font-size: 1rem;
    transition: color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .navClassic-list .subnav-list {
    position: relative;
    top: unset;
  }
  
  .navClassic-list .subnav-list a {
    transform: none;
  }
  
  .tippy-box[data-theme~='dark'] {
    background-color: #111111;
    box-shadow: 0 0.25rem 0.9375rem 0 rgba(0, 0, 0, 0.2);
  }
  
  .tippy-box[data-theme~='dark'] > .tippy-arrow::before {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  
  .tippy-box[data-theme~='dark'] a {
    color: white;
  }
  
  .tippy-box[data-theme~='dark'] a:hover {
    color: var(--accent-color);
    background-color: rgba(255, 255, 255, 0.04);
  }
  
  .tippy-box[data-theme~='light'] {
    background-color: #ffffff;
    box-shadow: 0 0.25rem 0.9375rem 0 rgba(0, 0, 0, 0.1);
  }
  
  .tippy-box[data-theme~='light'] > .tippy-arrow::before {
    border-bottom-color: white;
  }
  
  .tippy-box[data-theme~='light'] a {
    color: black;
  }
  
  .tippy-box[data-theme~='light'] a:hover {
    color: var(--accent-color);
    background-color: rgba(0, 0, 0, 0.03);
  }
  
  .tippy-box[data-animation='shift'][data-state='hidden'] {
    opacity: 0;
    transform: translateY(1.25rem);
  }
  
  /*--------------------------------------------------
      17. Nav button
  ---------------------------------------------------*/
  .navButton {
    display: flex;
  }
  
  .navButton__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 100%;
    min-width: unset !important;
    transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), border 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .navButton .icon {
    width: 2rem;
    height: 2rem;
    stroke-width: 1;
  }
  
  .navButton.-sm .navButton__item {
    width: 3.25rem;
    height: 3.25rem;
  }
  
  .navButton.-sm .navButton__item .icon {
    width: 1.75rem;
    height: 1.75rem;
  }
  
  .navButton.-md .navButton__item {
    width: 3.75rem;
    height: 3.75rem;
  }
  
  .navButton.-md .navButton__item .icon {
    width: 2rem;
    height: 2rem;
  }
  
  .navButton.-lg .navButton__item {
    width: 4.25rem;
    height: 4.25rem;
  }
  
  .navButton.-lg .navButton__item .icon {
    width: 2.25rem;
    height: 2.25rem;
  }
  
  /*--------------------------------------------------
      18. Testimonials
  ---------------------------------------------------*/
  .testimonials-slider .testimonials {
    transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), background-color 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), border-color 0.8s cubic-bezier(0.165, 0.84, 0.44, 1), box-shadow 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .testimonials-slider .testimonials .testimonials__comment,
  .testimonials-slider .testimonials .testimonials__author {
    transition: color 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .testimonials-slider .testimonials {
    border: none;
    box-shadow: 0px 1.25rem 3.125rem rgba(63, 76, 96, 0.07);
  }
  
  .testimonials-slider .swiper-slide-active .testimonials {
    background-color: var(--bg-dark-1);
  }
  
  .testimonials-slider .swiper-slide-active .testimonials .testimonials__comment {
    color: white;
  }
  
  .testimonials-slider .swiper-slide-active .testimonials .testimonials__author h6 {
    color: var(--font-light);
  }
  
  .testimonials-slider .swiper-slide-active .testimonials .testimonials__author h6 .text-black {
    color: white;
  }
  
  .testimonials {
    position: relative;
    height: 100%;
  }
  
  /*--------------------------------------------------
      19. Video
  ---------------------------------------------------*/
  .sectionVideo {
    position: relative;
    z-index: unset !important;
  }
  
  .sectionVideo__btn {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.5rem;
    height: 4.5rem;
    border: 0;
    border-radius: 100%;
  }
  
  .sectionVideo__btn::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: white;
  }
  
  .sectionVideo__btn__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .sectionVideo__btn i {
    position: relative;
    z-index: 2;
  }
  
  .sectionVideo__btn::after {
    -webkit-animation: video_btn_pulse 2.4s infinite;
            animation: video_btn_pulse 2.4s infinite;
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  @-webkit-keyframes video_btn_pulse {
    0% {
      opacity: 0.6;
      transform: scale(1);
    }
    75% {
      opacity: 0;
      transform: scale(1.4);
    }
    100% {
      opacity: 0;
      transform: scale(1.4);
    }
  }
  
  @keyframes video_btn_pulse {
    0% {
      opacity: 0.6;
      transform: scale(1);
    }
    75% {
      opacity: 0;
      transform: scale(1.4);
    }
    100% {
      opacity: 0;
      transform: scale(1.4);
    }
  }
  
  /*--------------------------------------------------
      20. Back button
  ---------------------------------------------------*/
  .backButton {
    pointer-events: none;
    opacity: 0;
    transform: scale(0.8);
    transition: transform 0.55s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .backButton.is-visible {
    pointer-events: auto;
    opacity: 1;
    transform: scale(1);
  }
  
  .backButton {
    cursor: pointer;
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
  }
  
  @media (max-width: 767px) {
    .backButton {
      bottom: 1.5rem;
      right: 1.5rem;
    }
  }
  
  @media (max-width: 575px) {
    .backButton {
      width: 68px;
      height: 68px;
    }
  }
  
  .backButton__bg {
    position: absolute;
    z-index: -1;
    background-color: black;
    border-radius: 100%;
    width: 100%;
    height: 100%;
  }
  
  .backButton__icon__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .backButton__button {
    color: white;
    stroke-width: 0.75;
    max-height: 100%;
    width: 3.5rem;
    height: 3.5rem;
  }
  
  @media (max-width: 767px) {
    .backButton__button {
      width: 52px;
      height: 52px;
    }
  }
  
  @media (max-width: 575px) {
    .backButton__button {
      width: 44px;
      height: 44px;
    }
  }
  
  .backButton .backButton__bg {
    transition: transform 0.55s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .backButton:hover .backButton__bg {
    transform: scale(1.1);
  }
  
  /*--------------------------------------------------
      21. Breadcrumbs
  ---------------------------------------------------*/
  .breadcrumbs a {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
  
  .breadcrumbs {
    display: flex;
  }
  
  .breadcrumbs-item {
    display: flex;
    font-size: 1rem;
    color: inherit;
    line-height: 1.4;
  }
  
  .breadcrumbs-item::before {
    content: "/";
    color: inherit;
    font-weight: 400;
    padding: 0 0.3125rem;
  }
  
  .breadcrumbs-item:first-child::before {
    display: none;
  }
  
  .breadcrumbs .active {
    font-weight: 700;
  }
  
  /*--------------------------------------------------
      22. Social
  ---------------------------------------------------*/
  .social {
    display: flex;
    z-index: 100;
  }
  
  .social.-bordered .social__item {
    border-radius: 100%;
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .social__item {
    position: relative;
    font-size: 1rem;
    transition: color 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
    margin-right: 1rem;
  }
  
  .social__item:hover {
    color: var(--accent-color);
  }
  
  .social__item:last-child {
    margin-right: 0;
  }
  
  /*--------------------------------------------------
      23. Contact
  ---------------------------------------------------*/
  .contact-form.-type-1 label {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .contact-form.-type-1 label .form__error {
    display: none;
    color: red;
    font-size: 1rem;
    margin-top: 0.375rem;
  }
  
  .contact-form.-type-1 label .form__error.is-active {
    display: block;
  }
  
  .contact-form.-type-1 .ajax-form-alert {
    display: none;
  }
  
  .contact-form.-type-1 .ajax-form-alert.is-active {
    display: block;
  }
  
  .contact-form.-type-1 .ajax-form-alert.is-error .ajax-form-alert__content {
    background-color: rgba(255, 0, 0, 0.25);
  }
  
  .contact-form.-type-1 .ajax-form-alert.is-success .ajax-form-alert__content {
    background-color: rgba(0, 128, 0, 0.2);
  }
  
  .contact-form.-type-1 .ajax-form-alert__content {
    display: inline-block;
    color: black;
    padding: 0.625rem 1rem;
    border-radius: 0.25rem;
  }
  
  .contact-form.-type-1 .ajax-form-alert__content a {
    font-weight: 600;
  }
  
  .contact-form.-type-1 .ajax-form-alert__content a:hover {
    color: black;
    text-decoration: underline;
  }
  
  .contact-form.-type-1 input.-error,
  .contact-form.-type-1 textarea.-error {
    border-color: red !important;
  }
  
  /*--------------------------------------------------
      24. Section heading
  ---------------------------------------------------*/
  .sectionHeading__subtitle {
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: black;
  }
  
  .sectionHeading__subtitle.-light {
    color: white;
  }
  
  .sectionHeading.-sm .sectionHeading__title {
    line-height: 1.3;
    font-weight: 700;
    letter-spacing: 0;
    font-size: 2.5rem;
  }
  
  @media (max-width: 991px) {
    .sectionHeading.-sm .sectionHeading__title {
      font-size: 2.375rem;
    }
  }
  
  @media (max-width: 767px) {
    .sectionHeading.-sm .sectionHeading__title {
      font-size: 1.75rem;
    }
  }
  
  .sectionHeading.-md .sectionHeading__title {
    line-height: 1.1;
    font-weight: 700;
    font-size: 3.5rem;
  }
  
  @media (max-width: 991px) {
    .sectionHeading.-md .sectionHeading__title {
      font-size: 3.25rem;
    }
  }
  
  @media (max-width: 767px) {
    .sectionHeading.-md .sectionHeading__title {
      font-size: 3rem;
    }
  }
  
  @media (max-width: 575px) {
    .sectionHeading.-md .sectionHeading__title {
      font-size: 2.75rem;
    }
  }
  
  .sectionHeading.-lg .sectionHeading__title {
    line-height: 1.1;
    font-weight: 700;
    font-size: 4.25rem;
  }
  
  @media (max-width: 991px) {
    .sectionHeading.-lg .sectionHeading__title {
      font-size: 3.5rem;
    }
  }
  
  @media (max-width: 767px) {
    .sectionHeading.-lg .sectionHeading__title {
      font-size: 3.125rem;
    }
  }
  
  @media (max-width: 575px) {
    .sectionHeading.-lg .sectionHeading__title {
      font-size: 3rem;
    }
  }
  
  .sectionHeading.-xl .sectionHeading__title {
    line-height: 1.2;
    font-weight: 700;
    font-size: 5.625rem;
  }
  
  @media (max-width: 1229px) {
    .sectionHeading.-xl .sectionHeading__title {
      font-size: 4.75rem;
    }
  }
  
  @media (max-width: 991px) {
    .sectionHeading.-xl .sectionHeading__title {
      font-size: 3.5rem;
    }
  }
  
  @media (max-width: 575px) {
    .sectionHeading.-xl .sectionHeading__title {
      font-size: 3rem;
    }
  }
  
  /*--------------------------------------------------
      25. Parallax
  ---------------------------------------------------*/
  [data-parallax] {
    position: relative;
    z-index: 0;
  }
  
  [data-parallax] > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  [data-parallax] [data-parallax-target] {
    position: absolute;
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  /*--------------------------------------------------
      26. Preloader
  ---------------------------------------------------*/
  body.preloader-visible .preloader__bg {
    transform: scaleY(1);
  }
  
  body.preloader-visible .preloader__progress {
    opacity: 1;
  }
  
  .preloader {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5000;
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .preloader__bg {
    pointer-events: auto;
    height: 100%;
    width: 100%;
    background-color: var(--bg-dark-4);
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.12);
    will-change: transform;
    transform-origin: bottom;
    transform: scaleY(0);
  }
  
  .preloader__progress {
    position: absolute;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 3.5rem;
    background-color: #FFFFFF;
    opacity: 0;
    -webkit-mask-image: var(--preloader-image);
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-image: var(--preloader-image);
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
  }
  
  .preloader__progress__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--accent-color);
    transform-origin: bottom;
    transform: scaleY(0);
  }
  
  /*--------------------------------------------------
      27. UI elements
  ---------------------------------------------------*/
  .ui-element {
    position: absolute;
    z-index: 50;
  }
  
  .ui-element.-fixed {
    position: fixed;
  }
  
  .ui-element.-bottom {
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .ui-element.-bottom-left {
    bottom: 3rem;
    left: 3rem;
  }
  
  .ui-element.-bottom-right {
    bottom: 3rem;
    right: 3rem;
  }
  
  .ui-element.-left {
    left: 3rem;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .ui-element.-right {
    right: 3rem;
    top: 50%;
    transform: translateY(-50%);
  }
  
  @media (max-width: 767px) {
    .ui-element.-bottom {
      bottom: 1.5rem;
    }
    .ui-element.-bottom-left {
      bottom: 1.5rem;
      left: 1.5rem;
    }
    .ui-element.-bottom-right {
      bottom: 1.5rem;
      right: 1.5rem;
    }
    .ui-element.-left {
      left: 1.5rem;
    }
    .ui-element.-right {
      right: 1.5rem;
    }
  }
  
  .ui-element__social {
    display: flex;
    font-size: 1rem;
    line-height: 100%;
  }
  
  .ui-element__social a {
    font-size: inherit;
    line-height: inherit;
    margin-right: 1.5rem;
  }
  
  .ui-element__social a:last-child {
    margin: 0;
  }
  
  .ui-element__social.-column {
    flex-flow: column wrap;
  }
  
  .ui-element__social.-column a {
    margin: 0;
    margin-bottom: 1.5rem;
  }
  
  .ui-element__social.-column a:last-child {
    margin-bottom: 0;
  }
  
  .ui-element__scroll {
    overflow: hidden;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 0.02em;
    font-weight: 400;
  }
  
  .ui-element__scroll .icon {
    width: 1.5rem;
    height: 1.5rem;
    stroke-width: 1.5;
    margin-left: 0.5rem;
  }
  
  .ui-element__scroll .icon {
    -webkit-animation-duration: 1.8s;
            animation-duration: 1.8s;
    -webkit-animation-name: uiScrollInfinite;
            animation-name: uiScrollInfinite;
    -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
            animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  
  @-webkit-keyframes uiScrollInfinite {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(100%);
    }
  }
  
  @keyframes uiScrollInfinite {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(100%);
    }
  }
  
  /*--------------------------------------------------
      28. Pagination
  ---------------------------------------------------*/
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 10;
  }
  
  .pagination__item {
    border-radius: 100%;
    width: 0.75rem;
    height: 0.75rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    transform: scale(0.5);
    transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .pagination__item.is-active {
    transform: scale(1);
  }
  
  .pagination.-light .pagination__item {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .pagination.-light .pagination__item.is-active {
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .pagination.-dark .pagination__item {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .pagination.-dark .pagination__item.is-active {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /*--------------------------------------------------
      29. Cursor
  ---------------------------------------------------*/
  body.is-cursor-active {
    cursor: none !important;
  }
  
  body.is-cursor-active * {
    cursor: none !important;
  }
  
  .cursor {
    position: fixed;
    pointer-events: none;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .cursor__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .cursor__follower {
    position: absolute;
    background-color: #888888;
    opacity: 0.25;
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), background-color 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .cursor__arrows {
    position: absolute;
    display: flex;
    opacity: 0;
    transform: scale(0.7) rotate(40deg);
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .cursor__arrows > * {
    position: relative;
    color: black;
    stroke-width: 1;
  }
  
  .cursor__arrows > *:first-child {
    left: -0.625rem;
  }
  
  .cursor__arrows > *:last-child {
    right: -0.625rem;
  }
  
  .cursor__label {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    letter-spacing: 0.05em;
    transform: scale(0);
    will-change: transform, opacity;
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .cursor__icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transform: scale(0);
    will-change: transform, opacity;
    transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .cursor__icon svg {
    width: 48px;
    height: 48px;
    stroke-width: 0.75;
  }
  
  .cursor {
    visibility: hidden;
  }
  
  .cursor.is-enabled {
    visibility: visible;
  }
  
  .cursor.is-hidden .cursor__wrapper {
    transform: scale(0) !important;
  }
  
  .cursor.is-mouse-down .cursor__wrapper {
    transform: scale(0.8);
  }
  
  .cursor.is-active .cursor__follower {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.7);
    transform: scale(3.6);
    opacity: 0.25;
  }
  
  .cursor.is-active.has-label .cursor__follower {
    background-color: black;
    opacity: 0.8;
  }
  
  .cursor.is-active.has-label .cursor__label {
    transform: scale(1);
  }
  
  .cursor.is-active.has-icon .cursor__follower {
    background-color: black;
    opacity: 0.8;
  }
  
  .cursor.is-active.has-icon .cursor__icon {
    transform: scale(1);
  }
  
  /*--------------------------------------------------
      30. Main slider type-1
  ---------------------------------------------------*/
  .sliderMain.-type-1 {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
  }
  
  .sliderMain.-type-1 .sliderMain__wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .sliderMain.-type-1 .sliderMain__img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .sliderMain.-type-1 .sliderMain__img.-cover-dark::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .sliderMain.-type-1 .sliderMain__content {
    position: relative;
  }
  
  .sliderMain.-type-1 .sliderMain__bgTitle {
    position: absolute;
    font-size: 16vw;
    line-height: 1;
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.03);
    text-align: center;
  }
  
  .sliderMain.-type-1 .sliderMain__title {
    word-wrap: break-word;
    font-size: 7.6vw;
  }
  
  @media (max-width: 1229px) {
    .sliderMain.-type-1 .sliderMain__title {
      font-size: 9.6vw;
    }
  }
  
  @media (max-width: 991px) {
    .sliderMain.-type-1 .sliderMain__title {
      font-size: 6rem;
    }
  }
  
  @media (max-width: 767px) {
    .sliderMain.-type-1 .sliderMain__title {
      font-size: 4.5rem;
    }
  }
  
  @media (max-width: 575px) {
    .sliderMain.-type-1 .sliderMain__title {
      font-size: 3.125rem;
    }
  }
  
  /*--------------------------------------------------
      31. Main slider type-2
  ---------------------------------------------------*/
  .sliderMain.-type-2 {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #010101;
  }
  
  .sliderMain.-type-2 .swiper-wrapper {
    transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  }
  
  .sliderMain.-type-2 .slider__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    width: 100%;
    height: 100%;
    padding-top: 2.125rem;
  }
  
  .sliderMain.-type-2 .slider__subtitle {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 400;
    text-transform: capitalize;
  }
  
  .sliderMain.-type-2 .slider__title {
    display: block;
    line-height: 1.1;
    letter-spacing: -0.02em;
    font-size: 5vw;
  }
  
  @media (max-width: 991px) {
    .sliderMain.-type-2 .slider__title {
      font-size: 12vw;
    }
  }
  
  @media (max-width: 575px) {
    .sliderMain.-type-2 .slider__title {
      font-size: 18vw;
    }
  }
  
  .sliderMain.-type-2 .slider__images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .sliderMain.-type-2 .slider__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .sliderMain.-type-2 .slider__img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .sliderMain.-type-2 .slider__content {
    pointer-events: none;
  }
  
  .sliderMain.-type-2 .slider__title {
    opacity: 0.2;
    transform: scale(0.6);
    transition: opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1) 0s, transform 0.6s cubic-bezier(0.77, 0, 0.175, 1) 0s;
  }
  
  .sliderMain.-type-2 .slider__subtitle {
    transform: translateY(100%);
    transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) 0s, opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }
  
  .sliderMain.-type-2 .slider__img {
    opacity: 0;
    transform: scale(1.3);
    transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1), opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  }
  
  .sliderMain.-type-2 .slider__img.is-active {
    opacity: 1;
    transform: scale(1);
  }
  
  .sliderMain.-type-2 .swiper-slide-duplicate-active .slider__content,
  .sliderMain.-type-2 .swiper-slide-active .slider__content {
    pointer-events: auto;
  }
  
  .sliderMain.-type-2 .swiper-slide-duplicate-active .slider__title,
  .sliderMain.-type-2 .swiper-slide-active .slider__title {
    opacity: 1;
    transform: scale(1.2);
    transform: scale(1.1);
    transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  }
  
  .sliderMain.-type-2 .swiper-slide-duplicate-active .slider__subtitle,
  .sliderMain.-type-2 .swiper-slide-active .slider__subtitle {
    transform: none;
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  }
  
  /*--------------------------------------------------
      32. Main slider type-3
  ---------------------------------------------------*/
  .sliderMain.-type-3 {
    overflow: visible;
    position: relative;
    z-index: 5;
    height: 100vh;
    width: 100%;
  }
  
  @media (max-width: 767px) {
    .sliderMain.-type-3 {
      height: calc(100vh - 3.75rem);
    }
  }
  
  @media (max-width: 767px) {
    .sliderMain.-type-3 .swiper-col {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
  
  .sliderMain.-type-3 .swiper-container {
    width: 100%;
  }
  
  @media (max-width: 767px) {
    .sliderMain.-type-3 .swiper-container {
      height: calc(100vh - 3.75rem);
    }
  }
  
  .sliderMain.-type-3 .slider__img {
    width: 100%;
    height: 100%;
  }
  
  @media (max-width: 767px) {
    .sliderMain.-type-3 .slider__img::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  
  .sliderMain.-type-3 .slider__img__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    transform-origin: left;
  }
  
  .sliderMain.-type-3 .slider__img__bg {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  
  .sliderMain.-type-3 .slider__content__wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .sliderMain.-type-3 .slider__content {
    pointer-events: none;
    position: absolute;
    left: 0;
  }
  
  .sliderMain.-type-3 .slider__content.is-active {
    pointer-events: auto;
  }
  
  @media (max-width: 767px) {
    .sliderMain.-type-3 .slider__content {
      width: 100%;
    }
  }
  
  @media (max-width: 767px) {
    .sliderMain.-type-3 .slider__subtitle {
      color: white;
    }
  }
  
  .sliderMain.-type-3 .slider__title {
    font-size: 4vw;
  }
  
  @media (max-width: 1229px) {
    .sliderMain.-type-3 .slider__title {
      font-size: 4.5vw;
    }
  }
  
  @media (max-width: 991px) {
    .sliderMain.-type-3 .slider__title {
      font-size: 6vw;
    }
  }
  
  @media (max-width: 575px) {
    .sliderMain.-type-3 .slider__title {
      font-size: 9vw;
    }
  }
  
  @media (max-width: 767px) {
    .sliderMain.-type-3 .slider__title {
      color: white;
    }
  }
  
  .sliderMain.-type-3 .slider__title .split__line {
    padding-bottom: 0.25rem;
  }
  
  .sliderMain.-type-3 .slider__title.-line-bg-white {
    background-color: white;
  }
  
  .sliderMain.-type-3 .slider__title.-line-bg-black {
    background-color: black;
  }
  
  @media (max-width: 767px) {
    .sliderMain.-type-3 .slider__button .button {
      color: black;
      background-color: white;
      border: 1px solid white;
    }
  }
  
  .sliderMain.-type-3 .slider__navs {
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 3rem;
    z-index: 10;
    transform: translateY(-50%) translateX(25%);
  }
  
  .sliderMain.-type-3 .slider__navs .navButton {
    display: flex;
    flex-flow: column nowrap;
  }
  
  @media (max-width: 767px) {
    .sliderMain.-type-3 .slider__navs {
      top: unset;
      left: 1.5rem;
      right: 1.5rem;
      bottom: 1.5rem;
      transform: none;
    }
    .sliderMain.-type-3 .slider__navs .navButton {
      flex-flow: row;
      justify-content: space-between;
    }
    .sliderMain.-type-3 .slider__navs .navButton .navButton__item {
      margin: 0 !important;
      background-color: transparent;
      border: 0.0625rem solid #ffffff;
      color: white;
    }
    .sliderMain.-type-3 .slider__navs .navButton .navButton__item:hover {
      background-color: white;
      color: black;
    }
  }
  
  .sliderMain.-type-3 .slider__navs.is-active {
    pointer-events: auto;
  }
  
  /*--------------------------------------------------
      33. Masthead base
  ---------------------------------------------------*/
  .masthead {
    position: relative;
    z-index: 0;
  }
  
  .masthead__bg {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  
  .masthead .masthead-shapes__item {
    position: absolute;
    z-index: 1;
  }
  
  .masthead .masthead-shapes__shape.-fixed {
    position: fixed;
  }
  
  .masthead .masthead-shapes__shape.-white {
    background-color: #FFFFFF;
  }
  
  .masthead .masthead-shapes__shape.-black {
    background-color: #000000;
  }
  
  .masthead .masthead-shapes__shape.-light-1 {
    background-color: #FAFAFA;
  }
  
  .masthead .masthead-shapes__shape.-dark-1 {
    background-color: var(--bg-dark-1);
  }
  
  .masthead .masthead-shapes__shape.-dark-2 {
    background-color: #17171C;
  }
  
  .masthead .masthead-shapes__shape.-shadow-light {
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.08);
  }
  
  .masthead .masthead-shapes__shape.-shadow-dark {
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.35);
  }
  
  .masthead .masthead-shapes.-group-1 .-item-1 {
    top: -23.75vw;
    left: -28.75vw;
  }
  
  .masthead .masthead-shapes.-group-1 .-item-1 .masthead-shapes__shape {
    width: 64vw;
    height: 64vw;
    border-radius: 100%;
  }
  
  .masthead .masthead-shapes.-group-1 .-item-2 {
    bottom: -21.5625vw;
    right: -19.6875vw;
  }
  
  .masthead .masthead-shapes.-group-1 .-item-2 .masthead-shapes__shape {
    width: 68.4vw;
    height: 68.4vw;
    border-radius: 100%;
  }
  
  @media (max-width: 767px) {
    .masthead .masthead-shapes.-group-1 .-item-1 {
      top: 50%;
      left: -75%;
      transform: translateY(-50%);
    }
    .masthead .masthead-shapes.-group-1 .-item-1 .masthead-shapes__shape {
      width: 140vw;
      height: 140vw;
    }
    .masthead .masthead-shapes.-group-1 .-item-2 {
      display: none;
    }
  }
  
  .masthead .masthead-shapes.-group-2 .-item-1 {
    top: 10vw;
    right: 14.3vw;
  }
  
  .masthead .masthead-shapes.-group-2 .-item-1 .masthead-shapes__shape {
    width: 35rem;
    height: 35rem;
    border-radius: 100%;
  }
  
  @media (max-width: 991px) {
    .masthead .masthead-shapes.-group-2 .-item-1 {
      top: 50%;
      right: unset;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
    .masthead .masthead-shapes.-group-2 .-item-1 .masthead-shapes__shape {
      width: 70vw;
      height: 70vw;
    }
  }
  
  @media (max-width: 575px) {
    .masthead .masthead-shapes.-group-2 .-item-1 {
      top: 50%;
      left: -75%;
      transform: translateY(-50%);
    }
    .masthead .masthead-shapes.-group-2 .-item-1 .masthead-shapes__shape {
      width: 140vw;
      height: 140vw;
    }
  }
  
  @media (max-width: 767px) {
    .masthead .masthead-shapes.-group-2 .-item-1 {
      top: 50%;
      left: -75%;
      transform: translateY(-50%);
    }
    .masthead .masthead-shapes.-group-2 .-item-1 .masthead-shapes__shape {
      width: 140vw;
      height: 140vw;
    }
  }
  
  .masthead .masthead-shapes.-group-3 .-item-1 {
    top: -15.0vw;
    left: -30vw;
  }
  
  .masthead .masthead-shapes.-group-3 .-item-1 .masthead-shapes__shape {
    border-radius: 100%;
    width: 78.5vw;
    height: 78.5vw;
  }
  
  .masthead .masthead-shapes.-group-3 .-item-2 {
    top: -24.25vw;
    right: -15.0625vw;
  }
  
  .masthead .masthead-shapes.-group-3 .-item-2 .masthead-shapes__shape {
    border-radius: 100%;
    width: 44vw;
    height: 44vw;
  }
  
  .masthead .masthead-shapes.-group-3 .-item-3 {
    bottom: -30.9375vw;
    right: -20.375vw;
  }
  
  .masthead .masthead-shapes.-group-3 .-item-3 .masthead-shapes__shape {
    border-radius: 100%;
    width: 46vw;
    height: 46vw;
  }
  
  @media (max-width: 767px) {
    .masthead .masthead-shapes.-group-3 .-item-1 {
      top: 50%;
      left: -75%;
      transform: translateY(-50%);
    }
    .masthead .masthead-shapes.-group-3 .-item-1 .masthead-shapes__shape {
      width: 140vw;
      height: 140vw;
    }
    .masthead .masthead-shapes.-group-3 .-item-2 {
      display: none;
    }
    .masthead .masthead-shapes.-group-3 .-item-3 {
      display: none;
    }
  }
  
  .masthead .masthead-shapes.-group-4 .-item-1 {
    top: -35rem;
    left: -31.25rem;
  }
  
  .masthead .masthead-shapes.-group-4 .-item-1 .masthead-shapes__shape {
    border-radius: 100%;
    width: 71.875rem;
    height: 71.875rem;
  }
  
  .masthead .masthead-shapes.-group-4 .-item-2 {
    top: -18.75rem;
    right: -18.75rem;
  }
  
  .masthead .masthead-shapes.-group-4 .-item-2 .masthead-shapes__shape {
    border-radius: 100%;
    width: 50rem;
    height: 50rem;
  }
  
  @media (max-width: 767px) {
    .masthead .masthead-shapes.-group-4 .-item-1 {
      top: 50%;
      left: -75%;
      transform: translateY(-50%);
    }
    .masthead .masthead-shapes.-group-4 .-item-1 .masthead-shapes__shape {
      width: 140vw;
      height: 140vw;
    }
    .masthead .masthead-shapes.-group-4 .-item-2 {
      display: none;
    }
  }
  
  .masthead .masthead-shapes.-group-5 .-item-1 {
    top: -32.5rem;
    left: -30.375rem;
  }
  
  .masthead .masthead-shapes.-group-5 .-item-1 .masthead-shapes__shape {
    border-radius: 100%;
    width: 76.875rem;
    height: 76.875rem;
  }
  
  .masthead .masthead-shapes.-group-5 .-item-2 {
    top: -13.25rem;
    right: -18.75rem;
  }
  
  .masthead .masthead-shapes.-group-5 .-item-2 .masthead-shapes__shape {
    border-radius: 100%;
    width: 49.375rem;
    height: 49.375rem;
  }
  
  @media (max-width: 767px) {
    .masthead .masthead-shapes.-group-5 .-item-1 {
      top: 50%;
      left: -75%;
      transform: translateY(-50%);
    }
    .masthead .masthead-shapes.-group-5 .-item-1 .masthead-shapes__shape {
      width: 140vw;
      height: 140vw;
    }
    .masthead .masthead-shapes.-group-5 .-item-2 {
      display: none;
    }
  }
  
  .masthead .masthead-shapes.-group-6 .-item-1 {
    top: 50%;
    left: 50%;
    transform: translateY(-36%) translateX(-52%);
  }
  
  .masthead .masthead-shapes.-group-6 .-item-1 .masthead-shapes__shape {
    border-radius: 100%;
    width: 560px;
    height: 560px;
  }
  
  @media (max-width: 767px) {
    .masthead .masthead-shapes.-group-6 .-item-1 {
      top: 50%;
      left: -75%;
      transform: translateY(-50%);
    }
    .masthead .masthead-shapes.-group-6 .-item-1 .masthead-shapes__shape {
      width: 140vw;
      height: 140vw;
    }
  }
  
  /*--------------------------------------------------
      34. Masthead single portfolio project
  ---------------------------------------------------*/
  .masthead.-type-work-1.-full-screen .masthead__content {
    padding-top: 0;
    height: 100vh;
    display: flex;
    align-items: flex-end;
  }
  
  .masthead.-type-work-1 .masthead__content {
    padding-top: 16.25rem;
    padding-bottom: 5rem;
  }
  
  @media (max-width: 991px) {
    .masthead.-type-work-1 .masthead__content {
      padding-top: 11.25rem;
    }
  }
  
  @media (max-width: 767px) {
    .masthead.-type-work-1 .masthead__content {
      padding-top: 8.75rem;
      padding-bottom: 3.75rem;
    }
  }
  
  @media (max-width: 575px) {
    .masthead.-type-work-1 .masthead__content {
      padding-top: 6.25rem;
      padding-bottom: 2.5rem;
    }
  }
  
  .masthead.-type-work-1 .masthead__title {
    line-height: 1.05;
    font-size: 6.25rem;
    letter-spacing: -0.01em;
  }
  
  @media (max-width: 991px) {
    .masthead.-type-work-1 .masthead__title {
      font-size: 5.625rem;
    }
  }
  
  @media (max-width: 767px) {
    .masthead.-type-work-1 .masthead__title {
      font-size: 4.375rem;
    }
  }
  
  @media (max-width: 575px) {
    .masthead.-type-work-1 .masthead__title {
      font-size: 3.5rem;
    }
  }
  
  .masthead.-type-work-1 .masthead__title .split__line {
    padding-bottom: 0.25rem;
  }
  
  .masthead.-type-work-1 .masthead__info__wrap {
    margin-top: 13.75rem;
  }
  
  @media (max-width: 991px) {
    .masthead.-type-work-1 .masthead__info__wrap {
      margin-top: 8.75rem;
    }
  }
  
  @media (max-width: 575px) {
    .masthead.-type-work-1 .masthead__info__wrap {
      margin-top: 4.5rem;
    }
  }
  
  /*--------------------------------------------------
      35. Masthead type-1
  ---------------------------------------------------*/
  .masthead.-type-1 {
    overflow: visible;
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100%;
  }
  
  .masthead.-type-1 .masthead__content {
    position: relative;
  }
  
  .masthead.-type-1 .masthead__title {
    line-height: 1.2;
    font-size: 6vw;
  }
  
  @media (max-width: 1229px) {
    .masthead.-type-1 .masthead__title {
      font-size: 7.2vw;
    }
  }
  
  @media (max-width: 991px) {
    .masthead.-type-1 .masthead__title {
      font-size: 4rem;
    }
  }
  
  @media (max-width: 767px) {
    .masthead.-type-1 .masthead__title {
      font-size: 3.5rem;
    }
  }
  
  @media (max-width: 575px) {
    .masthead.-type-1 .masthead__title {
      font-size: 10.5vw;
    }
  }
  
  @media (max-width: 1229px) {
    .masthead.-type-1 .masthead__title br {
      display: none;
    }
  }
  
  .masthead.-type-1 .masthead__text {
    max-width: 18.75rem;
  }
  
  /*--------------------------------------------------
      36. Masthead type-2
  ---------------------------------------------------*/
  .masthead.-type-2 {
    overflow: visible;
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100%;
  }
  
  .masthead.-type-2 .masthead__content {
    position: relative;
    padding-top: 4.8vw;
  }
  
  .masthead.-type-2 .masthead__title {
    line-height: 1.05;
    font-size: 7.5vw;
  }
  
  @media (max-width: 1229px) {
    .masthead.-type-2 .masthead__title {
      font-size: 8.4vw;
    }
  }
  
  @media (max-width: 991px) {
    .masthead.-type-2 .masthead__title {
      font-size: 8vw;
    }
  }
  
  @media (max-width: 767px) {
    .masthead.-type-2 .masthead__title {
      font-size: 10vw;
    }
  }
  
  @media (max-width: 575px) {
    .masthead.-type-2 .masthead__title {
      font-size: 14vw;
    }
  }
  
  .masthead.-type-2 .masthead__title .overflow-hidden {
    padding-bottom: 0.9%;
  }
  
  /*--------------------------------------------------
      37. Masthead type-3
  ---------------------------------------------------*/
  .masthead.-type-3 {
    overflow: visible;
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100%;
  }
  
  .masthead.-type-3 .masthead__content {
    position: relative;
  }
  
  .masthead.-type-3 .masthead__content.-left-padding .masthead__subtitle {
    margin-left: 4.9375rem;
  }
  
  .masthead.-type-3 .masthead__content.-left-padding .masthead__text {
    margin-left: 4.8125rem;
  }
  
  .masthead.-type-3 .masthead__content.-left-padding .masthead__button {
    margin-left: 4.6875rem;
  }
  
  @media (max-width: 575px) {
    .masthead.-type-3 .masthead__content.-left-padding .masthead__subtitle {
      margin-left: 0;
    }
    .masthead.-type-3 .masthead__content.-left-padding .masthead__text {
      margin-left: 0;
    }
    .masthead.-type-3 .masthead__content.-left-padding .masthead__button {
      margin-left: 0;
    }
  }
  
  .masthead.-type-3 .masthead__title {
    line-height: 1.1;
    font-size: 6rem;
  }
  
  @media (max-width: 991px) {
    .masthead.-type-3 .masthead__title {
      font-size: 5.375rem;
    }
  }
  
  @media (max-width: 767px) {
    .masthead.-type-3 .masthead__title {
      font-size: 4.375rem;
    }
  }
  
  @media (max-width: 575px) {
    .masthead.-type-3 .masthead__title {
      font-size: 2.625rem;
    }
  }
  
  .masthead.-type-3 .masthead__title .overflow-hidden {
    padding-bottom: 0.8%;
  }
  
  /*--------------------------------------------------
      38. Masthead type-4
  ---------------------------------------------------*/
  .masthead.-type-4 {
    position: relative;
    z-index: 5;
    overflow: visible;
    width: 100%;
    height: 100vh;
  }
  
  @media (max-width: 991px) {
    .masthead.-type-4 {
      height: auto !important;
    }
  }
  
  @media (max-width: 991px) {
    .masthead.-type-4 .masthead__img {
      height: 80vh;
    }
  }
  
  @media (max-width: 767px) {
    .masthead.-type-4 .masthead__img {
      height: 60vh;
    }
  }
  
  .masthead.-type-4 .masthead__img__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bg-dark-1);
    transform-origin: right;
  }
  
  .masthead.-type-4 .masthead__content {
    padding-top: 2.5rem;
  }
  
  @media (max-width: 991px) {
    .masthead.-type-4 .masthead__content {
      padding-top: 8.75rem;
      padding-bottom: 6.25rem;
    }
  }
  
  @media (max-width: 767px) {
    .masthead.-type-4 .masthead__content {
      padding-top: 7.5rem;
      padding-bottom: 5rem;
    }
  }
  
  .masthead.-type-4 .masthead__title {
    line-height: 1.1;
    font-size: 4vw;
  }
  
  @media (max-width: 991px) {
    .masthead.-type-4 .masthead__title {
      font-size: 5.6vw;
    }
  }
  
  @media (max-width: 767px) {
    .masthead.-type-4 .masthead__title {
      font-size: 2.25rem;
    }
  }
  
  /*--------------------------------------------------
      39. Card
  ---------------------------------------------------*/
  .serviceCard {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .serviceCard__img {
    overflow: hidden;
  }
  
  .serviceCard__content {
    flex: 1 1 auto;
  }
  
  .serviceCard__navButton {
    margin-left: -0.0625rem;
  }
  
  /*--------------------------------------------------
      40. Price card
  ---------------------------------------------------*/
  .priceCard__price {
    letter-spacing: -0.05em;
  }
  
  .priceCard__badge {
    display: inline-block;
    padding: 0.5rem 0.625rem;
    line-height: 1;
    border-radius: 0.375rem;
    background-color: #F0F0F0;
  }
  
  .priceCard__badge.-light {
    background-color: rgba(255, 255, 255, 0.08);
  }
  
  .priceCard__list li {
    margin-bottom: 0.75rem;
  }
  
  .priceCard__list li:last-child {
    margin-bottom: 0;
  }
  
  .priceCard__list li p {
    line-height: 1.3;
  }
  
  /*--------------------------------------------------
      41. Award
  ---------------------------------------------------*/
  /*--------------------------------------------------
      42. Charts
  ---------------------------------------------------*/
  .bar-stroke-hidden {
    stroke-width: 0px !important;
  }
  
  .pieChart-chart {
    transform: rotate(-90deg);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pieChart circle {
    stroke-dashoffset: 0;
    fill: transparent;
    stroke-width: 0.125rem;
  }
  
  .pieChart circle.pieChart-bar {
    stroke: var(--accent-color);
    stroke-width: 0.375rem;
  }
  
  .pieChart-percent {
    transform: rotate(90deg);
    position: absolute;
    font-family: var(--font-secondary);
    font-size: 1.75rem;
    font-weight: 600;
  }
  
  .pieChart-title {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
  
  .pieChart {
    transition: background-color 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .pieChart .pieChart-title,
  .pieChart .pieChart-text,
  .pieChart .pieChart-percent {
    transition: color 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .pieChart-light circle {
    stroke: rgba(255, 255, 255, 0.2);
  }
  
  .pieChart-light:hover {
    background-color: white;
  }
  
  .pieChart-light:hover .pieChart-percent,
  .pieChart-light:hover .pieChart-title {
    color: black;
  }
  
  .pieChart-light:hover .pieChart-text {
    color: var(--font-dark);
  }
  
  .pieChart-dark circle {
    stroke: rgba(0, 0, 0, 0.1);
  }
  
  .pieChart-dark:hover {
    background-color: black;
  }
  
  .pieChart-dark:hover .pieChart-percent,
  .pieChart-dark:hover .pieChart-title {
    color: white;
  }
  
  .pieChart-dark:hover .pieChart-text {
    color: var(--font-light);
  }
  
  .lineChart__item {
    margin-top: 2rem;
    margin-top: 2rem;
  }
  
  .lineChart__item:first-child {
    margin-top: 0;
  }
  
  .lineChart__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .lineChart__content p {
    font-size: inherit;
    color: inherit;
    line-height: inherit;
  }
  
  .lineChart-bar {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 0.1875rem;
  }
  
  .lineChart-bar__item {
    width: 100%;
    height: 100%;
    transform-origin: 0% 50%;
    transform: scaleX(0);
  }
  
  .lineChart-bar__bg {
    position: absolute;
    width: 100%;
    height: 0.1875rem;
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .lineChart-bar__bg.-light {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  /*--------------------------------------------------
      43. Clients
  ---------------------------------------------------*/
  .clientsItem {
    position: relative;
  }
  
  .clientsItem__img {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .clientsItem__content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--bg-dark-1);
  }
  
  .clientsItem__title {
    font-weight: 600;
  }
  
  .clientsItem.-hover .clientsItem__img {
    transition: transform 480ms cubic-bezier(0.77, 0, 0.175, 1), opacity 480ms cubic-bezier(0.77, 0, 0.175, 1);
  }
  
  .clientsItem.-hover .clientsItem__content {
    transition: transform 480ms cubic-bezier(0.77, 0, 0.175, 1), opacity 480ms cubic-bezier(0.77, 0, 0.175, 1);
    transform-origin: bottom;
    transform: scaleY(0);
  }
  
  .clientsItem.-hover .clientsItem__title {
    transition: transform 350ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    transform: translateY(16px);
  }
  
  .clientsItem.-hover:hover .clientsItem__img {
    opacity: 1;
    transform: translateY(-24px);
  }
  
  .clientsItem.-hover:hover .clientsItem__content {
    opacity: 1;
    transform: none;
  }
  
  .clientsItem.-hover:hover .clientsItem__title {
    transition: transform 480ms cubic-bezier(0.165, 0.84, 0.44, 1) 350ms, opacity 480ms cubic-bezier(0.165, 0.84, 0.44, 1) 350ms;
    opacity: 1;
    transform: none;
  }
  
  .clientsItem.-hover.-light .clientsItem__content {
    background-color: var(--bg-light-1);
  }
  
  /*--------------------------------------------------
      44. Blog card
  ---------------------------------------------------*/
  .blogCard {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .blogCard__content-no-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .blogCard__content {
    flex: 1 1 auto;
  }
  
  .blogCard.-type-1.-hover .blogCard__img {
    overflow: hidden;
  }
  
  .blogCard.-type-1.-hover .blogCard__img div {
    transition: transform 0.46s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: scale(1.01);
  }
  
  .blogCard.-type-1.-hover:hover .blogCard__img div {
    transform: scale(1.1);
  }
  
  .blogCard.-type-2 {
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
  
  .blogCard.-type-2 .blogCard__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  
  .blogCard.-type-2 .blogCard__img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .blogCard.-type-2.-hover .blogCard__img {
    transform: scale(1.1);
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .blogCard.-type-2.-hover .blogCard__info,
  .blogCard.-type-2.-hover .blogCard__info a,
  .blogCard.-type-2.-hover .blogCard__title a,
  .blogCard.-type-2.-hover .blogCard__text,
  .blogCard.-type-2.-hover .blogCard__date > * {
    transition: color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0s !important;
  }
  
  .blogCard.-type-2.-hover:hover .blogCard__img {
    opacity: 1;
    transform: scale(1.3);
  }
  
  .blogCard.-type-2.-hover:hover .blogCard__info,
  .blogCard.-type-2.-hover:hover .blogCard__info a,
  .blogCard.-type-2.-hover:hover .blogCard__title a,
  .blogCard.-type-2.-hover:hover .blogCard__text,
  .blogCard.-type-2.-hover:hover .blogCard__date > * {
    color: white;
  }
  
  /*--------------------------------------------------
      45. Team
  ---------------------------------------------------*/
  .teamCard {
    position: relative;
  }
  
  .teamCard__social {
    display: flex;
  }
  
  .teamCard__social__item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9375rem;
    margin-right: 1.75rem;
  }
  
  .teamCard__social__item:last-child {
    margin-right: 0;
  }
  
  .teamCard.-type-1 .teamCard__content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .teamCard.-type-1.-hover .teamCard__content {
    transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
  }
  
  .teamCard.-type-1.-hover h4,
  .teamCard.-type-1.-hover p,
  .teamCard.-type-1.-hover .teamCard__social {
    transition: transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .teamCard.-type-1.-hover .teamCard__title {
    transform: translateY(1.25rem);
  }
  
  .teamCard.-type-1.-hover .teamCard__text {
    transform: translateY(1.5rem);
  }
  
  .teamCard.-type-1.-hover .teamCard__social {
    transform: translateY(2rem);
  }
  
  .teamCard.-type-1.-hover:hover .teamCard__content {
    opacity: 1;
  }
  
  .teamCard.-type-1.-hover:hover .teamCard__title,
  .teamCard.-type-1.-hover:hover .teamCard__text,
  .teamCard.-type-1.-hover:hover .teamCard__social {
    transform: none;
  }
  
  /*--------------------------------------------------
      46. Counter
  ---------------------------------------------------*/
  /*--------------------------------------------------
      47. Portfolio
  ---------------------------------------------------*/
  .portfolioCard {
    display: block;
  }
  
  .portfolioCard__img {
    overflow: hidden;
  }
  
  .portfolioCard.-type-1.-hover .portfolioCard__img {
    transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .portfolioCard.-type-1.-hover .portfolioCard__img__inner {
    transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .portfolioCard.-type-1.-hover .portfolioCard__category,
  .portfolioCard.-type-1.-hover .portfolioCard__title {
    transition: color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .portfolioCard.-type-1.-hover:hover .portfolioCard__img {
    transform: scale(0.95);
  }
  
  .portfolioCard.-type-1.-hover:hover .portfolioCard__img__inner {
    transform: scale(1.1);
  }
  
  .portfolioCard.-type-1.-hover:hover .portfolioCard__category {
    transform: translateX(2rem);
  }
  
  .portfolioCard.-type-1.-hover:hover .portfolioCard__title {
    transform: translateX(2rem);
    color: var(--accent-color);
  }
  
  .portfolioCard.-type-2 {
    position: relative;
  }
  
  .portfolioCard.-type-2 .portfolioCard__content {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .portfolioCard.-type-2 .portfolioCard__content a {
    position: relative;
    z-index: 4;
  }
  
  .portfolioCard.-type-2 .portfolioCard__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  
  .portfolioCard.-type-2.-hover .portfolioCard__img {
    overflow: hidden;
  }
  
  .portfolioCard.-type-2.-hover .portfolioCard__img__inner {
    transform: scale(1.01);
    transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .portfolioCard.-type-2.-hover .portfolioCard__content {
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .portfolioCard.-type-2.-hover .portfolioCard__content p,
  .portfolioCard.-type-2.-hover .portfolioCard__content a {
    opacity: 0;
    transform: translateY(2rem);
    transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .portfolioCard.-type-2.-hover .portfolioCard__content h3 {
    opacity: 0;
    transform: translateY(2rem);
    transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.06s, transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.06s;
  }
  
  .portfolioCard.-type-2.-hover:hover .portfolioCard__img__inner {
    transform: scale(1.08);
  }
  
  .portfolioCard.-type-2.-hover:hover .portfolioCard__content {
    opacity: 1;
  }
  
  .portfolioCard.-type-2.-hover:hover .portfolioCard__content a,
  .portfolioCard.-type-2.-hover:hover .portfolioCard__content p,
  .portfolioCard.-type-2.-hover:hover .portfolioCard__content h3 {
    opacity: 1;
    transform: none;
  }
  
  /*--------------------------------------------------
      48. Masonry
  ---------------------------------------------------*/
  .filter-button-group {
    display: flex;
    flex-wrap: wrap;
  }
  
  .filter-button-group button {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
  }
  
  .filter-button-group button:hover {
    color: var(--accent-color) !important;
  }
  
  .filter-button-group button.btn-active {
    color: black;
    text-decoration: underline;
  }
  
  .masonry__item .portfolioCard__img,
  .masonry__item .portfolioCard__img__inner {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .masonry .masonry__item .ratio {
    padding-bottom: 100%;
  }
  
  .masonry .masonry__item.-long .ratio {
    padding-bottom: 200%;
  }
  
  .masonry .masonry__item.-big .ratio {
    padding-bottom: 100%;
  }
  
  .masonry .masonry__item.-wide .ratio {
    padding-bottom: 50%;
  }
  
  .masonry.-col-4 .masonry__sizer {
    width: 25%;
  }
  
  .masonry.-col-4 .masonry__item {
    width: 25%;
  }
  
  .masonry.-col-4 .masonry__item.-long {
    width: 25%;
  }
  
  .masonry.-col-4 .masonry__item.-big {
    width: 50%;
  }
  
  .masonry.-col-4 .masonry__item.-wide {
    width: 50%;
  }
  
  @media (max-width: 991px) {
    .masonry.-col-4 .masonry__sizer {
      width: 33.33333%;
    }
    .masonry.-col-4 .masonry__item {
      width: 33.33333%;
    }
    .masonry.-col-4 .masonry__item.-long {
      width: 33.33333%;
    }
    .masonry.-col-4 .masonry__item.-big {
      width: 66.66666%;
    }
    .masonry.-col-4 .masonry__item.-wide {
      width: 66.66666%;
    }
  }
  
  @media (max-width: 767px) {
    .masonry.-col-4 .masonry__sizer {
      width: 50%;
    }
    .masonry.-col-4 .masonry__item {
      width: 50%;
    }
    .masonry.-col-4 .masonry__item.-long {
      width: 50%;
    }
    .masonry.-col-4 .masonry__item.-big {
      width: 100%;
    }
    .masonry.-col-4 .masonry__item.-wide {
      width: 100%;
    }
  }
  
  @media (max-width: 575px) {
    .masonry.-col-4 .masonry__sizer {
      width: 100%;
    }
    .masonry.-col-4 .masonry__item {
      width: 100%;
    }
    .masonry.-col-4 .masonry__item.-long {
      width: 100%;
    }
    .masonry.-col-4 .masonry__item.-big {
      width: 100%;
    }
    .masonry.-col-4 .masonry__item.-wide {
      width: 100%;
    }
  }
  
  .masonry.-col-3 .masonry__sizer {
    width: 33.33333%;
  }
  
  .masonry.-col-3 .masonry__item {
    width: 33.33333%;
  }
  
  .masonry.-col-3 .masonry__item.-long {
    width: 33.33333%;
  }
  
  .masonry.-col-3 .masonry__item.-big {
    width: 66.66666%;
  }
  
  .masonry.-col-3 .masonry__item.-wide {
    width: 66.66666%;
  }
  
  @media (max-width: 767px) {
    .masonry.-col-3 .masonry__sizer {
      width: 50%;
    }
    .masonry.-col-3 .masonry__item {
      width: 50%;
    }
    .masonry.-col-3 .masonry__item.-long {
      width: 50%;
    }
    .masonry.-col-3 .masonry__item.-big {
      width: 100%;
    }
    .masonry.-col-3 .masonry__item.-wide {
      width: 100%;
    }
  }
  
  @media (max-width: 575px) {
    .masonry.-col-3 .masonry__sizer {
      width: 100%;
    }
    .masonry.-col-3 .masonry__item {
      width: 100%;
    }
    .masonry.-col-3 .masonry__item.-long {
      width: 100%;
    }
    .masonry.-col-3 .masonry__item.-big {
      width: 100%;
    }
    .masonry.-col-3 .masonry__item.-wide {
      width: 100%;
    }
  }
  
  .masonry.-col-2 .masonry__sizer {
    width: 50%;
  }
  
  .masonry.-col-2 .masonry__item {
    width: 50%;
  }
  
  .masonry.-col-2 .masonry__item.-long {
    width: 50%;
  }
  
  .masonry.-col-2 .masonry__item.-big {
    width: 100%;
  }
  
  .masonry.-col-2 .masonry__item.-wide {
    width: 100%;
  }
  
  @media (max-width: 575px) {
    .masonry.-col-2 .masonry__sizer {
      width: 100%;
    }
    .masonry.-col-2 .masonry__item {
      width: 100%;
    }
    .masonry.-col-2 .masonry__item.-long {
      width: 100%;
    }
    .masonry.-col-2 .masonry__item.-big {
      width: 100%;
    }
    .masonry.-col-2 .masonry__item.-wide {
      width: 100%;
    }
  }
  
  .masonry.-gap-32 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  
  .masonry.-gap-32 .masonry__item {
    padding: 0 1rem;
    margin-bottom: 2rem;
  }
  
  .masonry.-gap-32 .masonry__item .ratio {
    padding-bottom: 100%;
  }
  
  .masonry.-gap-32 .masonry__item.-long .ratio {
    padding-bottom: calc(200% + 2rem);
  }
  
  .masonry.-gap-32 .masonry__item.-big .ratio {
    padding-bottom: 100%;
  }
  
  .masonry.-gap-32 .masonry__item.-wide .ratio {
    padding-bottom: calc(50% - 1rem);
  }
  
  .masonry.-gap-40 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  
  .masonry.-gap-40 .masonry__item {
    padding: 0 1.25rem;
    margin-bottom: 2.5rem;
  }
  
  .masonry.-gap-40 .masonry__item .ratio {
    padding-bottom: 100%;
  }
  
  .masonry.-gap-40 .masonry__item.-long .ratio {
    padding-bottom: calc(200% + 2.5rem);
  }
  
  .masonry.-gap-40 .masonry__item.-big .ratio {
    padding-bottom: 100%;
  }
  
  .masonry.-gap-40 .masonry__item.-wide .ratio {
    padding-bottom: calc(50% - 1.25rem);
  }
  
  .masonry.-gap-48 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  
  .masonry.-gap-48 .masonry__item {
    padding: 0 1.5rem;
    margin-bottom: 3rem;
  }
  
  .masonry.-gap-48 .masonry__item .ratio {
    padding-bottom: 100%;
  }
  
  .masonry.-gap-48 .masonry__item.-long .ratio {
    padding-bottom: calc(200% + 3rem);
  }
  
  .masonry.-gap-48 .masonry__item.-big .ratio {
    padding-bottom: 100%;
  }
  
  .masonry.-gap-48 .masonry__item.-wide .ratio {
    padding-bottom: calc(50% - 1.5rem);
  }
  
  /*--------------------------------------------------
      49. Portfolio projects navigation
  ---------------------------------------------------*/
  .projects-nav__item {
    overflow: hidden;
    position: relative;
    z-index: 2;
    display: block;
  }
  
  .projects-nav__item.-prev .projects-nav__content {
    padding-left: 7.5rem;
    padding-right: 5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  @media (max-width: 767px) {
    .projects-nav__item.-prev .projects-nav__content {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      align-items: flex-end;
    }
    .projects-nav__item.-prev .projects-nav__content .projects-nav__icon {
      position: absolute;
      bottom: 2.5rem;
      right: 2.25rem;
    }
  }
  
  .projects-nav__item.-next .projects-nav__content {
    padding-left: 5rem;
    padding-right: 7.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  @media (max-width: 767px) {
    .projects-nav__item.-next .projects-nav__content {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      align-items: flex-start;
    }
    .projects-nav__item.-next .projects-nav__content .projects-nav__icon {
      position: absolute;
      bottom: 2.5rem;
      left: 2.25rem;
    }
  }
  
  .projects-nav__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), transform 2.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    transform: scale(1.2);
  }
  
  .projects-nav__img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(4, 7, 15, 0.7);
  }
  
  .projects-nav__content {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 13.75rem;
    padding-bottom: 13.75rem;
  }
  
  @media (max-width: 991px) {
    .projects-nav__content {
      padding-top: 10rem;
      padding-bottom: 10rem;
    }
  }
  
  @media (max-width: 767px) {
    .projects-nav__content {
      justify-content: flex-start;
      flex-flow: column nowrap;
    }
  }
  
  .projects-nav__text {
    position: absolute;
    top: 3.75rem;
  }
  
  .projects-nav__text.-prev {
    right: 5rem;
  }
  
  .projects-nav__text.-next {
    left: 5rem;
  }
  
  @media (max-width: 767px) {
    .projects-nav__text {
      top: 2.5rem;
    }
    .projects-nav__text.-prev {
      right: 2.5rem;
    }
    .projects-nav__text.-next {
      left: 2.5rem;
    }
  }
  
  .projects-nav__item:hover .projects-nav__img {
    opacity: 1;
    transform: none;
  }
  
  /*--------------------------------------------------
      50. Portfolio single project
  ---------------------------------------------------*/
  .work-grid.-col-2 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -3.125rem;
    margin-right: -3.125rem;
  }
  
  .work-grid.-col-2 .grid__item {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
  
  .work-grid.-col-2 .grid__item:nth-child(2n + 2) {
    margin-top: 5rem;
  }
  
  .work-grid.-col-2 .grid__item:nth-child(1n + 3) {
    padding-top: 4.5%;
  }
  
  /*--------------------------------------------------
      51. Utilities
  ---------------------------------------------------*/
  .relative {
    position: relative;
  }
  
  .bg-fill-image {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1 !important;
  }
  
  @media (max-width: 991px) {
    .md\:container {
      max-width: 45rem;
      padding-top: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      margin: 0 auto;
    }
  }
  
  @media (max-width: 767px) {
    .md\:container {
      max-width: 33.75rem;
    }
  }
  
  @media (max-width: 575px) {
    .md\:container {
      max-width: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  
  @media (max-width: 767px) {
    .sm\:container {
      max-width: 33.75rem;
    }
  }
  
  @media (max-width: 575px) {
    .sm\:container {
      max-width: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  
  @media (max-width: 575px) {
    .xs\:container {
      max-width: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  
  .section-border-bottom-dark > * {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  
  .section-border-bottom-dark > *:last-child {
    border-bottom: unset;
  }
  
  .section-border-bottom-light > * {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .section-border-bottom-light > *:last-child {
    border-bottom: unset;
  }
  
  .html-overflow-hidden {
    overflow: hidden !important;
  }
  
  .html-overflow-hidden body {
    overflow-y: scroll;
  }
  
  html.full-page-slider {
    overflow: hidden !important;
  }
  
  html.full-page-slider body {
    overflow: hidden !important;
  }
  
  .smooth-scroll {
    scroll-behavior: smooth;
  }
  
  .origin-top {
    transform-origin: top !important;
  }
  
  .origin-left {
    transform-origin: left !important;
  }
  
  .overflow-hidden {
    overflow: hidden !important;
  }
  
  .overflow-normal {
    overflow: initial !important;
  }
  
  .overflow-visible {
    overflow: visible !important;
  }
  
  .pointer-events-auto {
    pointer-events: auto !important;
  }
  
  .pointer-events-none {
    pointer-events: none !important;
  }
  
  .bg-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
  }
  
  .bg-video {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .overlay-black-sm::after, .overlay-black-md::after, .overlay-black-lg::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
  
  .overlay-black-sm::after {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .overlay-black-md::after {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .overlay-black-lg::after {
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .container-wide {
    padding-left: 5%;
    padding-right: 5%;
  }
  
  @media (max-width: 767px) {
    .container-wide {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  
  .rounded-4 {
    border-radius: 0.25rem;
  }
  
  .rounded-base {
    border-radius: 0.375rem;
  }
  
  .rounded-full {
    border-radius: 100%;
  }
  
  .z-0 {
    z-index: 0;
  }
  
  .z-1 {
    z-index: 1;
  }
  
  .z-2 {
    z-index: 2;
  }
  
  .z-3 {
    z-index: 3;
  }
  
  .z-4 {
    z-index: 4;
  }
  
  .z-5 {
    z-index: 5;
  }
  
  .size-3xs {
    width: 1rem !important;
    height: 1rem !important;
  }
  
  .size-2xs {
    width: 1.25rem !important;
    height: 1.25rem !important;
  }
  
  .size-xs {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
  
  .size-sm {
    width: 2rem !important;
    height: 2rem !important;
  }
  
  .size-md {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
  
  .size-lg {
    width: 3rem !important;
    height: 3rem !important;
  }
  
  .size-xl {
    width: 4rem !important;
    height: 4rem !important;
  }
  
  .size-2xl {
    width: 5rem !important;
    height: 5rem !important;
  }
  
  .size-3xl {
    width: 6rem !important;
    height: 6rem !important;
  }
  
  .str-width-xs {
    stroke-width: 0.5 !important;
  }
  
  .str-width-sm {
    stroke-width: 0.75 !important;
  }
  
  .str-width-md {
    stroke-width: 1 !important;
  }
  
  .str-width-lg {
    stroke-width: 1.25 !important;
  }
  
  .str-width-xl {
    stroke-width: 1.5 !important;
  }
  
  .str-width-2xl {
    stroke-width: 1.75 !important;
  }
  
  .str-width-3xl {
    stroke-width: 2 !important;
  }
  
  .ml-minus-4 {
    margin-left: -0.25rem;
  }
  
  .bg-accent {
    background-color: var(--accent-color);
  }
  
  .bg-black {
    background-color: #000000;
  }
  
  .bg-white {
    background-color: #FFFFFF;
  }
  
  .bg-light-1 {
    background-color: var(--bg-light-1);
  }
  
  .bg-light-2 {
    background-color: var(--bg-light-2);
  }
  
  .bg-dark-1 {
    background-color: var(--bg-dark-1);
  }
  
  .bg-dark-2 {
    background-color: var(--bg-dark-2);
  }
  
  .bg-dark-3 {
    background-color: var(--bg-dark-3);
  }
  
  .bg-dark-4 {
    background-color: var(--bg-dark-4);
  }
  
  .shadow-light {
    box-shadow: 0 6px 24px -2px rgba(119, 139, 165, 0.2);
  }
  
  .shadow-dark {
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.6);
  }
  
  .border-none {
    border: none;
  }
  
  .border-light {
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .border-dark {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .border-top-light {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .border-top-dark {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .italic {
    font-style: italic;
  }
  
  .uppercase {
    text-transform: uppercase;
  }
  
  .capitalize {
    text-transform: capitalize;
  }
  
  .normal {
    text-transform: none;
  }
  
  .line-through {
    text-decoration: line-through;
  }
  
  .underline {
    text-decoration: underline;
  }
  
  .fw-300 {
    font-weight: 300 !important;
  }
  
  .fw-400 {
    font-weight: 400 !important;
  }
  
  .fw-500 {
    font-weight: 500 !important;
  }
  
  .fw-600 {
    font-weight: 600 !important;
  }
  
  .fw-700 {
    font-weight: 700 !important;
  }
  
  .fw-800 {
    font-weight: 800 !important;
  }
  
  .fw-900 {
    font-weight: 900 !important;
  }
  
  .text-accent {
    color: var(--accent-color);
  }
  
  .text-light {
    color: var(--font-light);
  }
  
  .text-dark {
    color: var(--font-dark);
  }
  
  .text-black {
    color: #000000;
  }
  
  .text-white {
    color: #FFFFFF;
  }
  
  .py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  
  .px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  
  .pt-0 {
    padding-top: 0rem !important;
  }
  
  .pb-0 {
    padding-bottom: 0rem !important;
  }
  
  .pl-0 {
    padding-left: 0rem !important;
  }
  
  .pr-0 {
    padding-right: 0rem !important;
  }
  
  .mt-0 {
    margin-top: 0rem !important;
  }
  
  .mb-0 {
    margin-bottom: 0rem !important;
  }
  
  .ml-0 {
    margin-left: 0rem !important;
  }
  
  .mr-0 {
    margin-right: 0rem !important;
  }
  
  .py-4 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  
  .px-4 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  
  .pt-4 {
    padding-top: 0.25rem !important;
  }
  
  .pb-4 {
    padding-bottom: 0.25rem !important;
  }
  
  .pl-4 {
    padding-left: 0.25rem !important;
  }
  
  .pr-4 {
    padding-right: 0.25rem !important;
  }
  
  .mt-4 {
    margin-top: 0.25rem !important;
  }
  
  .mb-4 {
    margin-bottom: 0.25rem !important;
  }
  
  .ml-4 {
    margin-left: 0.25rem !important;
  }
  
  .mr-4 {
    margin-right: 0.25rem !important;
  }
  
  .py-8 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  
  .px-8 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  
  .pt-8 {
    padding-top: 0.5rem !important;
  }
  
  .pb-8 {
    padding-bottom: 0.5rem !important;
  }
  
  .pl-8 {
    padding-left: 0.5rem !important;
  }
  
  .pr-8 {
    padding-right: 0.5rem !important;
  }
  
  .mt-8 {
    margin-top: 0.5rem !important;
  }
  
  .mb-8 {
    margin-bottom: 0.5rem !important;
  }
  
  .ml-8 {
    margin-left: 0.5rem !important;
  }
  
  .mr-8 {
    margin-right: 0.5rem !important;
  }
  
  .py-12 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  
  .px-12 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  
  .pt-12 {
    padding-top: 0.75rem !important;
  }
  
  .pb-12 {
    padding-bottom: 0.75rem !important;
  }
  
  .pl-12 {
    padding-left: 0.75rem !important;
  }
  
  .pr-12 {
    padding-right: 0.75rem !important;
  }
  
  .mt-12 {
    margin-top: 0.75rem !important;
  }
  
  .mb-12 {
    margin-bottom: 0.75rem !important;
  }
  
  .ml-12 {
    margin-left: 0.75rem !important;
  }
  
  .mr-12 {
    margin-right: 0.75rem !important;
  }
  
  .py-16 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  
  .px-16 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  
  .pt-16 {
    padding-top: 1rem !important;
  }
  
  .pb-16 {
    padding-bottom: 1rem !important;
  }
  
  .pl-16 {
    padding-left: 1rem !important;
  }
  
  .pr-16 {
    padding-right: 1rem !important;
  }
  
  .mt-16 {
    margin-top: 1rem !important;
  }
  
  .mb-16 {
    margin-bottom: 1rem !important;
  }
  
  .ml-16 {
    margin-left: 1rem !important;
  }
  
  .mr-16 {
    margin-right: 1rem !important;
  }
  
  .py-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  
  .px-20 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  
  .pt-20 {
    padding-top: 1.25rem !important;
  }
  
  .pb-20 {
    padding-bottom: 1.25rem !important;
  }
  
  .pl-20 {
    padding-left: 1.25rem !important;
  }
  
  .pr-20 {
    padding-right: 1.25rem !important;
  }
  
  .mt-20 {
    margin-top: 1.25rem !important;
  }
  
  .mb-20 {
    margin-bottom: 1.25rem !important;
  }
  
  .ml-20 {
    margin-left: 1.25rem !important;
  }
  
  .mr-20 {
    margin-right: 1.25rem !important;
  }
  
  .py-24 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  
  .px-24 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  
  .pt-24 {
    padding-top: 1.5rem !important;
  }
  
  .pb-24 {
    padding-bottom: 1.5rem !important;
  }
  
  .pl-24 {
    padding-left: 1.5rem !important;
  }
  
  .pr-24 {
    padding-right: 1.5rem !important;
  }
  
  .mt-24 {
    margin-top: 1.5rem !important;
  }
  
  .mb-24 {
    margin-bottom: 1.5rem !important;
  }
  
  .ml-24 {
    margin-left: 1.5rem !important;
  }
  
  .mr-24 {
    margin-right: 1.5rem !important;
  }
  
  .py-32 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  
  .px-32 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  
  .pt-32 {
    padding-top: 2rem !important;
  }
  
  .pb-32 {
    padding-bottom: 2rem !important;
  }
  
  .pl-32 {
    padding-left: 2rem !important;
  }
  
  .pr-32 {
    padding-right: 2rem !important;
  }
  
  .mt-32 {
    margin-top: 2rem !important;
  }
  
  .mb-32 {
    margin-bottom: 2rem !important;
  }
  
  .ml-32 {
    margin-left: 2rem !important;
  }
  
  .mr-32 {
    margin-right: 2rem !important;
  }
  
  .py-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  
  .px-40 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  
  .pt-40 {
    padding-top: 2.5rem !important;
  }
  
  .pb-40 {
    padding-bottom: 2.5rem !important;
  }
  
  .pl-40 {
    padding-left: 2.5rem !important;
  }
  
  .pr-40 {
    padding-right: 2.5rem !important;
  }
  
  .mt-40 {
    margin-top: 2.5rem !important;
  }
  
  .mb-40 {
    margin-bottom: 2.5rem !important;
  }
  
  .ml-40 {
    margin-left: 2.5rem !important;
  }
  
  .mr-40 {
    margin-right: 2.5rem !important;
  }
  
  .py-48 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  
  .px-48 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  
  .pt-48 {
    padding-top: 3rem !important;
  }
  
  .pb-48 {
    padding-bottom: 3rem !important;
  }
  
  .pl-48 {
    padding-left: 3rem !important;
  }
  
  .pr-48 {
    padding-right: 3rem !important;
  }
  
  .mt-48 {
    margin-top: 3rem !important;
  }
  
  .mb-48 {
    margin-bottom: 3rem !important;
  }
  
  .ml-48 {
    margin-left: 3rem !important;
  }
  
  .mr-48 {
    margin-right: 3rem !important;
  }
  
  .py-56 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  
  .px-56 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  
  .pt-56 {
    padding-top: 3.5rem !important;
  }
  
  .pb-56 {
    padding-bottom: 3.5rem !important;
  }
  
  .pl-56 {
    padding-left: 3.5rem !important;
  }
  
  .pr-56 {
    padding-right: 3.5rem !important;
  }
  
  .mt-56 {
    margin-top: 3.5rem !important;
  }
  
  .mb-56 {
    margin-bottom: 3.5rem !important;
  }
  
  .ml-56 {
    margin-left: 3.5rem !important;
  }
  
  .mr-56 {
    margin-right: 3.5rem !important;
  }
  
  .py-64 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  
  .px-64 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  
  .pt-64 {
    padding-top: 4rem !important;
  }
  
  .pb-64 {
    padding-bottom: 4rem !important;
  }
  
  .pl-64 {
    padding-left: 4rem !important;
  }
  
  .pr-64 {
    padding-right: 4rem !important;
  }
  
  .mt-64 {
    margin-top: 4rem !important;
  }
  
  .mb-64 {
    margin-bottom: 4rem !important;
  }
  
  .ml-64 {
    margin-left: 4rem !important;
  }
  
  .mr-64 {
    margin-right: 4rem !important;
  }
  
  .py-72 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  
  .px-72 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  
  .pt-72 {
    padding-top: 4.5rem !important;
  }
  
  .pb-72 {
    padding-bottom: 4.5rem !important;
  }
  
  .pl-72 {
    padding-left: 4.5rem !important;
  }
  
  .pr-72 {
    padding-right: 4.5rem !important;
  }
  
  .mt-72 {
    margin-top: 4.5rem !important;
  }
  
  .mb-72 {
    margin-bottom: 4.5rem !important;
  }
  
  .ml-72 {
    margin-left: 4.5rem !important;
  }
  
  .mr-72 {
    margin-right: 4.5rem !important;
  }
  
  .py-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  
  .px-80 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  
  .pt-80 {
    padding-top: 5rem !important;
  }
  
  .pb-80 {
    padding-bottom: 5rem !important;
  }
  
  .pl-80 {
    padding-left: 5rem !important;
  }
  
  .pr-80 {
    padding-right: 5rem !important;
  }
  
  .mt-80 {
    margin-top: 5rem !important;
  }
  
  .mb-80 {
    margin-bottom: 5rem !important;
  }
  
  .ml-80 {
    margin-left: 5rem !important;
  }
  
  .mr-80 {
    margin-right: 5rem !important;
  }
  
  @media (max-width: 1229px) {
    .lg\:py-0 {
      padding-top: 0rem !important;
      padding-bottom: 0rem !important;
    }
    .lg\:px-0 {
      padding-left: 0rem !important;
      padding-right: 0rem !important;
    }
    .lg\:pt-0 {
      padding-top: 0rem !important;
    }
    .lg\:pb-0 {
      padding-bottom: 0rem !important;
    }
    .lg\:pl-0 {
      padding-left: 0rem !important;
    }
    .lg\:pr-0 {
      padding-right: 0rem !important;
    }
    .lg\:mt-0 {
      margin-top: 0rem !important;
    }
    .lg\:mb-0 {
      margin-bottom: 0rem !important;
    }
    .lg\:ml-0 {
      margin-left: 0rem !important;
    }
    .lg\:mr-0 {
      margin-right: 0rem !important;
    }
    .lg\:py-4 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .lg\:px-4 {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
    .lg\:pt-4 {
      padding-top: 0.25rem !important;
    }
    .lg\:pb-4 {
      padding-bottom: 0.25rem !important;
    }
    .lg\:pl-4 {
      padding-left: 0.25rem !important;
    }
    .lg\:pr-4 {
      padding-right: 0.25rem !important;
    }
    .lg\:mt-4 {
      margin-top: 0.25rem !important;
    }
    .lg\:mb-4 {
      margin-bottom: 0.25rem !important;
    }
    .lg\:ml-4 {
      margin-left: 0.25rem !important;
    }
    .lg\:mr-4 {
      margin-right: 0.25rem !important;
    }
    .lg\:py-8 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .lg\:px-8 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
    .lg\:pt-8 {
      padding-top: 0.5rem !important;
    }
    .lg\:pb-8 {
      padding-bottom: 0.5rem !important;
    }
    .lg\:pl-8 {
      padding-left: 0.5rem !important;
    }
    .lg\:pr-8 {
      padding-right: 0.5rem !important;
    }
    .lg\:mt-8 {
      margin-top: 0.5rem !important;
    }
    .lg\:mb-8 {
      margin-bottom: 0.5rem !important;
    }
    .lg\:ml-8 {
      margin-left: 0.5rem !important;
    }
    .lg\:mr-8 {
      margin-right: 0.5rem !important;
    }
    .lg\:py-12 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
    }
    .lg\:px-12 {
      padding-left: 0.75rem !important;
      padding-right: 0.75rem !important;
    }
    .lg\:pt-12 {
      padding-top: 0.75rem !important;
    }
    .lg\:pb-12 {
      padding-bottom: 0.75rem !important;
    }
    .lg\:pl-12 {
      padding-left: 0.75rem !important;
    }
    .lg\:pr-12 {
      padding-right: 0.75rem !important;
    }
    .lg\:mt-12 {
      margin-top: 0.75rem !important;
    }
    .lg\:mb-12 {
      margin-bottom: 0.75rem !important;
    }
    .lg\:ml-12 {
      margin-left: 0.75rem !important;
    }
    .lg\:mr-12 {
      margin-right: 0.75rem !important;
    }
    .lg\:py-16 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .lg\:px-16 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .lg\:pt-16 {
      padding-top: 1rem !important;
    }
    .lg\:pb-16 {
      padding-bottom: 1rem !important;
    }
    .lg\:pl-16 {
      padding-left: 1rem !important;
    }
    .lg\:pr-16 {
      padding-right: 1rem !important;
    }
    .lg\:mt-16 {
      margin-top: 1rem !important;
    }
    .lg\:mb-16 {
      margin-bottom: 1rem !important;
    }
    .lg\:ml-16 {
      margin-left: 1rem !important;
    }
    .lg\:mr-16 {
      margin-right: 1rem !important;
    }
    .lg\:py-20 {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
    }
    .lg\:px-20 {
      padding-left: 1.25rem !important;
      padding-right: 1.25rem !important;
    }
    .lg\:pt-20 {
      padding-top: 1.25rem !important;
    }
    .lg\:pb-20 {
      padding-bottom: 1.25rem !important;
    }
    .lg\:pl-20 {
      padding-left: 1.25rem !important;
    }
    .lg\:pr-20 {
      padding-right: 1.25rem !important;
    }
    .lg\:mt-20 {
      margin-top: 1.25rem !important;
    }
    .lg\:mb-20 {
      margin-bottom: 1.25rem !important;
    }
    .lg\:ml-20 {
      margin-left: 1.25rem !important;
    }
    .lg\:mr-20 {
      margin-right: 1.25rem !important;
    }
    .lg\:py-24 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .lg\:px-24 {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
    .lg\:pt-24 {
      padding-top: 1.5rem !important;
    }
    .lg\:pb-24 {
      padding-bottom: 1.5rem !important;
    }
    .lg\:pl-24 {
      padding-left: 1.5rem !important;
    }
    .lg\:pr-24 {
      padding-right: 1.5rem !important;
    }
    .lg\:mt-24 {
      margin-top: 1.5rem !important;
    }
    .lg\:mb-24 {
      margin-bottom: 1.5rem !important;
    }
    .lg\:ml-24 {
      margin-left: 1.5rem !important;
    }
    .lg\:mr-24 {
      margin-right: 1.5rem !important;
    }
    .lg\:py-32 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
    .lg\:px-32 {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
    .lg\:pt-32 {
      padding-top: 2rem !important;
    }
    .lg\:pb-32 {
      padding-bottom: 2rem !important;
    }
    .lg\:pl-32 {
      padding-left: 2rem !important;
    }
    .lg\:pr-32 {
      padding-right: 2rem !important;
    }
    .lg\:mt-32 {
      margin-top: 2rem !important;
    }
    .lg\:mb-32 {
      margin-bottom: 2rem !important;
    }
    .lg\:ml-32 {
      margin-left: 2rem !important;
    }
    .lg\:mr-32 {
      margin-right: 2rem !important;
    }
    .lg\:py-40 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
    .lg\:px-40 {
      padding-left: 2.5rem !important;
      padding-right: 2.5rem !important;
    }
    .lg\:pt-40 {
      padding-top: 2.5rem !important;
    }
    .lg\:pb-40 {
      padding-bottom: 2.5rem !important;
    }
    .lg\:pl-40 {
      padding-left: 2.5rem !important;
    }
    .lg\:pr-40 {
      padding-right: 2.5rem !important;
    }
    .lg\:mt-40 {
      margin-top: 2.5rem !important;
    }
    .lg\:mb-40 {
      margin-bottom: 2.5rem !important;
    }
    .lg\:ml-40 {
      margin-left: 2.5rem !important;
    }
    .lg\:mr-40 {
      margin-right: 2.5rem !important;
    }
    .lg\:py-48 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .lg\:px-48 {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
    .lg\:pt-48 {
      padding-top: 3rem !important;
    }
    .lg\:pb-48 {
      padding-bottom: 3rem !important;
    }
    .lg\:pl-48 {
      padding-left: 3rem !important;
    }
    .lg\:pr-48 {
      padding-right: 3rem !important;
    }
    .lg\:mt-48 {
      margin-top: 3rem !important;
    }
    .lg\:mb-48 {
      margin-bottom: 3rem !important;
    }
    .lg\:ml-48 {
      margin-left: 3rem !important;
    }
    .lg\:mr-48 {
      margin-right: 3rem !important;
    }
    .lg\:py-56 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important;
    }
    .lg\:px-56 {
      padding-left: 3.5rem !important;
      padding-right: 3.5rem !important;
    }
    .lg\:pt-56 {
      padding-top: 3.5rem !important;
    }
    .lg\:pb-56 {
      padding-bottom: 3.5rem !important;
    }
    .lg\:pl-56 {
      padding-left: 3.5rem !important;
    }
    .lg\:pr-56 {
      padding-right: 3.5rem !important;
    }
    .lg\:mt-56 {
      margin-top: 3.5rem !important;
    }
    .lg\:mb-56 {
      margin-bottom: 3.5rem !important;
    }
    .lg\:ml-56 {
      margin-left: 3.5rem !important;
    }
    .lg\:mr-56 {
      margin-right: 3.5rem !important;
    }
    .lg\:py-64 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .lg\:px-64 {
      padding-left: 4rem !important;
      padding-right: 4rem !important;
    }
    .lg\:pt-64 {
      padding-top: 4rem !important;
    }
    .lg\:pb-64 {
      padding-bottom: 4rem !important;
    }
    .lg\:pl-64 {
      padding-left: 4rem !important;
    }
    .lg\:pr-64 {
      padding-right: 4rem !important;
    }
    .lg\:mt-64 {
      margin-top: 4rem !important;
    }
    .lg\:mb-64 {
      margin-bottom: 4rem !important;
    }
    .lg\:ml-64 {
      margin-left: 4rem !important;
    }
    .lg\:mr-64 {
      margin-right: 4rem !important;
    }
    .lg\:py-72 {
      padding-top: 4.5rem !important;
      padding-bottom: 4.5rem !important;
    }
    .lg\:px-72 {
      padding-left: 4.5rem !important;
      padding-right: 4.5rem !important;
    }
    .lg\:pt-72 {
      padding-top: 4.5rem !important;
    }
    .lg\:pb-72 {
      padding-bottom: 4.5rem !important;
    }
    .lg\:pl-72 {
      padding-left: 4.5rem !important;
    }
    .lg\:pr-72 {
      padding-right: 4.5rem !important;
    }
    .lg\:mt-72 {
      margin-top: 4.5rem !important;
    }
    .lg\:mb-72 {
      margin-bottom: 4.5rem !important;
    }
    .lg\:ml-72 {
      margin-left: 4.5rem !important;
    }
    .lg\:mr-72 {
      margin-right: 4.5rem !important;
    }
    .lg\:py-80 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .lg\:px-80 {
      padding-left: 5rem !important;
      padding-right: 5rem !important;
    }
    .lg\:pt-80 {
      padding-top: 5rem !important;
    }
    .lg\:pb-80 {
      padding-bottom: 5rem !important;
    }
    .lg\:pl-80 {
      padding-left: 5rem !important;
    }
    .lg\:pr-80 {
      padding-right: 5rem !important;
    }
    .lg\:mt-80 {
      margin-top: 5rem !important;
    }
    .lg\:mb-80 {
      margin-bottom: 5rem !important;
    }
    .lg\:ml-80 {
      margin-left: 5rem !important;
    }
    .lg\:mr-80 {
      margin-right: 5rem !important;
    }
  }
  
  @media (max-width: 991px) {
    .md\:py-0 {
      padding-top: 0rem !important;
      padding-bottom: 0rem !important;
    }
    .md\:px-0 {
      padding-left: 0rem !important;
      padding-right: 0rem !important;
    }
    .md\:pt-0 {
      padding-top: 0rem !important;
    }
    .md\:pb-0 {
      padding-bottom: 0rem !important;
    }
    .md\:pl-0 {
      padding-left: 0rem !important;
    }
    .md\:pr-0 {
      padding-right: 0rem !important;
    }
    .md\:mt-0 {
      margin-top: 0rem !important;
    }
    .md\:mb-0 {
      margin-bottom: 0rem !important;
    }
    .md\:ml-0 {
      margin-left: 0rem !important;
    }
    .md\:mr-0 {
      margin-right: 0rem !important;
    }
    .md\:py-4 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .md\:px-4 {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
    .md\:pt-4 {
      padding-top: 0.25rem !important;
    }
    .md\:pb-4 {
      padding-bottom: 0.25rem !important;
    }
    .md\:pl-4 {
      padding-left: 0.25rem !important;
    }
    .md\:pr-4 {
      padding-right: 0.25rem !important;
    }
    .md\:mt-4 {
      margin-top: 0.25rem !important;
    }
    .md\:mb-4 {
      margin-bottom: 0.25rem !important;
    }
    .md\:ml-4 {
      margin-left: 0.25rem !important;
    }
    .md\:mr-4 {
      margin-right: 0.25rem !important;
    }
    .md\:py-8 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .md\:px-8 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
    .md\:pt-8 {
      padding-top: 0.5rem !important;
    }
    .md\:pb-8 {
      padding-bottom: 0.5rem !important;
    }
    .md\:pl-8 {
      padding-left: 0.5rem !important;
    }
    .md\:pr-8 {
      padding-right: 0.5rem !important;
    }
    .md\:mt-8 {
      margin-top: 0.5rem !important;
    }
    .md\:mb-8 {
      margin-bottom: 0.5rem !important;
    }
    .md\:ml-8 {
      margin-left: 0.5rem !important;
    }
    .md\:mr-8 {
      margin-right: 0.5rem !important;
    }
    .md\:py-12 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
    }
    .md\:px-12 {
      padding-left: 0.75rem !important;
      padding-right: 0.75rem !important;
    }
    .md\:pt-12 {
      padding-top: 0.75rem !important;
    }
    .md\:pb-12 {
      padding-bottom: 0.75rem !important;
    }
    .md\:pl-12 {
      padding-left: 0.75rem !important;
    }
    .md\:pr-12 {
      padding-right: 0.75rem !important;
    }
    .md\:mt-12 {
      margin-top: 0.75rem !important;
    }
    .md\:mb-12 {
      margin-bottom: 0.75rem !important;
    }
    .md\:ml-12 {
      margin-left: 0.75rem !important;
    }
    .md\:mr-12 {
      margin-right: 0.75rem !important;
    }
    .md\:py-16 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .md\:px-16 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .md\:pt-16 {
      padding-top: 1rem !important;
    }
    .md\:pb-16 {
      padding-bottom: 1rem !important;
    }
    .md\:pl-16 {
      padding-left: 1rem !important;
    }
    .md\:pr-16 {
      padding-right: 1rem !important;
    }
    .md\:mt-16 {
      margin-top: 1rem !important;
    }
    .md\:mb-16 {
      margin-bottom: 1rem !important;
    }
    .md\:ml-16 {
      margin-left: 1rem !important;
    }
    .md\:mr-16 {
      margin-right: 1rem !important;
    }
    .md\:py-20 {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
    }
    .md\:px-20 {
      padding-left: 1.25rem !important;
      padding-right: 1.25rem !important;
    }
    .md\:pt-20 {
      padding-top: 1.25rem !important;
    }
    .md\:pb-20 {
      padding-bottom: 1.25rem !important;
    }
    .md\:pl-20 {
      padding-left: 1.25rem !important;
    }
    .md\:pr-20 {
      padding-right: 1.25rem !important;
    }
    .md\:mt-20 {
      margin-top: 1.25rem !important;
    }
    .md\:mb-20 {
      margin-bottom: 1.25rem !important;
    }
    .md\:ml-20 {
      margin-left: 1.25rem !important;
    }
    .md\:mr-20 {
      margin-right: 1.25rem !important;
    }
    .md\:py-24 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .md\:px-24 {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
    .md\:pt-24 {
      padding-top: 1.5rem !important;
    }
    .md\:pb-24 {
      padding-bottom: 1.5rem !important;
    }
    .md\:pl-24 {
      padding-left: 1.5rem !important;
    }
    .md\:pr-24 {
      padding-right: 1.5rem !important;
    }
    .md\:mt-24 {
      margin-top: 1.5rem !important;
    }
    .md\:mb-24 {
      margin-bottom: 1.5rem !important;
    }
    .md\:ml-24 {
      margin-left: 1.5rem !important;
    }
    .md\:mr-24 {
      margin-right: 1.5rem !important;
    }
    .md\:py-32 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
    .md\:px-32 {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
    .md\:pt-32 {
      padding-top: 2rem !important;
    }
    .md\:pb-32 {
      padding-bottom: 2rem !important;
    }
    .md\:pl-32 {
      padding-left: 2rem !important;
    }
    .md\:pr-32 {
      padding-right: 2rem !important;
    }
    .md\:mt-32 {
      margin-top: 2rem !important;
    }
    .md\:mb-32 {
      margin-bottom: 2rem !important;
    }
    .md\:ml-32 {
      margin-left: 2rem !important;
    }
    .md\:mr-32 {
      margin-right: 2rem !important;
    }
    .md\:py-40 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
    .md\:px-40 {
      padding-left: 2.5rem !important;
      padding-right: 2.5rem !important;
    }
    .md\:pt-40 {
      padding-top: 2.5rem !important;
    }
    .md\:pb-40 {
      padding-bottom: 2.5rem !important;
    }
    .md\:pl-40 {
      padding-left: 2.5rem !important;
    }
    .md\:pr-40 {
      padding-right: 2.5rem !important;
    }
    .md\:mt-40 {
      margin-top: 2.5rem !important;
    }
    .md\:mb-40 {
      margin-bottom: 2.5rem !important;
    }
    .md\:ml-40 {
      margin-left: 2.5rem !important;
    }
    .md\:mr-40 {
      margin-right: 2.5rem !important;
    }
    .md\:py-48 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .md\:px-48 {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
    .md\:pt-48 {
      padding-top: 3rem !important;
    }
    .md\:pb-48 {
      padding-bottom: 3rem !important;
    }
    .md\:pl-48 {
      padding-left: 3rem !important;
    }
    .md\:pr-48 {
      padding-right: 3rem !important;
    }
    .md\:mt-48 {
      margin-top: 3rem !important;
    }
    .md\:mb-48 {
      margin-bottom: 3rem !important;
    }
    .md\:ml-48 {
      margin-left: 3rem !important;
    }
    .md\:mr-48 {
      margin-right: 3rem !important;
    }
    .md\:py-56 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important;
    }
    .md\:px-56 {
      padding-left: 3.5rem !important;
      padding-right: 3.5rem !important;
    }
    .md\:pt-56 {
      padding-top: 3.5rem !important;
    }
    .md\:pb-56 {
      padding-bottom: 3.5rem !important;
    }
    .md\:pl-56 {
      padding-left: 3.5rem !important;
    }
    .md\:pr-56 {
      padding-right: 3.5rem !important;
    }
    .md\:mt-56 {
      margin-top: 3.5rem !important;
    }
    .md\:mb-56 {
      margin-bottom: 3.5rem !important;
    }
    .md\:ml-56 {
      margin-left: 3.5rem !important;
    }
    .md\:mr-56 {
      margin-right: 3.5rem !important;
    }
    .md\:py-64 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .md\:px-64 {
      padding-left: 4rem !important;
      padding-right: 4rem !important;
    }
    .md\:pt-64 {
      padding-top: 4rem !important;
    }
    .md\:pb-64 {
      padding-bottom: 4rem !important;
    }
    .md\:pl-64 {
      padding-left: 4rem !important;
    }
    .md\:pr-64 {
      padding-right: 4rem !important;
    }
    .md\:mt-64 {
      margin-top: 4rem !important;
    }
    .md\:mb-64 {
      margin-bottom: 4rem !important;
    }
    .md\:ml-64 {
      margin-left: 4rem !important;
    }
    .md\:mr-64 {
      margin-right: 4rem !important;
    }
    .md\:py-72 {
      padding-top: 4.5rem !important;
      padding-bottom: 4.5rem !important;
    }
    .md\:px-72 {
      padding-left: 4.5rem !important;
      padding-right: 4.5rem !important;
    }
    .md\:pt-72 {
      padding-top: 4.5rem !important;
    }
    .md\:pb-72 {
      padding-bottom: 4.5rem !important;
    }
    .md\:pl-72 {
      padding-left: 4.5rem !important;
    }
    .md\:pr-72 {
      padding-right: 4.5rem !important;
    }
    .md\:mt-72 {
      margin-top: 4.5rem !important;
    }
    .md\:mb-72 {
      margin-bottom: 4.5rem !important;
    }
    .md\:ml-72 {
      margin-left: 4.5rem !important;
    }
    .md\:mr-72 {
      margin-right: 4.5rem !important;
    }
    .md\:py-80 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .md\:px-80 {
      padding-left: 5rem !important;
      padding-right: 5rem !important;
    }
    .md\:pt-80 {
      padding-top: 5rem !important;
    }
    .md\:pb-80 {
      padding-bottom: 5rem !important;
    }
    .md\:pl-80 {
      padding-left: 5rem !important;
    }
    .md\:pr-80 {
      padding-right: 5rem !important;
    }
    .md\:mt-80 {
      margin-top: 5rem !important;
    }
    .md\:mb-80 {
      margin-bottom: 5rem !important;
    }
    .md\:ml-80 {
      margin-left: 5rem !important;
    }
    .md\:mr-80 {
      margin-right: 5rem !important;
    }
  }
  
  @media (max-width: 767px) {
    .sm\:py-0 {
      padding-top: 0rem !important;
      padding-bottom: 0rem !important;
    }
    .sm\:px-0 {
      padding-left: 0rem !important;
      padding-right: 0rem !important;
    }
    .sm\:pt-0 {
      padding-top: 0rem !important;
    }
    .sm\:pb-0 {
      padding-bottom: 0rem !important;
    }
    .sm\:pl-0 {
      padding-left: 0rem !important;
    }
    .sm\:pr-0 {
      padding-right: 0rem !important;
    }
    .sm\:mt-0 {
      margin-top: 0rem !important;
    }
    .sm\:mb-0 {
      margin-bottom: 0rem !important;
    }
    .sm\:ml-0 {
      margin-left: 0rem !important;
    }
    .sm\:mr-0 {
      margin-right: 0rem !important;
    }
    .sm\:py-4 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .sm\:px-4 {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
    .sm\:pt-4 {
      padding-top: 0.25rem !important;
    }
    .sm\:pb-4 {
      padding-bottom: 0.25rem !important;
    }
    .sm\:pl-4 {
      padding-left: 0.25rem !important;
    }
    .sm\:pr-4 {
      padding-right: 0.25rem !important;
    }
    .sm\:mt-4 {
      margin-top: 0.25rem !important;
    }
    .sm\:mb-4 {
      margin-bottom: 0.25rem !important;
    }
    .sm\:ml-4 {
      margin-left: 0.25rem !important;
    }
    .sm\:mr-4 {
      margin-right: 0.25rem !important;
    }
    .sm\:py-8 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .sm\:px-8 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
    .sm\:pt-8 {
      padding-top: 0.5rem !important;
    }
    .sm\:pb-8 {
      padding-bottom: 0.5rem !important;
    }
    .sm\:pl-8 {
      padding-left: 0.5rem !important;
    }
    .sm\:pr-8 {
      padding-right: 0.5rem !important;
    }
    .sm\:mt-8 {
      margin-top: 0.5rem !important;
    }
    .sm\:mb-8 {
      margin-bottom: 0.5rem !important;
    }
    .sm\:ml-8 {
      margin-left: 0.5rem !important;
    }
    .sm\:mr-8 {
      margin-right: 0.5rem !important;
    }
    .sm\:py-12 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
    }
    .sm\:px-12 {
      padding-left: 0.75rem !important;
      padding-right: 0.75rem !important;
    }
    .sm\:pt-12 {
      padding-top: 0.75rem !important;
    }
    .sm\:pb-12 {
      padding-bottom: 0.75rem !important;
    }
    .sm\:pl-12 {
      padding-left: 0.75rem !important;
    }
    .sm\:pr-12 {
      padding-right: 0.75rem !important;
    }
    .sm\:mt-12 {
      margin-top: 0.75rem !important;
    }
    .sm\:mb-12 {
      margin-bottom: 0.75rem !important;
    }
    .sm\:ml-12 {
      margin-left: 0.75rem !important;
    }
    .sm\:mr-12 {
      margin-right: 0.75rem !important;
    }
    .sm\:py-16 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .sm\:px-16 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .sm\:pt-16 {
      padding-top: 1rem !important;
    }
    .sm\:pb-16 {
      padding-bottom: 1rem !important;
    }
    .sm\:pl-16 {
      padding-left: 1rem !important;
    }
    .sm\:pr-16 {
      padding-right: 1rem !important;
    }
    .sm\:mt-16 {
      margin-top: 1rem !important;
    }
    .sm\:mb-16 {
      margin-bottom: 1rem !important;
    }
    .sm\:ml-16 {
      margin-left: 1rem !important;
    }
    .sm\:mr-16 {
      margin-right: 1rem !important;
    }
    .sm\:py-20 {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
    }
    .sm\:px-20 {
      padding-left: 1.25rem !important;
      padding-right: 1.25rem !important;
    }
    .sm\:pt-20 {
      padding-top: 1.25rem !important;
    }
    .sm\:pb-20 {
      padding-bottom: 1.25rem !important;
    }
    .sm\:pl-20 {
      padding-left: 1.25rem !important;
    }
    .sm\:pr-20 {
      padding-right: 1.25rem !important;
    }
    .sm\:mt-20 {
      margin-top: 1.25rem !important;
    }
    .sm\:mb-20 {
      margin-bottom: 1.25rem !important;
    }
    .sm\:ml-20 {
      margin-left: 1.25rem !important;
    }
    .sm\:mr-20 {
      margin-right: 1.25rem !important;
    }
    .sm\:py-24 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .sm\:px-24 {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
    .sm\:pt-24 {
      padding-top: 1.5rem !important;
    }
    .sm\:pb-24 {
      padding-bottom: 1.5rem !important;
    }
    .sm\:pl-24 {
      padding-left: 1.5rem !important;
    }
    .sm\:pr-24 {
      padding-right: 1.5rem !important;
    }
    .sm\:mt-24 {
      margin-top: 1.5rem !important;
    }
    .sm\:mb-24 {
      margin-bottom: 1.5rem !important;
    }
    .sm\:ml-24 {
      margin-left: 1.5rem !important;
    }
    .sm\:mr-24 {
      margin-right: 1.5rem !important;
    }
    .sm\:py-32 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
    .sm\:px-32 {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
    .sm\:pt-32 {
      padding-top: 2rem !important;
    }
    .sm\:pb-32 {
      padding-bottom: 2rem !important;
    }
    .sm\:pl-32 {
      padding-left: 2rem !important;
    }
    .sm\:pr-32 {
      padding-right: 2rem !important;
    }
    .sm\:mt-32 {
      margin-top: 2rem !important;
    }
    .sm\:mb-32 {
      margin-bottom: 2rem !important;
    }
    .sm\:ml-32 {
      margin-left: 2rem !important;
    }
    .sm\:mr-32 {
      margin-right: 2rem !important;
    }
    .sm\:py-40 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
    .sm\:px-40 {
      padding-left: 2.5rem !important;
      padding-right: 2.5rem !important;
    }
    .sm\:pt-40 {
      padding-top: 2.5rem !important;
    }
    .sm\:pb-40 {
      padding-bottom: 2.5rem !important;
    }
    .sm\:pl-40 {
      padding-left: 2.5rem !important;
    }
    .sm\:pr-40 {
      padding-right: 2.5rem !important;
    }
    .sm\:mt-40 {
      margin-top: 2.5rem !important;
    }
    .sm\:mb-40 {
      margin-bottom: 2.5rem !important;
    }
    .sm\:ml-40 {
      margin-left: 2.5rem !important;
    }
    .sm\:mr-40 {
      margin-right: 2.5rem !important;
    }
    .sm\:py-48 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .sm\:px-48 {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
    .sm\:pt-48 {
      padding-top: 3rem !important;
    }
    .sm\:pb-48 {
      padding-bottom: 3rem !important;
    }
    .sm\:pl-48 {
      padding-left: 3rem !important;
    }
    .sm\:pr-48 {
      padding-right: 3rem !important;
    }
    .sm\:mt-48 {
      margin-top: 3rem !important;
    }
    .sm\:mb-48 {
      margin-bottom: 3rem !important;
    }
    .sm\:ml-48 {
      margin-left: 3rem !important;
    }
    .sm\:mr-48 {
      margin-right: 3rem !important;
    }
    .sm\:py-56 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important;
    }
    .sm\:px-56 {
      padding-left: 3.5rem !important;
      padding-right: 3.5rem !important;
    }
    .sm\:pt-56 {
      padding-top: 3.5rem !important;
    }
    .sm\:pb-56 {
      padding-bottom: 3.5rem !important;
    }
    .sm\:pl-56 {
      padding-left: 3.5rem !important;
    }
    .sm\:pr-56 {
      padding-right: 3.5rem !important;
    }
    .sm\:mt-56 {
      margin-top: 3.5rem !important;
    }
    .sm\:mb-56 {
      margin-bottom: 3.5rem !important;
    }
    .sm\:ml-56 {
      margin-left: 3.5rem !important;
    }
    .sm\:mr-56 {
      margin-right: 3.5rem !important;
    }
    .sm\:py-64 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .sm\:px-64 {
      padding-left: 4rem !important;
      padding-right: 4rem !important;
    }
    .sm\:pt-64 {
      padding-top: 4rem !important;
    }
    .sm\:pb-64 {
      padding-bottom: 4rem !important;
    }
    .sm\:pl-64 {
      padding-left: 4rem !important;
    }
    .sm\:pr-64 {
      padding-right: 4rem !important;
    }
    .sm\:mt-64 {
      margin-top: 4rem !important;
    }
    .sm\:mb-64 {
      margin-bottom: 4rem !important;
    }
    .sm\:ml-64 {
      margin-left: 4rem !important;
    }
    .sm\:mr-64 {
      margin-right: 4rem !important;
    }
    .sm\:py-72 {
      padding-top: 4.5rem !important;
      padding-bottom: 4.5rem !important;
    }
    .sm\:px-72 {
      padding-left: 4.5rem !important;
      padding-right: 4.5rem !important;
    }
    .sm\:pt-72 {
      padding-top: 4.5rem !important;
    }
    .sm\:pb-72 {
      padding-bottom: 4.5rem !important;
    }
    .sm\:pl-72 {
      padding-left: 4.5rem !important;
    }
    .sm\:pr-72 {
      padding-right: 4.5rem !important;
    }
    .sm\:mt-72 {
      margin-top: 4.5rem !important;
    }
    .sm\:mb-72 {
      margin-bottom: 4.5rem !important;
    }
    .sm\:ml-72 {
      margin-left: 4.5rem !important;
    }
    .sm\:mr-72 {
      margin-right: 4.5rem !important;
    }
    .sm\:py-80 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .sm\:px-80 {
      padding-left: 5rem !important;
      padding-right: 5rem !important;
    }
    .sm\:pt-80 {
      padding-top: 5rem !important;
    }
    .sm\:pb-80 {
      padding-bottom: 5rem !important;
    }
    .sm\:pl-80 {
      padding-left: 5rem !important;
    }
    .sm\:pr-80 {
      padding-right: 5rem !important;
    }
    .sm\:mt-80 {
      margin-top: 5rem !important;
    }
    .sm\:mb-80 {
      margin-bottom: 5rem !important;
    }
    .sm\:ml-80 {
      margin-left: 5rem !important;
    }
    .sm\:mr-80 {
      margin-right: 5rem !important;
    }
  }
  
  @media (max-width: 575px) {
    .xs\:py-0 {
      padding-top: 0rem !important;
      padding-bottom: 0rem !important;
    }
    .xs\:px-0 {
      padding-left: 0rem !important;
      padding-right: 0rem !important;
    }
    .xs\:pt-0 {
      padding-top: 0rem !important;
    }
    .xs\:pb-0 {
      padding-bottom: 0rem !important;
    }
    .xs\:pl-0 {
      padding-left: 0rem !important;
    }
    .xs\:pr-0 {
      padding-right: 0rem !important;
    }
    .xs\:mt-0 {
      margin-top: 0rem !important;
    }
    .xs\:mb-0 {
      margin-bottom: 0rem !important;
    }
    .xs\:ml-0 {
      margin-left: 0rem !important;
    }
    .xs\:mr-0 {
      margin-right: 0rem !important;
    }
    .xs\:py-4 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .xs\:px-4 {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
    .xs\:pt-4 {
      padding-top: 0.25rem !important;
    }
    .xs\:pb-4 {
      padding-bottom: 0.25rem !important;
    }
    .xs\:pl-4 {
      padding-left: 0.25rem !important;
    }
    .xs\:pr-4 {
      padding-right: 0.25rem !important;
    }
    .xs\:mt-4 {
      margin-top: 0.25rem !important;
    }
    .xs\:mb-4 {
      margin-bottom: 0.25rem !important;
    }
    .xs\:ml-4 {
      margin-left: 0.25rem !important;
    }
    .xs\:mr-4 {
      margin-right: 0.25rem !important;
    }
    .xs\:py-8 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .xs\:px-8 {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
    .xs\:pt-8 {
      padding-top: 0.5rem !important;
    }
    .xs\:pb-8 {
      padding-bottom: 0.5rem !important;
    }
    .xs\:pl-8 {
      padding-left: 0.5rem !important;
    }
    .xs\:pr-8 {
      padding-right: 0.5rem !important;
    }
    .xs\:mt-8 {
      margin-top: 0.5rem !important;
    }
    .xs\:mb-8 {
      margin-bottom: 0.5rem !important;
    }
    .xs\:ml-8 {
      margin-left: 0.5rem !important;
    }
    .xs\:mr-8 {
      margin-right: 0.5rem !important;
    }
    .xs\:py-12 {
      padding-top: 0.75rem !important;
      padding-bottom: 0.75rem !important;
    }
    .xs\:px-12 {
      padding-left: 0.75rem !important;
      padding-right: 0.75rem !important;
    }
    .xs\:pt-12 {
      padding-top: 0.75rem !important;
    }
    .xs\:pb-12 {
      padding-bottom: 0.75rem !important;
    }
    .xs\:pl-12 {
      padding-left: 0.75rem !important;
    }
    .xs\:pr-12 {
      padding-right: 0.75rem !important;
    }
    .xs\:mt-12 {
      margin-top: 0.75rem !important;
    }
    .xs\:mb-12 {
      margin-bottom: 0.75rem !important;
    }
    .xs\:ml-12 {
      margin-left: 0.75rem !important;
    }
    .xs\:mr-12 {
      margin-right: 0.75rem !important;
    }
    .xs\:py-16 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .xs\:px-16 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .xs\:pt-16 {
      padding-top: 1rem !important;
    }
    .xs\:pb-16 {
      padding-bottom: 1rem !important;
    }
    .xs\:pl-16 {
      padding-left: 1rem !important;
    }
    .xs\:pr-16 {
      padding-right: 1rem !important;
    }
    .xs\:mt-16 {
      margin-top: 1rem !important;
    }
    .xs\:mb-16 {
      margin-bottom: 1rem !important;
    }
    .xs\:ml-16 {
      margin-left: 1rem !important;
    }
    .xs\:mr-16 {
      margin-right: 1rem !important;
    }
    .xs\:py-20 {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
    }
    .xs\:px-20 {
      padding-left: 1.25rem !important;
      padding-right: 1.25rem !important;
    }
    .xs\:pt-20 {
      padding-top: 1.25rem !important;
    }
    .xs\:pb-20 {
      padding-bottom: 1.25rem !important;
    }
    .xs\:pl-20 {
      padding-left: 1.25rem !important;
    }
    .xs\:pr-20 {
      padding-right: 1.25rem !important;
    }
    .xs\:mt-20 {
      margin-top: 1.25rem !important;
    }
    .xs\:mb-20 {
      margin-bottom: 1.25rem !important;
    }
    .xs\:ml-20 {
      margin-left: 1.25rem !important;
    }
    .xs\:mr-20 {
      margin-right: 1.25rem !important;
    }
    .xs\:py-24 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .xs\:px-24 {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
    .xs\:pt-24 {
      padding-top: 1.5rem !important;
    }
    .xs\:pb-24 {
      padding-bottom: 1.5rem !important;
    }
    .xs\:pl-24 {
      padding-left: 1.5rem !important;
    }
    .xs\:pr-24 {
      padding-right: 1.5rem !important;
    }
    .xs\:mt-24 {
      margin-top: 1.5rem !important;
    }
    .xs\:mb-24 {
      margin-bottom: 1.5rem !important;
    }
    .xs\:ml-24 {
      margin-left: 1.5rem !important;
    }
    .xs\:mr-24 {
      margin-right: 1.5rem !important;
    }
    .xs\:py-32 {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
    .xs\:px-32 {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
    .xs\:pt-32 {
      padding-top: 2rem !important;
    }
    .xs\:pb-32 {
      padding-bottom: 2rem !important;
    }
    .xs\:pl-32 {
      padding-left: 2rem !important;
    }
    .xs\:pr-32 {
      padding-right: 2rem !important;
    }
    .xs\:mt-32 {
      margin-top: 2rem !important;
    }
    .xs\:mb-32 {
      margin-bottom: 2rem !important;
    }
    .xs\:ml-32 {
      margin-left: 2rem !important;
    }
    .xs\:mr-32 {
      margin-right: 2rem !important;
    }
    .xs\:py-40 {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
    .xs\:px-40 {
      padding-left: 2.5rem !important;
      padding-right: 2.5rem !important;
    }
    .xs\:pt-40 {
      padding-top: 2.5rem !important;
    }
    .xs\:pb-40 {
      padding-bottom: 2.5rem !important;
    }
    .xs\:pl-40 {
      padding-left: 2.5rem !important;
    }
    .xs\:pr-40 {
      padding-right: 2.5rem !important;
    }
    .xs\:mt-40 {
      margin-top: 2.5rem !important;
    }
    .xs\:mb-40 {
      margin-bottom: 2.5rem !important;
    }
    .xs\:ml-40 {
      margin-left: 2.5rem !important;
    }
    .xs\:mr-40 {
      margin-right: 2.5rem !important;
    }
    .xs\:py-48 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .xs\:px-48 {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
    .xs\:pt-48 {
      padding-top: 3rem !important;
    }
    .xs\:pb-48 {
      padding-bottom: 3rem !important;
    }
    .xs\:pl-48 {
      padding-left: 3rem !important;
    }
    .xs\:pr-48 {
      padding-right: 3rem !important;
    }
    .xs\:mt-48 {
      margin-top: 3rem !important;
    }
    .xs\:mb-48 {
      margin-bottom: 3rem !important;
    }
    .xs\:ml-48 {
      margin-left: 3rem !important;
    }
    .xs\:mr-48 {
      margin-right: 3rem !important;
    }
    .xs\:py-56 {
      padding-top: 3.5rem !important;
      padding-bottom: 3.5rem !important;
    }
    .xs\:px-56 {
      padding-left: 3.5rem !important;
      padding-right: 3.5rem !important;
    }
    .xs\:pt-56 {
      padding-top: 3.5rem !important;
    }
    .xs\:pb-56 {
      padding-bottom: 3.5rem !important;
    }
    .xs\:pl-56 {
      padding-left: 3.5rem !important;
    }
    .xs\:pr-56 {
      padding-right: 3.5rem !important;
    }
    .xs\:mt-56 {
      margin-top: 3.5rem !important;
    }
    .xs\:mb-56 {
      margin-bottom: 3.5rem !important;
    }
    .xs\:ml-56 {
      margin-left: 3.5rem !important;
    }
    .xs\:mr-56 {
      margin-right: 3.5rem !important;
    }
    .xs\:py-64 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .xs\:px-64 {
      padding-left: 4rem !important;
      padding-right: 4rem !important;
    }
    .xs\:pt-64 {
      padding-top: 4rem !important;
    }
    .xs\:pb-64 {
      padding-bottom: 4rem !important;
    }
    .xs\:pl-64 {
      padding-left: 4rem !important;
    }
    .xs\:pr-64 {
      padding-right: 4rem !important;
    }
    .xs\:mt-64 {
      margin-top: 4rem !important;
    }
    .xs\:mb-64 {
      margin-bottom: 4rem !important;
    }
    .xs\:ml-64 {
      margin-left: 4rem !important;
    }
    .xs\:mr-64 {
      margin-right: 4rem !important;
    }
    .xs\:py-72 {
      padding-top: 4.5rem !important;
      padding-bottom: 4.5rem !important;
    }
    .xs\:px-72 {
      padding-left: 4.5rem !important;
      padding-right: 4.5rem !important;
    }
    .xs\:pt-72 {
      padding-top: 4.5rem !important;
    }
    .xs\:pb-72 {
      padding-bottom: 4.5rem !important;
    }
    .xs\:pl-72 {
      padding-left: 4.5rem !important;
    }
    .xs\:pr-72 {
      padding-right: 4.5rem !important;
    }
    .xs\:mt-72 {
      margin-top: 4.5rem !important;
    }
    .xs\:mb-72 {
      margin-bottom: 4.5rem !important;
    }
    .xs\:ml-72 {
      margin-left: 4.5rem !important;
    }
    .xs\:mr-72 {
      margin-right: 4.5rem !important;
    }
    .xs\:py-80 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .xs\:px-80 {
      padding-left: 5rem !important;
      padding-right: 5rem !important;
    }
    .xs\:pt-80 {
      padding-top: 5rem !important;
    }
    .xs\:pb-80 {
      padding-bottom: 5rem !important;
    }
    .xs\:pl-80 {
      padding-left: 5rem !important;
    }
    .xs\:pr-80 {
      padding-right: 5rem !important;
    }
    .xs\:mt-80 {
      margin-top: 5rem !important;
    }
    .xs\:mb-80 {
      margin-bottom: 5rem !important;
    }
    .xs\:ml-80 {
      margin-left: 5rem !important;
    }
    .xs\:mr-80 {
      margin-right: 5rem !important;
    }
  }
  
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .xs\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  @media (max-width: 991px) {
    .md\:mt-120 {
      margin-top: 7.5rem;
    }
  }
  
  .ml-minus-sm {
    margin-left: -3.75rem;
  }
  
  .ml-minus-md {
    margin-left: -6.25rem;
  }
  
  .ml-minus-lg {
    margin-left: -8.75rem;
  }
  
  .ml-minus-xl {
    margin-left: -11.25rem;
  }
  
  .ml-minus-col-1 {
    margin-left: -8.33333vw;
  }
  
  .ml-minus-col-2 {
    margin-left: -16.66667vw;
  }
  
  .ml-minus-col-3 {
    margin-left: -25vw;
  }
  
  .ml-minus-col-4 {
    margin-left: -33.33333vw;
  }
  
  .mr-minus-sm {
    margin-right: -3.75rem;
  }
  
  .mr-minus-md {
    margin-right: -6.25rem;
  }
  
  .mr-minus-lg {
    margin-right: -8.75rem;
  }
  
  .mr-minus-xl {
    margin-right: -11.25rem;
  }
  
  .mr-minus-col-1 {
    margin-right: -8.33333vw;
  }
  
  .mr-minus-col-2 {
    margin-right: -16.66667vw;
  }
  
  .mr-minus-col-3 {
    margin-right: -25vw;
  }
  
  .mr-minus-col-4 {
    margin-right: -33.33333vw;
  }
  
  @media (max-width: 1229px) {
    .lg\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .lg\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .lg\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .lg\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .lg\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .lg\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .lg\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .lg\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .lg\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .lg\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .lg\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .lg\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .lg\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .lg\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .lg\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .lg\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .lg\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .lg\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .lg\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .lg\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .lg\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .lg\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .lg\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .lg\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .lg\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .lg\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .lg\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .lg\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .lg\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .lg\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .lg\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .lg\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .lg\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .lg\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .lg\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .lg\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .lg\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .lg\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .lg\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .lg\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .lg\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .lg\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .lg\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .lg\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .lg\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .lg\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .lg\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .lg\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .lg\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .lg\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .lg\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .lg\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .lg\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .lg\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .lg\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .lg\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .lg\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .lg\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .lg\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .lg\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .lg\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .lg\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .lg\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .lg\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .lg\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .lg\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .lg\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .lg\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .lg\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .lg\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .lg\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .lg\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .lg\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .lg\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .lg\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .lg\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .lg\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .lg\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .lg\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .lg\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .lg\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .lg\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .lg\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .lg\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .lg\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .lg\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .lg\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .lg\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .lg\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .lg\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .lg\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .lg\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .lg\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .lg\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .lg\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .lg\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .lg\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .lg\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .lg\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .lg\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .lg\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .lg\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .lg\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .lg\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .lg\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .lg\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .lg\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .lg\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .lg\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .lg\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .lg\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .lg\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .lg\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .lg\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .lg\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .lg\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .lg\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .lg\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .lg\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .lg\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .lg\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .lg\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .lg\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .lg\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .lg\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .lg\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .lg\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .lg\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .lg\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .lg\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .lg\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .lg\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .lg\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .lg\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .lg\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .lg\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .lg\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .lg\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .lg\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .lg\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .lg\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .lg\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .lg\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .lg\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .lg\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .lg\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .lg\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .lg\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .lg\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .lg\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .lg\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .lg\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .lg\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .lg\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .lg\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .lg\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .lg\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .lg\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .lg\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .lg\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .lg\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .lg\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .lg\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .lg\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .lg\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .lg\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .lg\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .lg\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .lg\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .lg\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .lg\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .lg\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .lg\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .lg\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .lg\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .lg\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .lg\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .lg\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .lg\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .lg\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .lg\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .lg\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .lg\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .lg\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .lg\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .lg\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .lg\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .lg\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .lg\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .lg\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .lg\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .lg\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .lg\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .lg\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .lg\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .lg\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .lg\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .lg\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .lg\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .lg\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .lg\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .lg\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .lg\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .lg\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .lg\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .lg\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .lg\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .lg\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .lg\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .lg\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .lg\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .lg\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .lg\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .lg\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .lg\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .lg\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .lg\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .lg\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .lg\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .lg\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .lg\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .lg\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .lg\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .lg\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
  }
  
  @media (max-width: 991px) {
    .md\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .md\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .md\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .md\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .md\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .md\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .md\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .md\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .md\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .md\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .md\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .md\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .md\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .md\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .md\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .md\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .md\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .md\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .md\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .md\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .md\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .md\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .md\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .md\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .md\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .md\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .md\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .md\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .md\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .md\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .md\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .md\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .md\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .md\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .md\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .md\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .md\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .md\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .md\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .md\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .md\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .md\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .md\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .md\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .md\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .md\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .md\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .md\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .md\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .md\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .md\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .md\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .md\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .md\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .md\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .md\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .md\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .md\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .md\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .md\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .md\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .md\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .md\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .md\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .md\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .md\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .md\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .md\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .md\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .md\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .md\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .md\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .md\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .md\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .md\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .md\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .md\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .md\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .md\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .md\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .md\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .md\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .md\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .md\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .md\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .md\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .md\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .md\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .md\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .md\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .md\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .md\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .md\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .md\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .md\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .md\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .md\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .md\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .md\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .md\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .md\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .md\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .md\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .md\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .md\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .md\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .md\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .md\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .md\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .md\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .md\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .md\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .md\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .md\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .md\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .md\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .md\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .md\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .md\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .md\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .md\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .md\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .md\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .md\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .md\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .md\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .md\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .md\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .md\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .md\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .md\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .md\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .md\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .md\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .md\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .md\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .md\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .md\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .md\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .md\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .md\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .md\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .md\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .md\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .md\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .md\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .md\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .md\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .md\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .md\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .md\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .md\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .md\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .md\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .md\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .md\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .md\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .md\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .md\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .md\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .md\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .md\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .md\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .md\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .md\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .md\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .md\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .md\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .md\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .md\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .md\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .md\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .md\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .md\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .md\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .md\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .md\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .md\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .md\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .md\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .md\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .md\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .md\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .md\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .md\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .md\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .md\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .md\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .md\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .md\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .md\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .md\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .md\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .md\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .md\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .md\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .md\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .md\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .md\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .md\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .md\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .md\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .md\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .md\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .md\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .md\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .md\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .md\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .md\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .md\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .md\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .md\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .md\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .md\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .md\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .md\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .md\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .md\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .md\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .md\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .md\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .md\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .md\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .md\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
  }
  
  @media (max-width: 767px) {
    .sm\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .sm\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .sm\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .sm\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .sm\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .sm\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .sm\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .sm\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .sm\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .sm\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .sm\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .sm\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .sm\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .sm\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .sm\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .sm\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .sm\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .sm\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .sm\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .sm\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .sm\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .sm\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .sm\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .sm\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .sm\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .sm\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .sm\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .sm\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .sm\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .sm\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .sm\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .sm\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .sm\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .sm\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .sm\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .sm\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .sm\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .sm\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .sm\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .sm\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .sm\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .sm\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .sm\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .sm\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .sm\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .sm\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .sm\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .sm\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .sm\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .sm\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .sm\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .sm\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .sm\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .sm\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .sm\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .sm\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .sm\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .sm\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .sm\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .sm\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .sm\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .sm\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .sm\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .sm\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .sm\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .sm\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .sm\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .sm\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .sm\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .sm\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .sm\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .sm\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .sm\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .sm\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .sm\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .sm\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .sm\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .sm\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .sm\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .sm\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .sm\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .sm\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .sm\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .sm\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .sm\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .sm\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .sm\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .sm\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .sm\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .sm\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .sm\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .sm\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .sm\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .sm\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .sm\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .sm\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .sm\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .sm\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .sm\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .sm\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .sm\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .sm\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .sm\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .sm\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .sm\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .sm\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .sm\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .sm\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .sm\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .sm\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .sm\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .sm\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .sm\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .sm\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .sm\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .sm\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .sm\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .sm\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .sm\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .sm\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .sm\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .sm\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .sm\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .sm\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .sm\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .sm\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .sm\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .sm\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .sm\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .sm\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .sm\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .sm\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .sm\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .sm\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .sm\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .sm\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .sm\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .sm\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .sm\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .sm\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .sm\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .sm\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .sm\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .sm\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .sm\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .sm\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .sm\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .sm\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .sm\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .sm\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .sm\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .sm\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .sm\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .sm\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .sm\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .sm\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .sm\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .sm\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .sm\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .sm\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .sm\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .sm\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .sm\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .sm\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .sm\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .sm\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .sm\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .sm\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .sm\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .sm\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .sm\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .sm\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .sm\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .sm\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .sm\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .sm\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .sm\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .sm\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .sm\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .sm\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .sm\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .sm\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .sm\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .sm\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .sm\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .sm\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .sm\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .sm\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .sm\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .sm\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .sm\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .sm\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .sm\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .sm\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .sm\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .sm\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .sm\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .sm\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .sm\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .sm\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .sm\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .sm\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .sm\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .sm\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .sm\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .sm\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .sm\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .sm\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .sm\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .sm\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .sm\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .sm\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .sm\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .sm\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .sm\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .sm\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .sm\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .sm\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .sm\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .sm\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .sm\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .sm\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .sm\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .sm\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
  }
  
  @media (max-width: 575px) {
    .xs\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .xs\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .xs\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .xs\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .xs\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .xs\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .xs\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .xs\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .xs\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .xs\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .xs\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .xs\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .xs\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .xs\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .xs\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .xs\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .xs\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .xs\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .xs\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .xs\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .xs\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .xs\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .xs\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .xs\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .xs\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .xs\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .xs\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .xs\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .xs\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .xs\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .xs\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .xs\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .xs\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .xs\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .xs\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .xs\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .xs\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .xs\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .xs\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .xs\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .xs\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .xs\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .xs\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .xs\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .xs\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .xs\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .xs\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .xs\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .xs\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .xs\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .xs\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .xs\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .xs\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .xs\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .xs\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .xs\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .xs\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .xs\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .xs\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .xs\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .xs\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .xs\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .xs\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .xs\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .xs\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .xs\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .xs\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .xs\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .xs\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .xs\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .xs\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .xs\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .xs\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .xs\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .xs\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .xs\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .xs\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .xs\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .xs\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .xs\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .xs\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .xs\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .xs\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .xs\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .xs\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .xs\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .xs\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .xs\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .xs\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .xs\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .xs\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .xs\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .xs\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .xs\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .xs\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .xs\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .xs\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .xs\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .xs\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .xs\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .xs\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .xs\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .xs\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .xs\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .xs\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .xs\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .xs\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .xs\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .xs\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .xs\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .xs\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .xs\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .xs\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .xs\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .xs\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .xs\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .xs\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .xs\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .xs\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .xs\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .xs\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .xs\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .xs\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .xs\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .xs\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .xs\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .xs\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .xs\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .xs\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .xs\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .xs\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .xs\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .xs\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .xs\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .xs\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .xs\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .xs\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .xs\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .xs\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .xs\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .xs\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .xs\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .xs\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .xs\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .xs\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .xs\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .xs\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .xs\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .xs\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .xs\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .xs\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .xs\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .xs\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .xs\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .xs\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .xs\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .xs\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .xs\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .xs\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .xs\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .xs\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .xs\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .xs\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .xs\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .xs\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .xs\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .xs\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .xs\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .xs\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .xs\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .xs\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .xs\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .xs\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .xs\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .xs\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .xs\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .xs\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .xs\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .xs\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .xs\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .xs\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .xs\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .xs\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .xs\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .xs\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .xs\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .xs\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .xs\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .xs\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .xs\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .xs\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .xs\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .xs\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .xs\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .xs\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .xs\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .xs\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .xs\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .xs\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .xs\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .xs\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .xs\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .xs\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .xs\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .xs\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .xs\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .xs\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .xs\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
    .xs\:ml-minus-sm {
      margin-left: -3.75rem;
    }
    .xs\:ml-minus-md {
      margin-left: -6.25rem;
    }
    .xs\:ml-minus-lg {
      margin-left: -8.75rem;
    }
    .xs\:ml-minus-xl {
      margin-left: -11.25rem;
    }
    .xs\:ml-minus-col-1 {
      margin-left: -8.33333vw;
    }
    .xs\:ml-minus-col-2 {
      margin-left: -16.66667vw;
    }
    .xs\:ml-minus-col-3 {
      margin-left: -25vw;
    }
    .xs\:ml-minus-col-4 {
      margin-left: -33.33333vw;
    }
    .xs\:mr-minus-sm {
      margin-right: -3.75rem;
    }
    .xs\:mr-minus-md {
      margin-right: -6.25rem;
    }
    .xs\:mr-minus-lg {
      margin-right: -8.75rem;
    }
    .xs\:mr-minus-xl {
      margin-right: -11.25rem;
    }
    .xs\:mr-minus-col-1 {
      margin-right: -8.33333vw;
    }
    .xs\:mr-minus-col-2 {
      margin-right: -16.66667vw;
    }
    .xs\:mr-minus-col-3 {
      margin-right: -25vw;
    }
    .xs\:mr-minus-col-4 {
      margin-right: -33.33333vw;
    }
  }
  
  .w-1\/1 {
    width: 100%;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-right {
    text-align: right;
  }
  
  .text-left {
    text-align: left;
  }
  
  @media (max-width: 1229px) {
    .lg\:text-center {
      text-align: center;
    }
    .lg\:text-right {
      text-align: right;
    }
    .lg\:text-left {
      text-align: left;
    }
  }
  
  @media (max-width: 991px) {
    .md\:text-center {
      text-align: center;
    }
    .md\:text-right {
      text-align: right;
    }
    .md\:text-left {
      text-align: left;
    }
  }
  
  @media (max-width: 767px) {
    .sm\:text-center {
      text-align: center;
    }
    .sm\:text-right {
      text-align: right;
    }
    .sm\:text-left {
      text-align: left;
    }
  }
  
  @media (max-width: 575px) {
    .xs\:text-center {
      text-align: center;
    }
    .xs\:text-right {
      text-align: right;
    }
    .xs\:text-left {
      text-align: left;
    }
  }
  
  .justify-content-start {
    justify-content: flex-start;
  }
  
  .justify-content-end {
    justify-content: flex-end;
  }
  
  .justify-content-between {
    justify-content: space-between;
  }
  
  .justify-content-center {
    justify-content: center;
  }
  
  .align-items-start {
    align-items: flex-start;
  }
  
  .align-items-end {
    align-items: flex-end;
  }
  
  .align-items-between {
    align-items: space-between;
  }
  
  .align-items-center {
    align-items: center;
  }
  
  @media (max-width: 1229px) {
    .lg\:justify-content-start {
      justify-content: flex-start;
    }
    .lg\:justify-content-end {
      justify-content: flex-end;
    }
    .lg\:justify-content-between {
      justify-content: space-between;
    }
    .lg\:justify-content-center {
      justify-content: center;
    }
    .lg\:align-items-start {
      align-items: flex-start;
    }
    .lg\:align-items-end {
      align-items: flex-end;
    }
    .lg\:align-items-between {
      align-items: space-between;
    }
    .lg\:align-items-center {
      align-items: center;
    }
  }
  
  @media (max-width: 991px) {
    .md\:justify-content-start {
      justify-content: flex-start;
    }
    .md\:justify-content-end {
      justify-content: flex-end;
    }
    .md\:justify-content-between {
      justify-content: space-between;
    }
    .md\:justify-content-center {
      justify-content: center;
    }
    .md\:align-items-start {
      align-items: flex-start;
    }
    .md\:align-items-end {
      align-items: flex-end;
    }
    .md\:align-items-between {
      align-items: space-between;
    }
    .md\:align-items-center {
      align-items: center;
    }
  }
  
  @media (max-width: 767px) {
    .sm\:justify-content-start {
      justify-content: flex-start;
    }
    .sm\:justify-content-end {
      justify-content: flex-end;
    }
    .sm\:justify-content-between {
      justify-content: space-between;
    }
    .sm\:justify-content-center {
      justify-content: center;
    }
    .sm\:align-items-start {
      align-items: flex-start;
    }
    .sm\:align-items-end {
      align-items: flex-end;
    }
    .sm\:align-items-between {
      align-items: space-between;
    }
    .sm\:align-items-center {
      align-items: center;
    }
  }
  
  @media (max-width: 575px) {
    .xs\:justify-content-start {
      justify-content: flex-start;
    }
    .xs\:justify-content-end {
      justify-content: flex-end;
    }
    .xs\:justify-content-between {
      justify-content: space-between;
    }
    .xs\:justify-content-center {
      justify-content: center;
    }
    .xs\:align-items-start {
      align-items: flex-start;
    }
    .xs\:align-items-end {
      align-items: flex-end;
    }
    .xs\:align-items-between {
      align-items: space-between;
    }
    .xs\:align-items-center {
      align-items: center;
    }
  }
  
  .d-none {
    display: none !important;
  }
  
  .d-flex {
    display: flex !important;
  }
  
  .d-block {
    display: block !important;
  }
  
  .d-inline-block {
    display: inline-block !important;
  }
  
  @media (max-width: 1229px) {
    .lg\:d-none {
      display: none !important;
    }
    .lg\:d-inline-block {
      display: inline-block !important;
    }
    .lg\:d-block {
      display: block !important;
    }
    .lg\:d-flex {
      display: flex !important;
    }
  }
  
  @media (max-width: 991px) {
    .md\:d-none {
      display: none !important;
    }
    .md\:d-inline-block {
      display: inline-block !important;
    }
    .md\:d-block {
      display: block !important;
    }
    .md\:d-flex {
      display: flex !important;
    }
  }
  
  @media (max-width: 767px) {
    .sm\:d-none {
      display: none !important;
    }
    .sm\:d-inline-block {
      display: inline-block !important;
    }
    .sm\:d-block {
      display: block !important;
    }
    .sm\:d-flex {
      display: flex !important;
    }
  }
  
  @media (max-width: 575px) {
    .xs\:d-none {
      display: none !important;
    }
    .xs\:d-inline-block {
      display: inline-block !important;
    }
    .xs\:d-block {
      display: block !important;
    }
    .xs\:d-flex {
      display: flex !important;
    }
  }
  
  .order-1 {
    order: 1;
  }
  
  .order-2 {
    order: 2;
  }
  
  .order-3 {
    order: 3;
  }
  
  .order-4 {
    order: 4;
  }
  
  .order-5 {
    order: 5;
  }
  
  .order-6 {
    order: 6;
  }
  
  @media (max-width: 1229px) {
    .lg\:order-1 {
      order: 1;
    }
    .lg\:order-2 {
      order: 2;
    }
    .lg\:order-3 {
      order: 3;
    }
    .lg\:order-4 {
      order: 4;
    }
    .lg\:order-5 {
      order: 5;
    }
    .lg\:order-6 {
      order: 6;
    }
  }
  
  @media (max-width: 991px) {
    .md\:order-1 {
      order: 1;
    }
    .md\:order-2 {
      order: 2;
    }
    .md\:order-3 {
      order: 3;
    }
    .md\:order-4 {
      order: 4;
    }
    .md\:order-5 {
      order: 5;
    }
    .md\:order-6 {
      order: 6;
    }
  }
  
  @media (max-width: 767px) {
    .sm\:order-1 {
      order: 1;
    }
    .sm\:order-2 {
      order: 2;
    }
    .sm\:order-3 {
      order: 3;
    }
    .sm\:order-4 {
      order: 4;
    }
    .sm\:order-5 {
      order: 5;
    }
    .sm\:order-6 {
      order: 6;
    }
  }
  
  @media (max-width: 575px) {
    .xs\:order-1 {
      order: 1;
    }
    .xs\:order-2 {
      order: 2;
    }
    .xs\:order-3 {
      order: 3;
    }
    .xs\:order-4 {
      order: 4;
    }
    .xs\:order-5 {
      order: 5;
    }
    .xs\:order-6 {
      order: 6;
    }
  }
  
  .layout-pt-xs {
    padding-top: 2.5rem;
  }
  
  .layout-pt-sm {
    padding-top: 3.75rem;
  }
  
  .layout-pt-md {
    padding-top: 5rem;
  }
  
  .layout-pt-lg {
    padding-top: 7.5rem;
  }
  
  .layout-pt-xl {
    padding-top: 10rem;
  }
  
  .layout-pt-2xl {
    padding-top: 11.25rem;
  }
  
  @media (max-width: 991px) {
    .layout-pt-md {
      padding-top: 3.75rem;
    }
    .layout-pt-lg {
      padding-top: 6.25rem;
    }
    .layout-pt-xl {
      padding-top: 7.5rem;
    }
    .layout-pt-2xl {
      padding-top: 10rem;
    }
  }
  
  @media (max-width: 767px) {
    .layout-pt-md {
      padding-top: 3.75rem;
    }
    .layout-pt-lg {
      padding-top: 5rem;
    }
    .layout-pt-xl {
      padding-top: 7.5rem;
    }
    .layout-pt-2xl {
      padding-top: 8.125rem;
    }
  }
  
  .layout-pb-xs {
    padding-bottom: 2.5rem;
  }
  
  .layout-pb-sm {
    padding-bottom: 3.75rem;
  }
  
  .layout-pb-md {
    padding-bottom: 5rem;
  }
  
  .layout-pb-lg {
    padding-bottom: 7.5rem;
  }
  
  .layout-pb-xl {
    padding-bottom: 10rem;
  }
  
  .layout-pb-2xl {
    padding-bottom: 11.25rem;
  }
  
  @media (max-width: 991px) {
    .layout-pb-md {
      padding-bottom: 3.75rem;
    }
    .layout-pb-lg {
      padding-bottom: 6.25rem;
    }
    .layout-pb-xl {
      padding-bottom: 7.5rem;
    }
    .layout-pb-2xl {
      padding-bottom: 10rem;
    }
  }
  
  @media (max-width: 767px) {
    .layout-pb-md {
      padding-bottom: 3.75rem;
    }
    .layout-pb-lg {
      padding-bottom: 5rem;
    }
    .layout-pb-xl {
      padding-bottom: 6.25rem;
    }
    .layout-pb-2xl {
      padding-bottom: 7.5rem;
    }
  }
  
  .layout-pt-pageHeader {
    padding-top: 10rem;
  }
  
  @media (max-width: 767px) {
    .layout-pt-pageHeader {
      padding-top: 8.75rem;
    }
  }
  
  .x-gap-32 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  
  .x-gap-32 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .x-gap-40 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  
  .x-gap-40 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  
  .x-gap-48 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  
  .x-gap-48 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  
  .x-gap-60 {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
  }
  
  .x-gap-60 > * {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  
  .x-gap-72 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  
  .x-gap-72 > * {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  
  .y-gap-32 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  
  .y-gap-32 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .y-gap-40 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  
  .y-gap-40 > * {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  
  .y-gap-48 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  
  .y-gap-48 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  
  .y-gap-60 {
    margin-top: -1.875rem;
    margin-bottom: -1.875rem;
  }
  
  .y-gap-60 > * {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  
  .y-gap-72 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  
  .y-gap-72 > * {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  
  .h-sm {
    height: 32.5rem;
  }
  
  .h-md {
    height: 36.25rem;
  }
  
  .h-lg {
    height: 42.5rem;
  }
  
  .h-70vh {
    height: 70vh;
  }
  
  .h-80vh {
    height: 80vh;
  }
  
  .h-90vh {
    height: 90vh;
  }
  
  .h-100vh {
    height: 100vh;
  }
  
  .h-unset {
    height: unset;
  }
  
  .h-full {
    height: 100%;
  }
  
  @media (max-width: 991px) {
    .md\:h-unset {
      height: unset;
    }
    .md\:h-70vh {
      height: 70vh;
    }
    .md\:h-80vh {
      height: 80vh;
    }
    .md\:h-90vh {
      height: 90vh;
    }
    .md\:h-100vh {
      height: 100vh;
    }
  }
  
  @media (max-width: 767px) {
    .x-gap-72,
    .x-gap-60,
    .x-gap-40,
    .x-gap-48 {
      margin-left: -1rem;
      margin-right: -1rem;
    }
    .x-gap-72 > *,
    .x-gap-60 > *,
    .x-gap-40 > *,
    .x-gap-48 > * {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  
  @media (max-width: 767px) {
    .y-gap-72,
    .y-gap-60,
    .y-gap-40,
    .y-gap-48 {
      margin-top: -1.25rem;
      margin-bottom: -1.25rem;
    }
    .y-gap-72 > *,
    .y-gap-60 > *,
    .y-gap-40 > *,
    .y-gap-48 > * {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
  }
  
  .fancy-grid {
    display: flex;
    flex-wrap: wrap;
  }
  
  .fancy-grid.-container {
    padding-left: 6vw;
    padding-right: 6vw;
  }
  
  .fancy-grid.-col-2 {
    margin-left: -4.0vw;
    margin-right: -4.0vw;
  }
  
  .fancy-grid.-col-2 .fancy-grid__item {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 4.0vw;
    padding-right: 4.0vw;
  }
  
  .fancy-grid.-col-2 .fancy-grid__item:nth-child(2n + 2) {
    margin-top: 4.5vw;
  }
  
  .fancy-grid.-col-2 .fancy-grid__item:nth-child(1n + 3) {
    padding-top: 4.0vw;
  }
  
  @media (max-width: 767px) {
    .fancy-grid.-col-2 .fancy-grid__item {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .fancy-grid.-col-2 .fancy-grid__item:nth-child(2n + 2) {
      margin-top: 0;
    }
    .fancy-grid.-col-2 .fancy-grid__item:nth-child(1n + 2) {
      padding-top: 2.5rem;
    }
  }
  
  .fancy-grid.-col-2.-reverse .fancy-grid__item {
    margin-top: 0;
  }
  
  .fancy-grid.-col-2.-reverse .fancy-grid__item:nth-child(2n + 1) {
    margin-top: 4.5vw;
  }
  
  .fancy-grid.-col-2.-reverse .fancy-grid__item:nth-child(1n + 3) {
    padding-top: 4.5vw;
  }
  
  @media (max-width: 767px) {
    .fancy-grid.-col-2.-reverse .fancy-grid__item:nth-child(2n + 1) {
      margin-top: 0;
    }
    .fancy-grid.-col-2.-reverse .fancy-grid__item:nth-child(1n + 2) {
      padding-top: 2.5rem;
    }
  }
  
  .fancy-grid.-col-3 {
    margin-left: -4vw;
    margin-right: -4vw;
  }
  
  .fancy-grid.-col-3 .fancy-grid__item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-left: 4vw;
    padding-right: 4vw;
  }
  
  .fancy-grid.-col-3 .fancy-grid__item:nth-child(3n + 2) {
    margin-top: 4.5vw;
  }
  
  .fancy-grid.-col-3 .fancy-grid__item:nth-child(1n + 4) {
    padding-top: 4.5vw;
  }
  
  @media (max-width: 991px) {
    .fancy-grid.-col-3 .fancy-grid__item {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .fancy-grid.-col-3 .fancy-grid__item:nth-child(3n + 2) {
      margin-top: 0;
    }
    .fancy-grid.-col-3 .fancy-grid__item:nth-child(1n + 4) {
      padding-top: 0;
    }
    .fancy-grid.-col-3 .fancy-grid__item:nth-child(2n + 2) {
      margin-top: 8vw;
    }
  }
  
  @media (max-width: 767px) {
    .fancy-grid.-col-3 .fancy-grid__item {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 0;
    }
    .fancy-grid.-col-3 .fancy-grid__item:nth-child(2n + 2) {
      margin-top: 0;
    }
    .fancy-grid.-col-3 .fancy-grid__item:nth-child(1n + 2) {
      margin-top: 3rem;
    }
  }
  
  .layout-pt-headerBar {
    padding-top: 6rem;
  }
  
  .layout-pr-headerBar {
    padding-right: 9.25rem;
  }
  
  .layout-pl-headerBar {
    margin-left: 9.25rem;
  }
  
  @media (max-width: 767px) {
    .layout-pt-headerBar {
      padding-top: 3.75rem;
    }
    .layout-pr-headerBar {
      padding-right: 7rem;
    }
    .layout-pl-headerBar {
      padding-left: 7rem;
    }
  }
  
  @media (max-width: 767px) {
    .sm\:layout-pt-headerBar {
      padding-top: 3.75rem;
    }
    .sm\:layout-pr-headerBar {
      padding-right: 7rem;
    }
    .sm\:layout-pl-headerBar {
      padding-left: 7rem;
    }
  }
  
  .layout-mt-headerBar {
    margin-top: 6rem;
  }
  
  .layout-mr-headerBar {
    margin-right: 9.25rem;
  }
  
  .layout-ml-headerBar {
    margin-left: 9.25rem;
  }
  
  @media (max-width: 767px) {
    .layout-mt-headerBar {
      margin-top: 3.75rem;
    }
    .layout-mr-headerBar {
      margin-right: 7rem;
    }
    .layout-ml-headerBar {
      margin-left: 7rem;
    }
  }
  
  @media (max-width: 767px) {
    .sm\:layout-mt-headerBar {
      margin-top: 3.75rem;
    }
    .sm\:layout-mr-headerBar {
      margin-right: 7rem;
    }
    .sm\:layout-ml-headerBar {
      margin-left: 7rem;
    }
  }
  